import React, { useState, useEffect } from "react";
import HeaderComponent from "../../components/HeaderComponent";
import FooterComponent from "../../components/FooterComponent";
import StaticMenuComponent from "../../components/StaticMenuComponent";
import ModalPerfisComponent from "../../components/ModalPerfisComponent";
import { Figure, Card, Col, Modal, Button, Image, Offcanvas, InputGroup, Form, FloatingLabel } from "react-bootstrap";
import { Create, listComments, totalComments } from '../../services/postCommentService';
import { CommentData } from '../../types/Types';
import { sendLike, getLike, totalLikes } from '../../services/postLikeService';
import { IoChatbubble } from "react-icons/io5";
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import PullToRefresh from 'react-pull-to-refresh';
import { listhome } from "../../services/postService";
import { FaCircleUser } from "react-icons/fa6";
import { AiFillLike } from "react-icons/ai";
import { getIdToken, formatDate2 } from '../../utils/Utils';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

export interface PostData {
  _id: string;
  idUser: string;
  texto: string;
  srcImagem: string;
  dataPublicacao: string;
  userDetails: {
    _id?: string,
    nome?: string,
    profile?: string,
    cidade?: string,
    srcProfile?: string
  },
  curtiu: boolean
} 

interface LikeButtonProps {
  post: PostData;
  addLike: (postId: string) => Promise<void>;
}

interface TruncateTextProps {
  text: string;
  maxLength: number;
}

function HomePage({ ...props }) {
  
  const LikeButton: React.FC<LikeButtonProps> = ({ post, addLike }) => {
    
    const [liked, setLiked] = useState(post.curtiu);
    
    const handleLike = async (postId: string) => {
      
      addLike(postId);
      setLiked(!liked);
    }
    
    return (
      <Button
      variant={liked === false ? 'outline-success' : 'success'}
      size="lg"
      className="btn rounded p-0 float-start blocoIconeAcao"
      onClick={() => handleLike(post._id)}
      >
      <AiFillLike className="fs-1-5em" />
      </Button>
    );
  };
  
  const [idUserToken, setIdUserToken] = useState(getIdToken());
  
  const [curtiu, setCurtiu] = useState(0);
  
  const [show, setShow] = useState(false);
  const [showCurtidas, setShowCurtidas] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleCloseCurtidas = () => setShowCurtidas(false);
  const handleShowCurtidas = () => setShowCurtidas(true);
  
  const [show2, setShow2] = useState(false);
  
  const handleClose2 = () => setShow2(false);
  
  const [nome, setNome] = useState("");
  
  const [showDenuncia, setShowDenuncia] = useState(false);
  
  const [dataPublicacaoPost, setDataPulicacao] = useState('');
  
  const handleCloseDenuncia = () => setShowDenuncia(false);
  
  const [textoPulicacao, setTextoPulicacao] = useState('');
  
  const [posts, setPosts] = useState<PostData[]>([]);
  
  const [imagemAtual, setImagemAtual] = useState('');
  
  const [buttonTextCurtidas, setButtonTextCurtidas] = useState('');
  
  const [campoComentario, setCampoComentario] = useState('');
  
  const [idPublicacao, setidPublicacao] = useState('');
  
  const [commentsTotalLoad, setCommentsTotalLoad] = useState(true);
  
  const [newComment, setNewComment] = useState(true);
  
  const [commentsTotalSel, setCommentsTotalSel] = useState(0);
  
  const [offsetGeral, setOffsetGeral] = useState(0);
  
  const [commentsList, setCommentsList] = useState<CommentData[]>([]);
  
  const [botaoCarregarMaisComments, setBotaoCarregarMaisComments] = useState(false);
  
  const [botaoDesabilitadoCarregarMais, setBotaoDesabilitadoCarregarMais] = useState(false);
  
  const [totalCurtiu, setTotalCurtiu] = useState(0);
  
  const [textoComentarioExclusao, setTextoComentarioExclusao] = useState('');
  
  const [dataComentarioSel, setDataComentarioSel] = useState<any | null>(null);
  
  const [showConfirmacaoExclusaoComentario, setShowConfirmacaoExclusaoComentario] = useState(false);
  
  const [postPublico, setPostPublico] = useState<number>(0);
  
  const getCurtidasTexto = (curtidas:any) => {
    
    let textoCurtidas = '';
    
    if (curtidas >= 2) {
      textoCurtidas = "2 CURTIDAS";
    } 
    
    if (curtidas === 1) {
      textoCurtidas = "1 CURTIDA";
    } 
    
    if (curtidas === '' || curtidas === null || curtidas === 0) {
      textoCurtidas = "NENHUMA CURTIDA";
    }
    
    setButtonTextCurtidas(textoCurtidas);
  }
  
  const handleShow2 = (post: any) => {
    
    getCurtidasTexto('');
    setTotalCurtiu(0);
    setOffsetGeral(0);
    setCampoComentario('');
    setPostPublico(post.publico)
    setImagemAtual(post.srcImagem);
    setTextoPulicacao(post.texto);
    setDataPulicacao(formatDate2(post.dataPublicacao));
    setShow2(true);
    setidPublicacao(post._id);
    comments(post._id, 1);
    setCommentsTotalLoad(true);
    likeVerify(post._id)
    totalLikesVerify(post._id);
  }
  
  const totalLikesVerify = async (idPost:string) => {
    try{
      
      const verifyLikeTotal = await totalLikes(idPost);
      
      if(verifyLikeTotal){
        
        setTotalCurtiu(verifyLikeTotal);
        getCurtidasTexto(verifyLikeTotal);
      }
    } catch (error) {
      console.error("Erro ao listar comentários");
    } 
  }
  
  const likeVerify = async (idPost:string) => {
    try{
      
      const verifyLike = await getLike(idPost);
      
      if(verifyLike){
        
        if(verifyLike.like === null){
          
          setCurtiu(0);  
        }else{
          
          setCurtiu(1)
        }
      }
    } catch (error) {
      console.error("Erro ao listar comentários");
    } 
  }
  
  const removeComentario = (comment:any) => {
    
    setTextoComentarioExclusao(comment.texto);
    setDataComentarioSel(comment);
    setShowConfirmacaoExclusaoComentario(true);
  }
  
  const addLike = async (postId:string | null) => {
    
    try {
      
      let idPostSel = idPublicacao;
      
      if(postId !== '' && postId !== null){
        
        idPostSel = postId;
      }
      
      if(postId === ''){
        
        getCurtidasClick(curtiu === 1 ? 0 : 1);
        setCurtiu(curtiu === 1 ? 0 : 1);
      }
      
      await sendLike(idPostSel);
      
    } catch (error) {
      console.error("Erro ao cadastrar este comentário");
    }
  }
  
  const paginationComment = () => {
    setBotaoDesabilitadoCarregarMais(true);
    comments(idPublicacao, 0);
    setBotaoDesabilitadoCarregarMais(false);
  };
  
  const getCurtidasClick = (clique:number) => {
    
    let textoCurtidas = '';
    
    if (totalCurtiu >= 1 && clique === 1) {
      textoCurtidas = (totalCurtiu + 1)+" CURTIDAS";
    } 
    
    if (totalCurtiu === 0 && clique === 1) {
      textoCurtidas = "1 CURTIDA";
    } 
    
    if ((totalCurtiu === 1 || totalCurtiu === 0) && clique === 0) {
      textoCurtidas = "NENHUMA CURTIDA";
    }
    
    if (totalCurtiu === 2 && clique === 0) {
      textoCurtidas = "1 CURTIDA";
    }
    
    if (totalCurtiu > 2 && clique === 0) {
      textoCurtidas = (totalCurtiu - 1)+" CURTIDAS";
    }
    
    setButtonTextCurtidas(textoCurtidas);
  }
  
  const comments = async (idPost:String, resetLista:Number) => {
    try {
      
      if (commentsTotalLoad) {
        const commentsTotal = await totalComments(idPost);
        setCommentsTotalSel(commentsTotal);
      }
      
      let newCommentsList:any = [];
      
      if(resetLista === 1){
        
        newCommentsList = await listComments(idPost, 0);
        setOffsetGeral(1);
      }
      
      if(resetLista !== 1){
        
        newCommentsList = await listComments(idPost, offsetGeral);
        setOffsetGeral(offsetGeral + 1);
      }
      
      if (resetLista === 0) {
        
        setCommentsList(prev => [...prev, ...newCommentsList]);
        
        if (newCommentsList.length < 10) {
          setBotaoCarregarMaisComments(false);
        }else{
          setBotaoCarregarMaisComments(true);
        }
        
      } else {
        
        setCommentsList(newCommentsList);
        
        if (newCommentsList.length < 10) {
          setBotaoCarregarMaisComments(false);
        }else{
          setBotaoCarregarMaisComments(true);
        }
      }
    } catch (error) {
      console.error("Erro ao listar comentários");
    }   
  }
  
  const addComment = async () => {
    try {
      const returnList = 1;
      const newComment = await Create(idPublicacao, campoComentario, returnList);
      
      if(newComment){
        
        setCampoComentario('');
        setNewComment(true);
        comments(idPublicacao, 1); 
      }
    } catch (error) {
      console.error("Erro ao cadastrar este comentário");
    }
  };
  
  const TruncateText: React.FC<TruncateTextProps> = ({ text, maxLength }) => {
    if (text.length > maxLength) {
      return <>{text.substring(0, maxLength) + "..."}</>;
    }
    return <>{text}</>;
  };

  const handleShowDenuncia = () => {
    setShowDenuncia(true);
    setShow(false);
  };
  
  const getList = async (offsetList: number) => {
    try {
      const resultListLikes = await listhome(offsetList);
      
      if (offsetList > 0) {
        setPosts((prevListProfiles: PostData[]) => [...prevListProfiles, ...resultListLikes]);
      } else {
        setPosts(resultListLikes);
      }
      
    } catch (error) {
      console.error("Erro ao listar postagens:", error);
    }
  };
  
  const handleRefresh = () => {
    return new Promise<void>((resolve) => {
      getList(0);
      resolve();
    });
  };
  
  useEffect(() => {
    
    getList(0);
  }, []);
  
  return (
    <>
    <StaticMenuComponent />
    <HeaderComponent />
    <main style={{top:"65px"}}>
    <div className="contentHome">
    <div className="cemPor">
    <PullToRefresh onRefresh={handleRefresh}>
    </PullToRefresh>
    </div>
    {posts.length > 0 && posts.map((post, index) => (
      <div
      key={index} 
      className="cemPor pt-0 float-start"
      >
      <div className="cemPor mt-1 float-start">
      <Col className="my-1 cemPor float-start">
      <div className="px-2 d-block float-start cemPor">
      <div className="d-block float-start">
      {post.userDetails.srcProfile ? (
        <LazyLoadImage src={post.userDetails.srcProfile} effect="blur" className="thumb-1" />
      ) : (
        <div className="">
        <FaCircleUser className="fs-3em opacity-0-1" />
        </div>
      )}
      </div>
      <div className="d-block float-start">
      <Link to={`/profile/${post.userDetails._id}`} className="text-black text-decoration-none">
      <h6 className="mb-0 py-0 ps-2 float-start">
      {post.userDetails.nome}<br />
      <small>@{post.userDetails.profile}</small>
      </h6>
      </Link>
      </div>
      
      </div>
      </Col>
      </div>
      
      {post.srcImagem ? (
        <div className="cemPor mt-2 float-start">
        <LazyLoadImage src={post.srcImagem} effect="blur" className="cemPor pt-0 pb-0 mb-2 figure" onClick={() => handleShow2(post)} />
        <div className="ms-2 float-start">
        <LikeButton post={post} addLike={addLike} />
        <Button
        variant='outline-primary'
        size="lg"
        className="btn rounded p-0 ms-2 float-start blocoIconeAcao"
        >
        <IoChatbubble
        className="fs-1-5em"
        onClick={() => handleShow2(post)}
        />
        </Button>
        </div>
        <div className="mx-2 float-start" onClick={() => handleShow2(post)}>
        <h6 className="pt-2 float-start cemPor px-2">
        <span>
        {post.texto && <TruncateText text={post.texto} maxLength={150} />}
        </span>
        </h6>
        </div>
        </div>
      ) : (
        <div className="cemPor float-start px-0">
        
        <div className="border-none card float-start px-1 pborder-none card float-start px-0 mx-0 my-0 pb-0 cemPor card" onClick={() => handleShow2(post)}>
        <h6 className="mb-0 cemPor px-2">
        {post.texto && <TruncateText text={post.texto} maxLength={150} />}
        </h6>
        </div>
        
        <div className="float-start mt-2 mb-3 ms-2">
        <LikeButton post={post} addLike={addLike} />
        <Button
        variant='outline-primary'
        size="lg"
        className="btn rounded p-0 ms-2 float-start blocoIconeAcao"
        >
        <IoChatbubble
        className="fs-1-5em"
        onClick={() => handleShow2(post)}
        />
        </Button>
        </div>
        </div>
      )}
      </div>
    ))}
    </div>
    </main>
    
    <Modal show={showDenuncia} onHide={handleClose}>
    <Modal.Body className="px-5">
    <Button
    className="link-vermelho fs-1-2em"
    onClick={handleShowDenuncia}
    >
    Denunciar
    </Button>
    <hr className="my-2" />
    <Button className="link-azul fs-1-2em" onClick={handleClose}>
    Fechar
    </Button>
    </Modal.Body>
    </Modal>
    
    <Modal
    show={showDenuncia}
    onHide={handleCloseDenuncia}
    className="modalDenuncia"
    >
    <Modal.Body className="px-5">
    <Button className="link-vermelho fs-1-2em">Enviar denúncia</Button>
    
    <FloatingLabel
    controlId="floatingTextarea2"
    label="Digite o motivo da denúncia..."
    className="mb-3 mt-4"
    >
    <Form.Control
    as="textarea"
    placeholder="Digite o motivo da denúncia..."
    style={{ height: "100px" }}
    className="bg-light"
    />
    </FloatingLabel>
    
    <Button
    variant="outline-danger"
    className="rounded-pill px-4 btn-lg my-2 mt-3 mb-3"
    >
    DENUNCIAR
    </Button>
    
    <hr className="my-2" />
    <Button
    variant="outline-primary"
    className="rounded-pill btn-lg"
    onClick={handleCloseDenuncia}
    >
    VOLTAR
    </Button>
    </Modal.Body>
    </Modal>
    
    <ModalPerfisComponent show={showCurtidas} onHide={handleCloseCurtidas} titulo="Lista de Curtidas" idPublicacao={idPublicacao} />   
    
    <Offcanvas
    show={show2}
    onHide={handleClose2}
    {...props}
    placement={"end"}
    style={{ width: "90%" }}
    >
    <Offcanvas.Body className="pt-0">
    <div className="legendaPostDetalhe">
    <div>
    {imagemAtual && (<Figure className="cemPor py-3 pt-0 pb-0 mb-2 figure">
      <LazyLoadImage
      className="cemPor mb-0"
      src={imagemAtual}
      effect="blur"
      style={{
        borderRight: "3px solid white",
        borderBottom: "3px solid white",
        height: "320px",
      }}
      />
      </Figure>
    )}
    <div className="d-flex">
    <div className="float-start cemPor">
    
    <Button
    variant={curtiu === 0 ? `outline-success` : `success`}
    size="lg"
    className="btn rounded p-0 float-start blocoIconeAcao"
    onClick={() => addLike('')}
    >
    <AiFillLike className="fs-1-5em" />
    </Button>
    
    <Button
    variant="link"
    size="lg"
    className="btn rounded p-0 text-black text-start ps-2 float-start w-auto"
    onClick={handleShowCurtidas}
    >{buttonTextCurtidas}</Button>
    <h6 className="arial w-auto float-end pe-1 italico"><small>{dataPublicacaoPost}</small></h6>
    </div>
    </div>
    
    <div className="float-start">
    {textoPulicacao && (<div className="d-flex">
      <div className="px-2">
      <h6
      className="py-0 ps-1 float-start text-start arial"
      style={{ marginTop: "7px" }}
      >
      <b>{nome}</b>{" "}
      <span>
      {textoPulicacao}
      </span>
      </h6>
      </div>
      </div>
    )}
    
    </div>
    </div>
    
    <InputGroup className="mt-1 mb-3 input-group-lg p-2" style={{height:"39px"}}>
    <Form.Control
    aria-label="Adicionar comentário..."
    placeholder="Adicionar comentário..."
    className="br-0 fs-1em"
    value={campoComentario}
    onChange={(e) => setCampoComentario(e.target.value)}
    />
    <InputGroup.Text className="bg-white">
    <PiPaperPlaneRightFill className="bl-0" onClick={addComment} />
    </InputGroup.Text>
    </InputGroup>
    
    <div className="px-3 pt-4" id="caixaScroll" style={{overflowY:'scroll'}}>
    
    {commentsList.length === 0 && (
      
      <p className="text-center">Nenhum comentário.</p>
    )}
    
    {commentsList.length > 0 && commentsList.map((comment) => (
      <div key={comment._id}>
      {comment.user.map((user) => (
        <React.Fragment key={user._id}>
        <div className="d-flex" {...(idUserToken === user._id && { onClick: () => removeComentario(comment) })}>
        {user.srcProfile ? (
          <Image src={user.srcProfile} className="thumb-1" />
        ) : (
          <div className="" style={{width:"50px"}}>
          <FaCircleUser className="fs-2em opacity-0-1" />
          </div>
        )}
        <div className="px-2 pb-3">
        <p className="text-start d-grid mb-0 arial">
        <b>{user.nome}</b>
        <span>{comment.texto}</span>
        </p>
        </div>
        </div>
        </React.Fragment>
      ))}
      </div>
    ))}
    
    {botaoCarregarMaisComments && (
      <Button
      variant="primary"
      className="rounded px-4 btn-sm mb-4"
      onClick={() => paginationComment()}
      disabled={botaoDesabilitadoCarregarMais}
      >
      CARREGAR MAIS
      </Button>  
    )}
    
    </div>
    
    <div className="p-0 mt-2" style={{position:"fixed",bottom:"0",right:"2%",height:"8%"}}>
    
    </div>
    </div>
    
    <div style={{position:"fixed",width:"85%",right:"2%",bottom:"2%"}}>
    
    <Button
    className="rounded btn-lg float-end quarentePor"
    onClick={handleClose2}
    
    >
    VOLTAR
    </Button>
    
    </div>
    
    </Offcanvas.Body>
    </Offcanvas>
    
    <FooterComponent />
    </>
  );
}

export default HomePage;
