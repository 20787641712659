import { configureHeaders } from '../utils/Utils';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

let apiUrl: string = process.env.REACT_APP_HOST_API as string;

export async function list(offset:number, search: string) {
  try {

    const token = Cookies.get("TokenBTMania");

    if (token) {

      const headers: Record<string, string> = {};

      const decoded = jwtDecode(token);

      const jsonString = JSON.stringify(decoded);
      const jsonObject = JSON.parse(jsonString);
      const idUser = (jsonObject.id);

      configureHeaders(headers);

      if(search === '' || search === null){
        search = 'noSearch';
      }

      const response = await fetch(`${apiUrl}/user/list/${idUser}/${offset}/${search}`, {
        method: 'GET',
        headers
      });

      const dados = await response.json();
      return dados;

    } else {
      console.error("Token não encontrado no armazenamento local.");
    }
  } catch (error) {
    console.error("Erro ao buscar usuário:", error);
    throw error;
  }
}

export async function listprofessores(offset:number, search: string) {
    try {
  
      const token = Cookies.get("TokenBTMania");
  
      if (token) {

        const headers: Record<string, string> = {};

        const decoded = jwtDecode(token);

        const jsonString = JSON.stringify(decoded);
        const jsonObject = JSON.parse(jsonString);
        const idUser = (jsonObject.id);

        configureHeaders(headers);

        if(search === '' || search === null){
          search = 'noSearch';
        }

        const response = await fetch(`${apiUrl}/user/list/professores/${idUser}/${offset}/${search}`, {
          method: 'GET',
          headers
        });
  
        const dados = await response.json();
        return dados;
  
      } else {
        console.error("Token não encontrado no armazenamento local.");
      }
    } catch (error) {
      console.error("Erro ao buscar usuário:", error);
      throw error;
    }
  }

  export async function detail(id:string) {
    try {
  
      const token = Cookies.get("TokenBTMania");
  
      if (token) {
  
        const headers: Record<string, string> = {};

        configureHeaders(headers);

        const response = await fetch(`${apiUrl}/user/${id}`, {
          method: 'GET',
          headers,
        });
  
        const dados = await response.json();
        return dados;
  
      } else {
        console.error("Token não encontrado no armazenamento local.");
      }
    } catch (error) {
      console.error("Erro ao buscar usuário:", error);
      throw error;
    }
  }