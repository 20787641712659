import { useState } from "react";
import { GoHomeFill } from "react-icons/go";
import { IoChatbubbles } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { FaPlus } from "react-icons/fa";
import { PiUsersFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { BsPostcard } from "react-icons/bs";
import { MdOutlineSubject } from "react-icons/md";

function FooterComponent() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [exibirPasso1, setExibirPasso1] = useState(true);
  const [exibirPasso2, setExibirPasso2] = useState(false);

  const mostrarPasso2 = () => {
    setExibirPasso1(false);
    setExibirPasso2(true);
  };


  return (
    <>
      <footer className="Footer">
        <nav className="Menu">
          <ul>
            <li>
              <Link to="/home">
                <GoHomeFill className="fs-1-8em" />
              </Link>
            </li>
            <li>
              <Link to="/profiles">
                <PiUsersFill className="fs-1-8em" />
              </Link>
            </li>
            <li style={{ backgroundColor: "white" }}>
              <Link to="/post/new">
                <FaPlus className="fs-1-8em primary" />
              </Link>
            </li>
            <li>
              <Link to="/chat">
                <IoChatbubbles className="fs-1-8em" />
              </Link>
            </li>
            <li>
              <Link to="/account/profile">
                <CgProfile className="fs-1-8em" />
              </Link>
            </li>
          </ul>
        </nav>
      </footer>
      <Modal show={show} onHide={handleClose} className="modalNovaPublicacao">
        <Modal.Header>
          <Modal.Title className="cemPor text-center">
            O que deseja compartilhar?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5" id="modalPublicacao">
          
        {exibirPasso1 && (
          <div className="d-grid text-center" id="publicacaoPasso1">
          <Button onClick={mostrarPasso2} variant="outline-primary" className="rounded px-4 py-2 pt-3 btn-lg my-4 d-grid btn-add-publicacao">
              <MdOutlineSubject className="btn-add-publicacao-img" />
              <span className="btn-add-pubicacao-span">Texto</span>
            </Button>
            <Button onClick={mostrarPasso2} variant="outline-primary" className="rounded px-4 py-2 pt-3 btn-lg my-4 d-grid btn-add-publicacao">
              <BsPostcard className="btn-add-publicacao-img" />
              <span className="btn-add-pubicacao-span">Postagem</span>
            </Button>
          </div>
          )}

          {exibirPasso2 && (
          <div className="d-grid text-center" id="publicacaoPasso2">
            conteudo passo 2
          </div>
          )}


          <Button className="link-azul fs-1-2em mt-3" onClick={handleClose}>
            Cancelar
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FooterComponent;
