import React, { useState, useEffect } from 'react';
import HeaderComponent from "../../components/Header2Component";
import FooterComponent from "../../components/FooterComponent";
import { Form, Image, Col, Button, InputGroup } from "react-bootstrap";
import { IoSearch } from "react-icons/io5";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { listConversations } from "../../services/chatService";
import { getIdToken } from "../../utils/Utils";
import { FaCircleUser } from "react-icons/fa6";
import { carregarChat } from "../../services/chatService";
import { formatConversationDate } from '../../utils/Utils';
import socket from '../../socket/socket';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import StaticMenuComponent from '../../components/StaticMenuComponent';

interface Participant {
  _id: string;
  nome: string;
  srcProfile?: string;
}

interface LastMessage {
  content: string;
  createdAt: string;
  sender: string;
}

interface Conversation {
  _id: string;
  participants: Participant[];
  lastMessage?: LastMessage;
}

const ChatPage: React.FC = () => {
  const navigate = useNavigate();
  const [idUserToken, setIdUserToken] = useState<string>(getIdToken());
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [botaoCarregarMais, setbotaoCarregarMais] = useState(false);
  const [botaoDesabilitadoCarregarMais, setbotaoDesabilitadoCarregarMais] = useState(false);
  const [contadorOffset, setContadorOffset] = useState(0);
    
  const pagination = async () => {
    setbotaoDesabilitadoCarregarMais(true);
    await getList(contadorOffset, searchTerm);
    setContadorOffset(prevOffset => prevOffset + 1);
    setbotaoDesabilitadoCarregarMais(false);
  };
  
  const loadChat = async (id: string) => {
    const iniciaChat = await carregarChat(id);
    
    if (iniciaChat.conversation && iniciaChat.conversation._id) {
      navigate('/chat/message/' + iniciaChat.conversation._id, {
        state: { conversation: iniciaChat.conversation }
      });
    }
  }
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
  };
  
  async function getList(offset: number, search: string) {
    try {
      const dados = await listConversations(idUserToken, offset, search);
      
      if (dados.conversations.length > 9) {
        setbotaoCarregarMais(true);
      } else {
        setbotaoCarregarMais(false);
      }      
      
      if (offset > 0) {
        setConversations((prevList: Conversation[]) => [...prevList, ...dados.conversations]);
      } else {
        if (dados && Array.isArray(dados.conversations)) {
          setConversations(dados.conversations);
        } else {
          setConversations([]);
        }
      }
    } catch (error) {
      console.error("Erro ao buscar conversas:", error);
      setConversations([]);
    }
  }
  
  useEffect(() => {
    if (searchTerm.length > 3) {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      const timeout = setTimeout(() => {
        getList(0, searchTerm);
        setContadorOffset(1);
      }, 500);
      setDebounceTimeout(timeout);
    }
    
    if (searchTerm.length === 0 && contadorOffset === 0) {
      getList(0, '');
      setContadorOffset(1);
    }
  }, [searchTerm]);

  useEffect(() => {
    socket.on('newConversation', (newConversation: Conversation) => {
      setConversations(prevConversations => {
        const exists = prevConversations.some(conversation => conversation._id === newConversation._id);
        if (!exists) {
          return [...prevConversations, newConversation];
        }
        return prevConversations;
      });
    });

    socket.on('updateConversation', (updatedConversation: Conversation) => {
      setConversations(prevConversations => {
        const conversationExists = prevConversations.some(conversation => conversation._id === updatedConversation._id);
        if (conversationExists) {
          const updatedConversations = prevConversations.map(conversation => 
            conversation._id === updatedConversation._id ? updatedConversation : conversation
          );
          return updatedConversations;
        } else {
          return [updatedConversation, ...prevConversations];
        }
      });
    });

    return () => {
      socket.off('newConversation');
      socket.off('updateConversation');
    };
  }, []);
  
  return (
    <>
      <StaticMenuComponent />
      <HeaderComponent />
      <main>
        <div className="contentPage">
          <div className='Content1'>
            <InputGroup className="mb-3 input-group-md p-2">
              <Form.Control
                aria-label="Pesquisar..."
                placeholder="Pesquisar..."
                className="br-0 bl-search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <InputGroup.Text className="bg-white bl-0 br-search">
                <IoSearch className="bl-0" />
              </InputGroup.Text>
            </InputGroup>
            
            {conversations.length > 0 ? (
              conversations.map((conversation) => {
                const { participants, lastMessage } = conversation;
                return (
                  <Col xs={12} md={12} className="text-left py-1 px-3 d-flex zebra" onClick={() => loadChat(conversation._id)} key={conversation._id}>
                    {participants.filter(participant => participant._id !== idUserToken).map(participant => (
                      participant.srcProfile ? (
                        <LazyLoadImage src={participant.srcProfile} className="thumb-1 mt-2" key={participant._id} />
                      ) : (
                        <div className="" key={participant._id}>
                          <FaCircleUser className="fs-2em opacity-0-1 mt-2" />
                        </div>
                      )
                    ))}
                    <div className="px-3 py-2 d-flex cemPor">
                      <div style={{ width: "100%" }}>
                        <h6 className="fw-bold arial mb-0">
                          {participants.filter(participant => participant._id !== idUserToken)[0]?.nome || ''}
                        </h6>
                        <h6 className="arial mb-0">
                          {lastMessage && lastMessage.sender === idUserToken ? (<small><b>eu:</b></small>) : ''} {lastMessage ? (lastMessage.content.length > 20 ? lastMessage.content.substring(0, 25) + '...' : lastMessage.content) : 'Iniciar conversa'}
                          <small className='bold fs-08-em'>{lastMessage ? ' . ' + formatConversationDate(lastMessage.createdAt) : null}</small>
                        </h6>
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <p className='text-center py-3'>
                <small className="fs-1em text-gray">Nenhuma conversa</small>
              </p>
            )}
            
            {botaoCarregarMais && (
              <Button
                variant="primary"
                className="rounded px-4 btn-sm mb-3 mx-3 mt-3"
                onClick={pagination}
                disabled={botaoDesabilitadoCarregarMais}
              >
                CARREGAR MAIS
              </Button>  
            )}
            
          </div>
        </div>
      </main>
      <FooterComponent />
    </>
  );
}

export default ChatPage;