import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useAuth } from '../../context/AuthContext';

const LogoutPage = () => {
  const { setToken, setIsLoggedIn, setEmailConfirmed } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    Cookies.remove("TokenBTMania", { path: '/' });
    Cookies.remove("EmailConfirmed", { path: '/' });

    setToken(null);
    setIsLoggedIn(false);
    setEmailConfirmed(null);

    navigate("/", { replace: true });
  }, [navigate, setToken, setIsLoggedIn, setEmailConfirmed]);

  return null;
};

export default LogoutPage;