import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../logo-btmania-a-rede-social-do-beach-tennis.png";
import { Button, FloatingLabel, Form, Alert } from "react-bootstrap";
import { recuperarSenha } from "../../services/mailService";
import { verificaCodigo, updatePasswordByCode, login } from "../../services/userService";
import { Helmet } from "react-helmet";

function PasswordPage() {
  
  const [digitarCodigo, setDigitarCodigo] = useState(false);
  const [blocoSenha, setblocoSenha] = useState(false);
  const [retornoAlerta, setRetornoAlerta] = useState("");
  const [email, setEmail] = useState("");
  const [codigo, setCodigo] = useState("");
  const [senha, setSenha] = useState("");
  const [botaoDesabilitado1, setbotaoDesabilitado1] = useState(false);
  const [botaoDesabilitado2, setbotaoDesabilitado2] = useState(false);
  const [botaoDesabilitado3, setbotaoDesabilitado3] = useState(false);
  const [botaoExibe1, setBotaoExibe1] = useState(true);
  const [exibeBotaoDesabilitado2, setExibeBotaoDesabilitado2] = useState(false);
  const [codIdUser, setCodIdUser] = useState('');
  const navigate = useNavigate();
  
  const handleCodigoChange = (e:any) => {
    
    const value = e.target.value;
    
    if (/^\d*$/.test(value)) {
      setCodigo(value);
    }
  };
  
  const enviarSenha = async (e:any) => {
    
    let senhaVry = senha.trim();
    
    if(senhaVry.length < 6){
      
      setRetornoAlerta('Mínimo 6 dígitos!');
    }else{
      
      setbotaoDesabilitado3(true);
      
      setRetornoAlerta('');
      
      const retornoPasswordByCode = await updatePasswordByCode(email, codigo, codIdUser, senha);
      
      if (retornoPasswordByCode){
        
        if(retornoPasswordByCode.error) {
          
          setbotaoDesabilitado3(false);
          setRetornoAlerta(retornoPasswordByCode.error);
        }
        
        if(retornoPasswordByCode.user && retornoPasswordByCode.user._id) {
          
          const userData = await login(email, senha);
          const token = userData.token;
          localStorage.setItem("TokenBTMania", token);
          navigate("/home");
        }
      }
    }
  }
  
  const verificarCodigo = async (e:any) => {
    
    e.preventDefault();
    
    try{
      
      setbotaoDesabilitado2(true);
      
      if(codigo === ''){
        
        setRetornoAlerta('Digite o código!');
      }else{
        
        const retornoVerificaCodigo = await verificaCodigo(email, codigo);
        
        if(retornoVerificaCodigo._id){
          
          setCodIdUser(retornoVerificaCodigo._id);
          setExibeBotaoDesabilitado2(false);
          setblocoSenha(true);
          setRetornoAlerta('');
        }else{
          
          setRetornoAlerta('Código inválido!');
        }
      }
      
    }catch (error: any) { 
      
    }
  };
  
  const handleSubmit = async (e:any) => {
    
    e.preventDefault();
    
    try {
      
      if(email === ''){
        
        setRetornoAlerta('Digite o e-mail!');
      }else{
        
        setbotaoDesabilitado1(true);
        setRetornoAlerta('');
        
        const userData = await recuperarSenha(email);
        
        if(userData){
          
          setExibeBotaoDesabilitado2(true);
          setDigitarCodigo(true);
          setBotaoExibe1(false);
        }
        
        if(!userData){
          
          setDigitarCodigo(false);
        }
      }
      
    } catch (error: any) { 
      setBotaoExibe1(true);
      setbotaoDesabilitado1(false);
      setRetornoAlerta(error.message);
    }
  };
  
  return (
    <>
    <Helmet>
    <meta name="description" content="BT Mania é a rede social ideal para praticantes de Beach Tennis. Conecte-se com outros jogadores, compartilhe suas experiências e fique por dentro das novidades do mundo do Beach Tennis. Esqueceu sua senha? Recupere agora!" /> 
    <meta name="keywords" content="beach tennis, BT Mania, rede social beach tennis, jogadores de beach tennis, comunidade beach tennis, torneios beach tennis, dicas de beach tennis, esqueceu sua senha, recuperar senha" /> 
    <meta name="author" content="BT Mania" />
    <meta name="theme-color" content="#ffffff" /> 
    <meta name="twitter:card" content="summary_large_image" /> 
    <meta property="og:type" content="website" /> 
    <meta property="og:title" content="BT Mania - Rede Social para Praticantes de Beach Tennis. Esqueceu sua senha? Recupere agora!" /> 
    <meta property="og:description" content="BT Mania é a rede social ideal para praticantes de Beach Tennis. Conecte-se com outros jogadores, compartilhe suas experiências e fique por dentro das novidades do mundo do Beach Tennis. Esqueceu sua senha? Recupere agora!" /> 
    <meta property="og:image" content="https://btmania.com.br/assets/images/logo-200-200.png" /> 
    <meta property="og:url" content="https://btmania.com.br" /> 
    <meta name="twitter:site" content="@bt_mania" /> 
    <meta name="twitter:title" content="BT Mania - Rede Social para Praticantes de Beach Tennis. Esqueceu sua senha? Recupere agora!" /> 
    <meta name="twitter:description" content="BT Mania é a rede social ideal para praticantes de Beach Tennis. Conecte-se com outros jogadores, compartilhe suas experiências e fique por dentro das novidades do mundo do Beach Tennis. Esqueceu sua senha? Recupere agora!" /> 
    <meta name="twitter:image" content="https://btmania.com.br/assets/images/logo-200-200.webp" /> 
    <meta name="twitter:url" content="https://btmania.com.br" /> 
    <meta name="twitter:creator" content="@bt_mania" /> 
    <meta name="twitter:domain" content="https://btmania.com.br" /> 
    <title>BT Mania - Rede Social para Praticantes de Beach Tennis. Esqueceu sua senha? Recupere agora!</title>
    </Helmet>
    <main className="centralize">
    <form className="w-300">
    <img src={logo} alt="BT Mania" className="logo" />
    <h5 className="cemPor textCenter primary fw-bold mb-3 mt-3">Gerar nova senha</h5>
    <h6 className="cemPor textCenter black mb-3 mt-3">Digite seu e-mail</h6>
    <FloatingLabel controlId="floatingInput" label="Seu e-mail" className="mb-3">
    
    <Form.Control
    type="email"
    placeholder="Seu e-mail"
    className="bg-light"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    autoComplete="off"
    required
    />
    </FloatingLabel>
    
    {botaoExibe1 && (
      <Button
      type="button"
      variant="primary"
      size="lg"
      className="mt-1 rounded py-3"
      disabled={botaoDesabilitado1}
      onClick={handleSubmit}
      >
      <div className="loader"></div>
      <span className="text-button">GERAR CÓDIGO</span>
      </Button>
    )}
    
    {digitarCodigo && (
      <>
      <p className="text-center">Digite o código recebido em seu e-mail</p>
      <FloatingLabel controlId="floatingInput" label="Digite o código recebido" className="mb-3">
      <Form.Control
      type="tel"
      placeholder="Digite o código recebido"
      className="bg-light"
      value={codigo}
      onChange={handleCodigoChange}
      maxLength={6}
      autoComplete="off"
      style={{ textAlign: 'center' }}
      required
      />
      </FloatingLabel>
      
      {exibeBotaoDesabilitado2 && (
        <Button
        type="button"
        variant="primary"
        size="lg"
        className="mt-1 rounded py-3"
        disabled={botaoDesabilitado2}
        onClick={verificarCodigo}
        >
        <div className="loader"></div>
        <span className="text-button">VALIDAR CÓDIGO</span>
        </Button>
      )}
      
      </>
    )}
    
    {blocoSenha && (
      <>
      <p className="text-center">Agora crie uma nova senha</p>
      <FloatingLabel controlId="floatingInput" label="Digite a nova senha" className="mb-3">
      <Form.Control
      type="email"
      placeholder="Digite a nova senha"
      className="bg-light"
      value={senha}
      onChange={(e) => setSenha(e.target.value)}
      autoComplete="off"
      required
      />
      </FloatingLabel>
      
      <Button
      type="button"
      variant="primary"
      size="lg"
      className="mt-1 rounded py-3"
      disabled={botaoDesabilitado3}
      onClick={enviarSenha}
      >
      <div className="loader"></div>
      <span className="text-button">FINALIZAR</span>
      </Button>
      
      </>
    )}
    
    
    {retornoAlerta && <Alert key="warning" variant="warning" className="mt-3 text-center">{retornoAlerta}</Alert>}
    
    <hr className="divider mt-3" />
    <Link to="/">
    <Button variant="success" size="lg" className="mt-4 rounded py-3">IR PARA LOGIN</Button>
    </Link>
    </form>
    </main>
    </>
  );
}

export default PasswordPage;
