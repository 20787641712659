import { configureHeaders } from '../utils/Utils';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

let apiUrl: string = process.env.REACT_APP_HOST_API as string;

export async function totalComments(idPost: String) {
    try {
        
        const token = Cookies.get("TokenBTMania");
        
        if (token) {

            const headers: Record<string, string> = {};
                        
            configureHeaders(headers);
            
            const response = await fetch(`${apiUrl}/post-comment/total/${idPost}`, {
            method: 'GET',
            headers
        });
        
        const dados = await response.json();
        return dados;
        
    } else {
        console.error("Token não encontrado no armazenamento local.");
    } } catch (error) {
        console.error("Erro ao buscar total de comentários:", error);
        throw error;
    }
}  

export async function listComments(idPost: String, offset: Number) {
    try {
        
        const token = Cookies.get("TokenBTMania");
        
        if (token) {

            const headers: Record<string, string> = {};
                        
            configureHeaders(headers);
            
            const response = await fetch(`${apiUrl}/post-comment/${idPost}/${offset}`, {
            method: 'GET',
            headers
        });
        
        const dados = await response.json();
        return dados;
        
    } else {
        console.error("Token não encontrado no armazenamento local.");
    } } catch (error) {
        console.error("Erro ao buscar comentários:", error);
        throw error;
    }
}  

export async function Create(idPost: string, texto: string, returnList: number): Promise<any> {
    
    try {
        
        const token = Cookies.get("TokenBTMania");
        
        if (token) {
            
            if(!texto){
                
                return { error: 'Comentário sem conteúdo' };
            }
            
            if(!idPost){
                
                return { error: 'Publicação não encontrada' };
            }
            
            const decoded = jwtDecode(token);
            
            const jsonString = JSON.stringify(decoded);
            const jsonObject = JSON.parse(jsonString);
            const idUser = (jsonObject.id);
            
            const headers: Record<string, string> = {};
            
            configureHeaders(headers);
            
            const response = await fetch(`${apiUrl}/post-comment`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ idPost, texto, idUser, returnList }),
        });
        
        const data = await response.json();
        return data;
        
    } else {
        console.error("Token não encontrado no armazenamento local.");
    }} catch (error) {
        
        throw error;
    }
}

export async function deletePostComment(id: string) {
  
    try {
      
      const token = Cookies.get("TokenBTMania");
      
      if (token) {
  
        const decoded = jwtDecode(token);
        
        const jsonString = JSON.stringify(decoded);
        const jsonObject = JSON.parse(jsonString);
        const idUser = (jsonObject.id);
        
        const headers: Record<string, string> = {};
        
        configureHeaders(headers);
        
        const response = await fetch(`${apiUrl}/post-comment/remove`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ id, idUser }),
      });
      
      const data = await response.json();
      return data;
      
    } else {
      console.error("Token não encontrado no armazenamento local.");
    }
    
  } catch (error) {
    
    throw error;
  }
  }