import React from "react";
import { Link } from "react-router-dom";
import logo from "../../logo-btmania-a-rede-social-do-beach-tennis.png";
import { Button } from "react-bootstrap";

function Politicadeprivacidade() {
  return (
    <main className="centralize">
      <img src={logo} alt="BT Mania" className="logo" />
      <h5 className="cemPor textCenter primary fw-bold mb-3 mt-3">
        Política de Privacidade
      </h5>
      <hr className="divider mt-3" />
      <section className="textLeft px-4">
        <h6 className="fw-bold">O Serviço do BT Mania</h6>
        <p>
          Concordamos em fornecer-te o Serviço do BT Mania. O Serviço inclui
          todos os produtos, funcionalidades, apps, serviços, tecnologias e
          software do BT Mania que fornecemos para fomentar a missão do BT
          Mania: aproximar-te das pessoas e dos conteúdos de que gostas. O
          Serviço é composto pelos seguintes aspectos:
        </p>
        <h6 className="fw-bold">Oferecer oportunidades personalizadas</h6>
        <p>
          Oferecemos-te diferentes tipos de contas e funcionalidades para te
          ajudar a criar, partilhar, aumentar a tua presença e comunicar com
          pessoas dentro e fora do BT Mania. Também pretendemos fortalecer as
          tuas relações através de experiências partilhadas que te interessem.
          Por isso, criamos sistemas que tentam compreender de quem e do que é
          que tu e as outras pessoas gostam, e utilizamos essas informações
          para te ajudar a criar, encontrar, participar e partilhar
          experiências importantes para ti. Fazemo-lo, por exemplo, ao destacar
          conteúdos, funcionalidades, ofertas e contas que podem ser do teu
          interesse, e ao oferecer-te formas diferentes de experimentar o BT
          Mania, com base no que tu e outras pessoas fazem dentro e fora do
          mesmo.
        </p>
        <h6 className="fw-bold">Promover um ambiente positivo, inclusivo e seguro</h6>
        <p>
          Desenvolvemos e utilizamos ferramentas e fornecemos recursos aos
          membros da nossa comunidade que ajudam a tornar as respetivas
          experiências inclusivas e positivas, incluindo quando pensamos que
          os mesmos podem precisar de ajuda. Também temos equipas e sistemas
          que trabalham para combater abusos e infrações aos nossos Termos e
          políticas, bem como comportamentos prejudiciais e enganadores.
          Utilizamos todas as informações que temos, incluindo as tuas
          informações, para tentar manter a nossa plataforma segura.
        </p>
        <h6 className="fw-bold">Desenvolver e utilizar tecnologias</h6>
        <p>
          Organizar e analisar informações para a nossa comunidade em
          crescimento é essencial para o nosso Serviço. Grande parte do nosso
          Serviço consiste em criar e utilizar tecnologias de ponta que nos
          ajudam a personalizar, proteger e melhorar o nosso Serviço em grande
          escala para uma vasta comunidade global. As tecnologias como a
          inteligência artificial e a aprendizagem automática permitem-nos
          aplicar processos complexos no nosso Serviço. As tecnologias de
          automatização também nos ajudam a garantir a funcionalidade e a
          integridade do nosso Serviço.
        </p>
        <h6 className="fw-bold">Fornecer experiências consistentes e integradas</h6>
        <p>
          O BT Mania faz parte da Empresa Solutio Web, que partilha tecnologia,
          sistemas, estatísticas e informações, incluindo as informações que
          temos sobre ti, para fornecer serviços otimizados e mais seguros.
        </p>
        <h6 className="fw-bold">Ligar-te a marcas, produtos e serviços que te interessem</h6>
        <p>
          Utilizamos dados provenientes do BT Mania bem como de parceiros externos, para te mostrar
          anúncios, ofertas e outros conteúdos patrocinados que acreditamos que
          vão ser importantes para ti. Além disso, tentamos tornar esses
          conteúdos tão relevantes como todas as tuas outras experiências no BT
          Mania.
        </p>
        <h6 className="fw-bold">Pesquisa e inovação</h6>
        <p>
          Utilizamos as informações que temos para estudar o nosso Serviço e
          colaborar com outras pessoas em pesquisas para melhorar o nosso
          Serviço e contribuir para o bem-estar da nossa comunidade.
        </p>
      </section>
      <Link to="/">
        <Button
          variant="outline-success"
          size="lg"
          className="mt-4 rounded py-3"
        >
          VOLTAR PARA PÁGINA INICIAL
        </Button>
      </Link>
    </main>
  );
}

export default Politicadeprivacidade;