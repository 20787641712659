import React, { useRef, useEffect, useState } from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { Button } from "react-bootstrap";
import { MdAddAPhoto, MdOutlineAddPhotoAlternate, MdFlipCameraAndroid } from "react-icons/md";
import { isMobile } from 'react-device-detect';

interface ImagePostComponentProps {
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    srcProfile: string;
    onImageCrop: (croppedImage: string | '') => void;
}

const ImagePostComponent: React.FC<ImagePostComponentProps> = ({ setIsVisible, srcProfile, onImageCrop }) => {
    
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const cropperRef = useRef<HTMLImageElement>(null);
    const cropperInstance = useRef<Cropper | null>(null);
    const [isAddPhotoVisible, setIsAddPhotoVisible] = useState(true);
    const [isEditPhotoVisible, setIsEditPhotoVisible] = useState(false);
    const [fotoCortada, setFotoCortada] = useState(false);
    const [croppedImage, setCroppedImage] = useState<string | null>(null);
    const [profileImageSrc, setProfileImageSrc] = useState<string>("");
    const [tirarFoto, setTirarFoto] = useState(false);
    const [isTirarFoto, setIsTirarFoto] = useState(false);
    const [facingMode, setFacingMode] = useState<"user" | "environment">("environment");

    const [screenWidth, setScreenWidth] = useState<number>(() => {
        let initialScreenWidth = window.innerWidth * 0.9;
        if (!isMobile) {
            initialScreenWidth = 400;
        }
        return initialScreenWidth;
    });

    const handleResize = () => {
        let newScreenWidth = window.innerWidth * 0.9;

        if (newScreenWidth > 600) {
            newScreenWidth = 600;
        } else if (newScreenWidth > 500) {
            newScreenWidth = 500;
        } else if (newScreenWidth > 400) {
            newScreenWidth = 400;
        } else {
            newScreenWidth = 300;
        }
        setScreenWidth(newScreenWidth);
    };
    
    const handleButtonClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };
    
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const imgElement = cropperRef.current;
                if (imgElement && event.target) {
                    imgElement.src = event.target.result as string;
                    if (cropperInstance.current) {
                        cropperInstance.current.destroy();
                        cropperInstance.current = null;
                    }
                    cropperInstance.current = new Cropper(imgElement, {
                        aspectRatio: 1,
                        cropBoxResizable: false,
                        center: true,
                        minCanvasHeight: 300,
                        minContainerHeight: 300,
                        minCropBoxHeight: 300,
                        minCanvasWidth: 300,
                        minContainerWidth: 300,
                        minCropBoxWidth: 300,
                        dragMode: 'move',
                        cropBoxMovable: false,
                        data: {
                            x: 1,
                            scaleX: 1,
                            scaleY: 1,
                        },
                        crop: () => {
                        },
                        ready: () => {
                            const cropper = cropperInstance.current;
                            if (cropper) {
                                const imageData = cropper.getImageData();
                                const canvasData = cropper.getCanvasData();
                                const canvasWidth = canvasData.width;
                                const canvasHeight = canvasData.height;
                                const cropBoxWidth = canvasWidth; 
                                const cropBoxHeight = canvasHeight;
                                const cropBoxAspectRatio = cropBoxWidth / cropBoxHeight;
                                cropper.setCropBoxData({
                                    width: cropBoxWidth,
                                    height: cropBoxHeight,
                                    left: (canvasWidth - cropBoxWidth) / 2,
                                    top: (canvasHeight - cropBoxHeight) / 2,
                                });
                            }
                        },
                    });
                }
            };
            reader.readAsDataURL(file);
            setIsVisible(false);
            setIsAddPhotoVisible(false);
            setIsEditPhotoVisible(true);
            const icoPhotoProfile = document.getElementById("icoPhotoProfile");
            if (icoPhotoProfile) icoPhotoProfile.style.display = "none";
            const imgFullCropContainer = document.getElementById("imgFullCropContainer");
            if (imgFullCropContainer) imgFullCropContainer.style.display = "block";
        }
    };

    const resizeAndCompressImage = async (base64Image: string, maxSize: number): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const img = new Image();
            img.src = base64Image;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    reject('Erro ao obter contexto de canvas');
                    return;
                }
                let width = img.width;
                let height = img.height;
                let offsetX = 0;
                let offsetY = 0;
                if (width > height) {
                    height = maxSize;
                    width = Math.round(maxSize * (img.width / img.height));
                    offsetX = (width - height) / 2;
                } else {
                    width = maxSize;
                    height = Math.round(maxSize * (img.height / img.width));
                    offsetY = (height - width) / 2;
                }
                canvas.width = maxSize;
                canvas.height = maxSize;
                ctx.drawImage(img, -offsetX, -offsetY, width, height);
                const compressedBase64 = canvas.toDataURL('image/jpeg', 0.7);
                resolve(compressedBase64);
            };
            img.onerror = () => {
                reject('Erro ao carregar a imagem');
            };
        });
    };    
    
    const startCamera = async () => {
        try {
            setIsAddPhotoVisible(false);
            setTirarFoto(true);
            setIsTirarFoto(true);
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode } });
            const videoElement = document.getElementById("blocoVideo");
            const videoWidth = Math.round(videoElement?.getBoundingClientRect().width || 0);
            if (videoElement) {
                videoElement.style.width = `${videoWidth}px`;
                videoElement.style.height = `${videoWidth}px`;
            }
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            }
        } catch (error) {
            console.error('Erro ao acessar a câmera:', error);
        }
    };

    const flipCamera = () => {
        setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
    };

    useEffect(() => {
        if (isTirarFoto) {
            startCamera();
        }
    }, [facingMode]);

    const handleCropTirarFoto = async () => {
        if (videoRef.current) {
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
                const croppedImageBase64 = canvas.toDataURL('image/jpeg');
                const resizedAndCompressedImageBase64 = await resizeAndCompressImage(croppedImageBase64, screenWidth);
                setCroppedImage(resizedAndCompressedImageBase64);
                const profileImageElement = document.getElementById("profileImage");
                if (profileImageElement) profileImageElement.setAttribute("src", resizedAndCompressedImageBase64);
                setFotoCortada(true);
                onImageCrop(resizedAndCompressedImageBase64);
                setTirarFoto(false);
                setIsTirarFoto(false);
                if (videoRef.current) {
                    const stream = videoRef.current.srcObject as MediaStream;
                    if (stream) {
                        const tracks = stream.getTracks();
                        tracks.forEach(track => track.stop());
                        videoRef.current.srcObject = null;
                    }
                }
            }
        }
    };
       
    const handleCrop = async () => {
        if (cropperInstance.current) {
            const canvas = cropperInstance.current.getCroppedCanvas();
            if (canvas) {
                const imgFullCrop = document.getElementById("imgFullCrop");
                if (imgFullCrop) imgFullCrop.setAttribute("src", "");
                const imgFullCropContainer = document.getElementById("imgFullCropContainer");
                if (imgFullCropContainer) imgFullCropContainer.style.display = "none";
                const icoPhotoProfile = document.getElementById("icoPhotoProfile");
                if (icoPhotoProfile) icoPhotoProfile.style.display = "block";
                const croppedImageBase64 = canvas.toDataURL();
                const resizedAndCompressedImageBase64 = await resizeAndCompressImage(croppedImageBase64, screenWidth);
                setCroppedImage(resizedAndCompressedImageBase64);
                const profileImageElement = document.getElementById("profileImage");
                if (profileImageElement) profileImageElement.setAttribute("src", resizedAndCompressedImageBase64);
                setFotoCortada(true);
                onImageCrop(resizedAndCompressedImageBase64);
                setIsEditPhotoVisible(false);
            }
            cropperInstance.current.destroy();
            cropperInstance.current = null;
        }
    };
    
    useEffect(() => {
        setProfileImageSrc(srcProfile);
    }, [srcProfile]);
    
    useEffect(() => {
        const imageElement = cropperRef.current;
        if (imageElement && cropperInstance.current === null) {
            cropperInstance.current = new Cropper(imageElement, {
                aspectRatio: 1,
                cropBoxResizable: false
            });
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            if (cropperInstance.current) {
                cropperInstance.current.destroy();
                cropperInstance.current = null;
            }
        };
    }, []);
    
    return (
        <>
            <input type="file" style={{ display: "none" }} ref={inputRef} onChange={handleFileChange} />
            <div style={{maxWidth:'500px', maxHeight:'500px'}}>
                {tirarFoto && (
                    <video ref={videoRef} autoPlay playsInline className="noventaPor" id="blocoVideo" width="100%" height="100%" style={{ objectFit: 'cover' }} />
                )}
                <div style={{ width: `${screenWidth}px`, height: `${screenWidth}px`, position: 'relative', display: 'none', margin: '0 auto' }} id="imgFullCropContainer">
                    <img ref={cropperRef} id="imgFullCrop" alt="Foto do perfil" style={{ display: "none" }} />
                </div>
            </div>
            {isAddPhotoVisible && (
                <div className="d-flex mx-2">
                    <div style={{ flex: 1 }}>
                        <Button onClick={startCamera} id="btTirarFoto" variant="outline-primary" className="rounded py-2 btn-lg mx-1 my-4 d-grid btn-add-publicacao">
                            <MdAddAPhoto className="btn-add-publicacao-img" />
                            <label className="mt-2">Tirar foto</label>
                        </Button>
                    </div>
                    <div onClick={handleButtonClick} style={{ flex: 1 }}>
                        <Button variant="outline-primary" className="rounded py-2 btn-lg mx-1 my-4 d-grid btn-add-publicacao">
                            <MdOutlineAddPhotoAlternate className="btn-add-publicacao-img" />
                            <label className="mt-2">Selecionar imagem</label>
                        </Button>
                    </div>
                </div>
            )}
            {tirarFoto && (
                <div className="d-flex justify-content-center mt-1 px-3">
                    <Button onClick={flipCamera} variant="outline-secondary" className="rounded py-2 mx-1 d-grid btn-add-publicacao mt-2 mb-0">
                        <MdFlipCameraAndroid className="btn-add-publicacao-img" />
                        <label>Alternar Câmera</label>
                    </Button>
                </div>
            )}
            {fotoCortada && (
                <div className="cemPor mb-4">
                    {croppedImage && <img src={croppedImage} alt="Imagem recortada" />}
                </div>
            )}
            {isEditPhotoVisible && (
                <div>            
                    <div className="mt-3">
                        <Button
                            variant="primary"
                            size="lg"
                            className="mb-3 mx-4 rounded py-3"
                            onClick={handleCrop}
                        >
                            CORTAR IMAGEM
                        </Button>
                    </div>
                </div>
            )}
            {isTirarFoto && (
                <div>            
                    <div className="mt-3">
                        <Button
                            variant="primary"
                            size="lg"
                            className="mb-3 mx-4 rounded py-3"
                            onClick={handleCropTirarFoto}
                        >
                            TIRAR FOTO
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};

export default ImagePostComponent;