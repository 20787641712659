import { configureHeaders } from '../utils/Utils';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

let apiUrl: string = process.env.REACT_APP_HOST_API as string;

export async function listFollowers(idUser: string, offset: Number) {
  try {
      
      const token = Cookies.get("TokenBTMania");
      
      if (token) {

          const headers: Record<string, string> = {};
                      
          configureHeaders(headers);
          
          const response = await fetch(`${apiUrl}/follower/list/followers/${idUser}/${offset}`, {
          method: 'GET',
          headers
      });
      
      const dados = await response.json();
      return dados;
      
  } else {
      console.error("Token não encontrado no armazenamento local.");
  } } catch (error) {
      console.error("Erro ao buscar seguidores:", error);
      throw error;
  }
}  

export async function listFollowings(idUser: string, offset: Number) {
  try {
      
      const token = Cookies.get("TokenBTMania");
      
      if (token) {

          const headers: Record<string, string> = {};
                      
          configureHeaders(headers);
          
          const response = await fetch(`${apiUrl}/follower/list/followings/${idUser}/${offset}`, {
          method: 'GET',
          headers
      });
      
      const dados = await response.json();
      return dados;
      
  } else {
      console.error("Token não encontrado no armazenamento local.");
  } } catch (error) {
      console.error("Erro ao buscar perfis seguindo:", error);
      throw error;
  }
}  

export async function totalFollower(idUser: string) {
  try {
      
      const token = Cookies.get("TokenBTMania");
      
      if (token) {

          const headers: Record<string, string> = {};
                      
          configureHeaders(headers);
          
          const response = await fetch(`${apiUrl}/follower/total/followers/${idUser}`, {
          method: 'GET',
          headers
      });
      
      const dados = await response.json();
      return dados;
      
  } else {
      console.error("Token não encontrado no armazenamento local.");
  } } catch (error) {
      console.error("Erro ao buscar comentários:", error);
      throw error;
  }
}  

export async function totalFollowing(idUser: string) {
  try {
      
      const token = Cookies.get("TokenBTMania");
      
      if (token) {

          const headers: Record<string, string> = {};
                      
          configureHeaders(headers);
          
          const response = await fetch(`${apiUrl}/follower/total/following/${idUser}`, {
          method: 'GET',
          headers
      });
      
      const dados = await response.json();
      return dados;
      
  } else {
      console.error("Token não encontrado no armazenamento local.");
  } } catch (error) {
      console.error("Erro ao buscar comentários:", error);
      throw error;
  }
}  

export async function follow(followedId: string) {
  try {

    const headers: Record<string, string> = {};

    configureHeaders(headers);

    const token = Cookies.get("TokenBTMania");

    if (token) {

        const decoded = jwtDecode(token);

        const jsonString = JSON.stringify(decoded);
        const jsonObject = JSON.parse(jsonString);
        const followerId = (jsonObject.id);

      const response = await fetch(`${apiUrl}/follower/`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ followerId, followedId }),
      });

      const dados = await response.json();
      return dados;

    } else {
      console.error("Token não encontrado no armazenamento local.");
    }
  } catch (error) {
    console.error('Erro ao atualizar dados do perfil:', error);
    throw error;
  }
}

export async function is(followedId: string) {
  try {

    const headers: Record<string, string> = {};

    configureHeaders(headers);

    const token = Cookies.get("TokenBTMania");

    if (token) {

        const decoded = jwtDecode(token);

        const jsonString = JSON.stringify(decoded);
        const jsonObject = JSON.parse(jsonString);
        const followerId = (jsonObject.id);

      const response = await fetch(`${apiUrl}/follower/is`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ followerId, followedId }),
      });

      const dados = await response.json();
      return dados;

    } else {
      console.error("Token não encontrado no armazenamento local.");
    }
  } catch (error) {
    console.error('Erro ao atualizar dados do perfil:', error);
    throw error;
  }
}