import React, { useState } from "react";
import { Button, FloatingLabel, Form, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../../components/HeaderComponent";
import FooterComponent from "../../components/FooterComponent";
import { useAuth } from "../../context/AuthContext";
import { verificaCadastro } from '../../services/userService';

function VerifyPage() {
  const [show, setShow] = useState(false);
  const [botaoVerificaCodigo, setBotaoVerificaCodigo] = useState(true);
  const [codigo, setCodigo] = useState("");
  const [alertVariant, setAlertVariant] = useState("success");
  const [alertContent, setAlertContent] = useState("");
  const { setEmailConfirmed } = useAuth();
  const navigate = useNavigate();

  const handleCodigoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodigo(e.target.value);
  };

  const handleVerifyCode = async () => {
    try {
      
      const result = await verificaCadastro(codigo);
      
      if (!result.error) {
        setBotaoVerificaCodigo(false);
        setAlertVariant("success");
        setAlertContent("Email confirmado com sucesso!");
        setEmailConfirmed(true);
        setShow(true);
        setTimeout(() => {
          setShow(false);
          navigate("/account/profile/edit");
        }, 3000);
      } else {
        setAlertVariant("danger");
        setAlertContent(result.error);
        setShow(true);
      }
    } catch (error) {
      setAlertVariant("danger");
      setAlertContent("Erro ao verificar o código. Por favor, tente novamente mais tarde.");
      setShow(true);
    } 
  };

  return (
    <>
      <HeaderComponent />
      <main>
        <div className="Content1">
          <h4 className="py-3 px-5 text-center">Digite o código enviado em seu e-mail para validar seu cadastro.</h4>
          <FloatingLabel controlId="floatingInput" label="Código de Verificação" className="mb-3 mx-4">
            <Form.Control
              type="text"
              placeholder="Código"
              className="bg-light"
              value={codigo}
              onChange={handleCodigoChange}
            />
          </FloatingLabel>
          <Alert variant={alertVariant} show={show} className="mx-4">
            <p className="my-0 text-center">{alertContent}</p>
          </Alert>
          {botaoVerificaCodigo && (
          <Button
            variant="primary"
            size="lg"
            className="mb-3 mx-4 rounded py-3"
            onClick={handleVerifyCode}
          >
            VERIFICAR CÓDIGO
          </Button>
          )}
        </div>
      </main>
      <FooterComponent />
    </>
  );
}

export default VerifyPage;