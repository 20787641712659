import React, { useEffect, useState, FormEvent } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Spinner, Button, FloatingLabel, Form, Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import logo from "../../logo-btmania-a-rede-social-do-beach-tennis.png";
import { isMobile } from 'react-device-detect';
import { createUser } from "../../services/userService";
import { useAuth } from "../../context/AuthContext";

interface FormData {
  nome: string;
  genero: string;
  email: string;
  senha: string;
}

interface Post {
  title: string;
  imageUrl: string;
  [key: string]: string;
}

const BlogPostPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setEmailConfirmed, setIsLoggedIn, setToken } = useAuth();
  const [retornoAlerta, setRetornoAlerta] = useState("");

  const [formData, setFormData] = useState<FormData>({
    nome: "",
    genero: "",
    email: "",
    senha: ""
  });

  const [botaoDesabilitado1, setbotaoDesabilitado1] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setbotaoDesabilitado1(true);
      const userData = await createUser(formData);
      const token = userData.token;
      setToken(token);
      setIsLoggedIn(true);
      setEmailConfirmed(false);
      navigate("/account/profile/edit");
    } catch (error: any) {
      setbotaoDesabilitado1(false);
      setRetornoAlerta(error.message);
    }
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`/blog/${slug}.json`);
        if (!response.ok) {
          navigate("/404");
        }
        const data = await response.json();
        setPost(data);
      } catch (error) {
        setError('Postagem não encontrada');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug, navigate]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": post?.title,
    "image": post?.imageUrl,
    "articleBody": post?.content,
    "author": {
      "@type": "Organization",
      "name": "BT Mania"
    },
    "publisher": {
      "@type": "Organization",
      "name": "BT Mania",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.btmania.com.br/assets/images/logo-200-200.png"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `https://www.btmania.com.br/blog/${slug}`
    },
    "datePublished": new Date().toISOString(),
    "dateModified": new Date().toISOString()
  };

  const getContentSnippet = (post: Post | null, maxLength: number): string => {
    if (!post) return '';
    let snippet = "";
    let i = 0;
    while (post[`content${i}`] !== undefined && snippet.length < maxLength) {
      const content = post[`content${i}`].replace(/<[^>]+>/g, '');
      if ((snippet + content).length > maxLength) {
        snippet += content.slice(0, maxLength - snippet.length);
      } else {
        snippet += content;
      }
      i++;
    }
    return snippet;
  };

  const contentSnippet = getContentSnippet(post, 160);

  const renderContent = () => {
    const contents = [];
    let i = 0;

    if (post) {
      while (post[`content${i}`] !== undefined) {
        contents.push(
          <div key={i} className="px-2 mt-3" dangerouslySetInnerHTML={{ __html: post[`content${i}`] }} />
        );
        i++;
      }
    }
    return contents;
  };

  const style = document.createElement("style");

  style.innerHTML = `
  .contentPost p,h1,h2,h3,h4,h5,h6{ 
    font-family: arial !important; 
  }
  .contentPost p{
    padding: 15px 0;
    margin: 0;
    float: left;
  }
  .contentPost picture{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    float: left;
  }
  img {
  width: 100%;
  height: auto;
  }
  @font-face {
    font-display: swap;
  }
  h1{
  font-size: 1.5em;
  }
  h2{
  font-size: 1.3em;
  text-align:center;
  }
  h3{
  font-size: 1.2em;
  font-weight: bold;
  float: left;
  width: 100%;
  padding-top: 15px;
  }
  h4{
  font-size: 1.1em;
  float: left;
  width: 100%;
  padding-top: 15px;
  }
  ul{
    padding-left: 1rem;
  }
  li{
    padding-bottom: 10px;
    list-style-type: disc;
  }
  `;
  document.head.appendChild(style);

  return (
    <Container className="mt-2">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
        <meta name="description" content={contentSnippet} />
        <meta name="keywords" content={`beach tennis, BT Mania, ${post?.title}`} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post?.title} />
        <meta property="og:description" content={contentSnippet} />
        <meta property="og:image" content={post?.imageUrl} />
        <meta property="og:url" content={`https://www.btmania.com.br/blog/${slug}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post?.title} />
        <meta name="twitter:description" content={contentSnippet} />
        <meta name="twitter:image" content={post?.imageUrl} />
        <meta name="twitter:url" content={`https://www.btmania.com.br/blog/${slug}`} />
        <title>{post?.title}</title>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6410560563298586" crossOrigin="anonymous"></script>     
      </Helmet>
      <div className='cemPor text-center'>
        <img src={logo} alt="BT Mania" className="logo" loading='lazy' />
      </div>
      <Row>
        <Col md={{ span: 8, offset: 2 }} className='arial contentPost'>
          <h1 className='pb-2 pt-2 px-1 text-center arial'>{post?.title}</h1>
          <Card className='border-none text-center'>
            {post && (
              <picture>
                <source media="(min-width: 1200px)" srcSet={`${post.imageUrl}?w=1800&h=1000`} />
                <source media="(max-width: 1199px)" srcSet={`${post.imageUrl}?w=800&h=800`} />
                <img src={`${post.imageUrl}`} alt={post.title} className="img-fluid" loading='lazy' />
              </picture>
            )}
          </Card>
          {renderContent()}
        </Col>
      </Row>
      <hr className="divider mt-1" />
      <div className='cemPor text-center mb-5'>
        <form className="w-300" onSubmit={handleSubmit} style={{ margin: "0 auto" }}>
          <img src={logo} alt="BT Mania" className="logo" />
          <h5 className="cemPor textCenter primary fw-bold mb-3 mt-3">
            Faça seu cadastro
          </h5>
          <FloatingLabel
            controlId="nome"
            label="Seu primeiro nome"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Seu primeiro nome"
              name="nome"
              value={formData.nome}
              onChange={(e) => setFormData({ ...formData, nome: e.target.value })}
              className="bg-light"
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="genero"
            label="Gênero"
            className="mb-3"
          >
            <Form.Select
              aria-label="Gênero"
              name="genero"
              value={formData.genero}
              onChange={(e) => setFormData({ ...formData, genero: e.target.value })}
            >
              <option value="">Selecione</option>
              <option value="Feminino">Feminino</option>
              <option value="Masculino">Masculino</option>
              <option value="Transgênero">Transgênero</option>
              <option value="Prefiro não informar">Prefiro não informar</option>
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel
            controlId="email"
            label="Seu e-mail"
            className="mb-3"
          >
            <Form.Control
              type="email"
              placeholder="Seu e-mail"
              name="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="bg-light"
            />
          </FloatingLabel>
          <FloatingLabel controlId="senha" label="Crie uma senha">
            <Form.Control
              type="password"
              placeholder="Crie uma senha"
              name="senha"
              value={formData.senha}
              onChange={(e) => setFormData({ ...formData, senha: e.target.value })}
              className="bg-light"
            />
          </FloatingLabel>
          <Button variant="primary" size="lg" type="submit" className="mt-4 rounded py-3" disabled={botaoDesabilitado1}>
            <div className="loader"></div>
            <span className="text-button">CADASTRAR</span>
          </Button>

          {retornoAlerta && <Alert key="warning" variant="warning" className="mt-3 text-center px-4">{retornoAlerta}</Alert>}

          <p className="text-center mt-3">
            <Link to="/esqueci-minha-senha">
              <Button variant="outline" className="link primary">
                Esqueci minha senha
              </Button>
            </Link>
          </p>
          <hr className="divider mt-3" />
          <Link to="/">
            <Button
              variant="success"
              size="lg"
              className="mt-4 rounded py-3"
            >
              IR PARA LOGIN
            </Button>
          </Link>
        </form>
      </div>
    </Container>
  );
};

export default BlogPostPage;