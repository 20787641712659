import React, { createContext, useState, useEffect, useContext, ReactNode } from "react";
import { Button, Col } from "react-bootstrap";
import PullToRefresh from 'react-pull-to-refresh';
import FooterComponent from "../../components/FooterComponent";
import StaticMenuComponent from "../../components/StaticMenuComponent";
import HeaderComponent from "../../components/Header2Component";
import { getUnviewedNotifications, markNotificationsAsViewed } from '../../services/notificationService';
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

interface Notification {
    _id: string;
    tipo: string;
    usuarioDestino: string;
    usuarioOrigem: {
        _id: string;
        nome: string;
        profile: string;
        srcProfile: string;
    };
    idPost?: {
        _id: string;
        srcImagem: string;
    };
    idComentario?: string;
    dataNotificacao: string;
    visualizada: boolean;
    mensagem: string;
    srcProfile: string;
    srcImagem: string;
    linkPostagem: string;
}

interface NotificationContextType {
    notifications: Notification[];
    loading: boolean;
    unviewedCount: number;
    markAsViewed: (notificationId: string) => void;
    setUnviewedCount: (count: number) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [loading, setLoading] = useState(true);
    const [unviewedCount, setUnviewedCount] = useState(0);

    useEffect(() => {
        fetchUnviewedNotifications();
        const interval = setInterval(fetchUnviewedNotifications, 60000);
        return () => clearInterval(interval);
    }, []);

    const fetchUnviewedNotifications = async () => {
        setLoading(true);
        try {
            const unviewedNotifications = await getUnviewedNotifications();

            console.log(unviewedNotifications.notifications);

            if (Array.isArray(unviewedNotifications.notifications)) {
                setNotifications(unviewedNotifications.notifications);
                const count = unviewedNotifications.notifications.length;
                setUnviewedCount(count);
                localStorage.setItem('unviewedCount', count.toString());
            } else {
                console.error("Erro: getUnviewedNotifications não retornou um array válido:", unviewedNotifications.notifications);
            }
        } catch (error) {
            console.error("Erro ao buscar notificações:", error);
        } finally {
            setLoading(false);
        }
    };    

    const markAsViewed = async () => {
        try {
            await markNotificationsAsViewed();
            setUnviewedCount(0);
            localStorage.setItem('unviewedCount', unviewedCount.toString());
        } catch (error) {
            console.error("Erro ao marcar notificação como visualizada:", error);
        }
    };

    markAsViewed();

    return (
        <NotificationContext.Provider value={{ notifications, loading, unviewedCount, markAsViewed, setUnviewedCount }}>
            {children}
        </NotificationContext.Provider>
    );
};

const NotificationPageContent: React.FC = () => {
    const context = useContext(NotificationContext);

    if (!context) {
        throw new Error("NotificationContext must be used within a NotificationProvider");
    }

    const { notifications, loading, markAsViewed } = context;

    const handleRefresh = async () => {
        await getUnviewedNotifications();
    };

    return (
        <>
            <StaticMenuComponent />
            <HeaderComponent />
            <main>
                <div className='Content1'>
                    <PullToRefresh onRefresh={handleRefresh}>
                        {loading ? (
                            <p className="text-center pt-4">Carregando notificações...</p>
                        ) : (
                            notifications.length === 0 ? (
                                <p className="text-center pt-4">Nenhuma notificação.</p>
                            ) : (
                                notifications.map((notification) => (
                                    <Col xs={12} md={12} className="text-left py-1 px-3 d-flex zebra" key={notification._id}>
                                        <div className="d-flex align-items-center cemPor">
                                            <Link to={`/profile/${notification.usuarioOrigem._id}`}>
                                                <LazyLoadImage
                                                    effect="blur"
                                                    src={notification.srcProfile}
                                                    className="thumb-1"
                                                    alt={notification.usuarioOrigem.nome}
                                                />
                                            </Link>
                                            <div className="pr-0 ps-2 pt-1 d-flex cemPor">
                                                <div style={{ width: "100%" }}>
                                                    <Link to={`/profile/${notification.usuarioOrigem._id}`} className="text-black text-decoration-none">
                                                        <h6 className="arial fw-bold mb-0">{notification.usuarioOrigem.nome}</h6>
                                                    </Link>
                                                    <p className="mb-0">
                                                        {notification.tipo === 'curtida' ? 'Curtiu sua postagem' : notification.tipo === 'comentario' ? 'Comentou sua postagem' : 'Começou a te seguir'}
                                                    </p>
                                                </div>
                                                {notification.tipo !== 'novo_seguidor' && (
                                                    <Link to={notification.linkPostagem}>
                                                        <LazyLoadImage
                                                            effect="blur"
                                                            src={notification.srcImagem}
                                                            className="thumb-3"
                                                            alt="Imagem da postagem"
                                                        />
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            )
                        )}
                    </PullToRefresh>
                </div>
            </main>
            <FooterComponent />
        </>
    );
};

const NotificationPage: React.FC = () => (
    <NotificationProvider>
        <NotificationPageContent />
    </NotificationProvider>
);

export default NotificationPage;