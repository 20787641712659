import React, { createContext, useState, useEffect, useContext, ReactNode } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "./../logo-3.png";
import { RiMenuFill } from "react-icons/ri";
import { VscBell, VscBellDot } from "react-icons/vsc";
import { getUnviewedNotifications } from '../services/notificationService';
import Cookies from "js-cookie";
import { TbArrowBadgeRightFilled } from "react-icons/tb";

interface Notification {
    _id: string;
    tipo: string;
    usuarioDestino: string;
    usuarioOrigem: string;
    idPost?: string;
    idComentario?: string;
    dataNotificacao: string;
    visualizada: boolean;
    mensagem: string;
}

interface NotificationContextType {
    notifications: Notification[];
    loading: boolean;
    unviewedCount: number;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [loading, setLoading] = useState(true);
    const [unviewedCount, setUnviewedCount] = useState(0);

    useEffect(() => {
        fetchUnviewedNotifications();
        const interval = setInterval(fetchUnviewedNotifications, 60000);
        return () => clearInterval(interval);
    }, []);

    const fetchUnviewedNotifications = async () => {
        setLoading(true);
        try {
            const unviewedNotifications = await getUnviewedNotifications();
            setNotifications(unviewedNotifications.notifications);
            setUnviewedCount(unviewedNotifications.unviewedCount);
            localStorage.setItem('unviewedCount', unviewedNotifications.unviewedCount.toString());
            Cookies.set('unviewedCount', unviewedNotifications.unviewedCount.toString());

        } catch (error) {
            console.error("Erro ao buscar notificações:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <NotificationContext.Provider value={{ notifications, loading, unviewedCount }}>
            {children}
        </NotificationContext.Provider>
    );
};

const HeaderComponent: React.FC = () => {
    const [show, setShow] = useState(false);
    const { unviewedCount } = useContext(NotificationContext) || { unviewedCount: 0 };
    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleLogout = () => navigate("/logout");

    const notificationCount = localStorage.getItem('unviewedCount') || '0';

    return (
        <Navbar expand="lg" className="MenuTopo">
            <Container className="d-block">
                <img src={logo} width={138} height={44} alt="Bt Mania" />

                <Button variant="outline-primary" onClick={handleShow} className="w-auto bt-topo-menu p-0 mt-0 float-end">
                    <RiMenuFill />
                </Button>

                <Link to="/notifications">
                    {Number(notificationCount) > 0 ? (
                        <VscBellDot className="icoNotificacaoAlert" />
                    ) : (
                        <VscBell className="icoNotificacao" />
                    )}
                </Link>

                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            <img src={logo} width={150} height={50} alt="Bt Mania" />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <ul className="ps-0">
                            <li>
                                <Link to="/home">
                                    <Button variant="link" className="py-2 text-start bt-menu">
                                    <TbArrowBadgeRightFilled /> Página inicial
                                    </Button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profiles">
                                    <Button variant="link" className="py-2 text-start bt-menu">
                                    <TbArrowBadgeRightFilled /> Encontrar pessoas
                                    </Button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/professores">
                                    <Button variant="link" className="py-2 text-start bt-menu">
                                    <TbArrowBadgeRightFilled /> Professores
                                    </Button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/chat">
                                    <Button variant="link" className="py-2 text-start bt-menu">
                                    <TbArrowBadgeRightFilled /> Mensagens
                                    </Button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/account/profile">
                                    <Button variant="link" className="py-2 text-start bt-menu">
                                    <TbArrowBadgeRightFilled /> Meu perfil
                                    </Button>
                                </Link>
                            </li>
                            <li>
                                <Button variant="link" className="py-2 text-start bt-menu" onClick={handleLogout}>
                                <TbArrowBadgeRightFilled /> Sair
                                </Button>
                            </li>
                        </ul>
                    </Offcanvas.Body>
                </Offcanvas>
            </Container>
        </Navbar>
    );
};

const HeaderWithNotifications: React.FC = () => (
    <NotificationProvider>
        <HeaderComponent />
    </NotificationProvider>
);

export default HeaderWithNotifications;