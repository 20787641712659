import { useState, FormEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../logo-btmania-a-rede-social-do-beach-tennis.png";
import { Button, FloatingLabel, Form, Alert } from "react-bootstrap";
import { createUser } from "../../services/userService";
import { useAuth } from "../../context/AuthContext";
import { Helmet } from "react-helmet";

interface FormData {
  nome: string;
  genero: string;
  email: string;
  senha: string;
}

function RegisterPage() {
  const navigate = useNavigate();
  const [retornoAlerta, setRetornoAlerta] = useState("");
  const [botaoDesabilitado1, setbotaoDesabilitado1] = useState(false);
  const { setEmailConfirmed, setIsLoggedIn, setToken } = useAuth();

  const [formData, setFormData] = useState<FormData>({
    nome: "",
    genero: "",
    email: "",
    senha: ""
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setbotaoDesabilitado1(true);
      const userData = await createUser(formData);
      const token = userData.token;
      setToken(token);
      setIsLoggedIn(true);
      setEmailConfirmed(false);
      navigate("/account/profile/edit");
    } catch (error: any) {
      setbotaoDesabilitado1(false);
      setRetornoAlerta(error.message);
    }
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Cadastro - BT Mania",
    "description": "BT Mania é a rede social ideal para praticantes de Beach Tennis. Conecte-se com outros jogadores, compartilhe suas experiências e fique por dentro das novidades do mundo do Beach Tennis. Faça seu cadastro agora!",
    "url": "https://btmania.com.br/register",
    "publisher": {
      "@type": "Organization",
      "name": "BT Mania",
      "logo": {
        "@type": "ImageObject",
        "url": "https://btmania.com.br/assets/images/logo-200-200.png"
      }
    }
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
        <meta name="description" content="BT Mania é a rede social ideal para praticantes de Beach Tennis. Conecte-se com outros jogadores, compartilhe suas experiências e fique por dentro das novidades do mundo do Beach Tennis. Faça seu cadastro agora!" /> 
        <meta name="keywords" content="beach tennis, BT Mania, rede social beach tennis, jogadores de beach tennis, comunidade beach tennis, torneios beach tennis, dicas de beach tennis, cadastro, faça seu cadastro" /> 
        <meta name="author" content="BT Mania" />
        <meta name="theme-color" content="#ffffff" /> 
        <meta name="twitter:card" content="summary_large_image" /> 
        <meta property="og:type" content="website" /> 
        <meta property="og:title" content="BT Mania - Rede Social para Praticantes de Beach Tennis. Faça seu cadastro agora!" /> 
        <meta property="og:description" content="BT Mania é a rede social ideal para praticantes de Beach Tennis. Conecte-se com outros jogadores, compartilhe suas experiências e fique por dentro das novidades do mundo do Beach Tennis. Faça seu cadastro agora!" /> 
        <meta property="og:image" content="https://btmania.com.br/assets/images/logo-200-200.png" /> 
        <meta property="og:url" content="https://btmania.com.br" /> 
        <meta name="twitter:site" content="@bt_mania" /> 
        <meta name="twitter:title" content="BT Mania - Rede Social para Praticantes de Beach Tennis. Faça seu cadastro agora!" /> 
        <meta name="twitter:description" content="BT Mania é a rede social ideal para praticantes de Beach Tennis. Conecte-se com outros jogadores, compartilhe suas experiências e fique por dentro das novidades do mundo do Beach Tennis. Faça seu cadastro agora!" /> 
        <meta name="twitter:image" content="https://btmania.com.br/assets/images/logo-200-200.webp" /> 
        <meta name="twitter:url" content="https://btmania.com.br" /> 
        <meta name="twitter:creator" content="@bt_mania" /> 
        <meta name="twitter:domain" content="https://btmania.com.br" /> 
        <title>BT Mania - Rede Social para Praticantes de Beach Tennis. Faça seu cadastro agora!</title>
      </Helmet>
      <main className="centralize">
        <form className="w-300" onSubmit={handleSubmit}>
          <img src={logo} alt="BT Mania" className="logo" />
          <h5 className="cemPor textCenter primary fw-bold mb-3 mt-3">
            Faça seu cadastro
          </h5>
          <FloatingLabel
            controlId="nome"
            label="Seu primeiro nome"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Seu primeiro nome"
              name="nome"
              value={formData.nome}
              onChange={(e) => setFormData({ ...formData, nome: e.target.value })}
              className="bg-light"
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="genero"
            label="Gênero"
            className="mb-3"
          >
            <Form.Select
              aria-label="Gênero"
              name="genero"
              value={formData.genero}
              onChange={(e) => setFormData({ ...formData, genero: e.target.value })}
            >
              <option value="">Selecione</option>
              <option value="Feminino">Feminino</option>
              <option value="Masculino">Masculino</option>
              <option value="Transgênero">Transgênero</option>
              <option value="Prefiro não informar">Prefiro não informar</option>
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel
            controlId="email"
            label="Seu e-mail"
            className="mb-3"
          >
            <Form.Control
              type="email"
              placeholder="Seu e-mail"
              name="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="bg-light"
            />
          </FloatingLabel>
          <FloatingLabel controlId="senha" label="Crie uma senha">
            <Form.Control
              type="password"
              placeholder="Crie uma senha"
              name="senha"
              value={formData.senha}
              onChange={(e) => setFormData({ ...formData, senha: e.target.value })}
              className="bg-light"
            />
          </FloatingLabel>
          <Button variant="primary" size="lg" type="submit" className="mt-4 rounded py-3" disabled={botaoDesabilitado1}>
            <div className="loader"></div>
            <span className="text-button">CADASTRAR</span>
          </Button>

          {retornoAlerta && <Alert key="warning" variant="warning" className="mt-3 text-center px-4">{retornoAlerta}</Alert>}

          <p className="text-center mt-3">
            <Link to="/esqueci-minha-senha">
              <Button variant="outline" className="link primary">
                Esqueci minha senha
              </Button>
            </Link>
          </p>
          <hr className="divider mt-3" />
          <Link to="/">
            <Button
              variant="success"
              size="lg"
              className="mt-4 rounded py-3"
            >
              IR PARA LOGIN
            </Button>
          </Link>
        </form>
      </main>
    </>
  );
}

export default RegisterPage;