import { configureHeaders } from '../utils/Utils';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

let apiUrl: string = process.env.REACT_APP_HOST_API as string;

interface UserData {
  nome: string;
  email: string;
  genero: string;
  senha: string;
}

export async function getIdUserLogged(){
  const token = Cookies.get("TokenBTMania");
  
  if (token) {
    const decoded = jwtDecode(token);
    const jsonObject = JSON.parse(JSON.stringify(decoded));
    const userId = jsonObject.id;
    return userId;
  }
}

export async function login(email: string, senha: string) {
  try {
    const response = await fetch(`${apiUrl}/user/login`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, senha }),
    });

    if (!response.ok) {
      throw new Error('Erro ao fazer login');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Erro ao fazer login:", error);
    throw error;
  }
}

export async function createUser(userData: UserData): Promise<any> {
  try {
    const response = await fetch(`${apiUrl}/user`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      throw new Error('Erro ao criar usuário');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Erro ao criar usuário:", error);
    throw error;
  }
}

export async function dataProfile(idUser: any) {
  try {
    const token = Cookies.get("TokenBTMania");

    if (token) {
      const response = await fetch(`${apiUrl}/user/${idUser}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar usuário');
      }

      const data = await response.json();
      return data;
    } else {
      console.error("Token não encontrado no armazenamento local.");
    }
  } catch (error) {
    console.error("Erro ao buscar usuário:", error);
    throw error;
  }
}

export async function updatePasswordByCode(email: string, codigo: string, codIdUser: string, senha: string) {
  try {
      const response = await fetch(`${apiUrl}/user/password-by-code`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, codigo, codIdUser, senha }),
      });

      if (!response.ok) {
        throw new Error('Erro ao atualizar senha');
      }

      const data = await response.json();
      return data;
    
  } catch (error) {
    console.error('Erro ao atualizar senha:', error);
    throw error;
  }
}

export async function updateProfile(nome: string, bio: string, cidade: string, professor: string) {
  try {
    const token = Cookies.get("TokenBTMania");

    if (token) {
      const decoded = jwtDecode(token);
      const jsonObject = JSON.parse(JSON.stringify(decoded));
      const idUser = jsonObject.id;

      const response = await fetch(`${apiUrl}/user/${idUser}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ nome, bio, cidade, professor }),
      });

      if (!response.ok) {
        throw new Error('Erro ao atualizar perfil');
      }

      const data = await response.json();
      return data;
    } else {
      console.error("Token não encontrado no armazenamento local.");
    }
  } catch (error) {
    console.error('Erro ao atualizar perfil:', error);
    throw error;
  }
}

export async function verificaCadastro(codigoVerificacao: string): Promise<any> {
  try {
    const headers: Record<string, string> = {};
    configureHeaders(headers);

    const token = Cookies.get("TokenBTMania");

    if (token) {
      const decoded = jwtDecode(token);
      const jsonObject = JSON.parse(JSON.stringify(decoded));
      const idUser = jsonObject.id;

      const response = await fetch(`${apiUrl}/user/valida-cadastro`, {
        method: 'PATCH',
        headers,
        body: JSON.stringify({ idUser, codigoVerificacao }),
      });

      if (!response.ok) {
        throw new Error('Erro ao validar cadastro');
      }

      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Erro ao validar código:", error);
    throw error;
  }
}

export async function verificaCodigo(email: string, codigoVerificacao: string): Promise<any> {
  try {
    const headers: Record<string, string> = {};
    configureHeaders(headers);

    const response = await fetch(`${apiUrl}/user/valida-codigo`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({ email: email.trim(), codigoVerificacao }),
    });

    if (!response.ok) {
      throw new Error('Erro ao validar código');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Erro ao validar código:", error);
    throw error;
  }
}

export async function SendUserProfileImage(base64Image: string): Promise<any> {
  try {
    const token = Cookies.get("TokenBTMania");

    if (token) {
      const decoded = jwtDecode(token);
      const jsonObject = JSON.parse(JSON.stringify(decoded));
      const idUser = jsonObject.id;

      const headers: Record<string, string> = {};
      configureHeaders(headers);

      const response = await fetch(`${apiUrl}/user/photo/${idUser}`, {
        method: 'PATCH',
        headers,
      });

      if (!response.ok) {
        throw new Error('Erro ao enviar imagem de perfil');
      }

      const byteCharacters = atob(base64Image.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });

      const data = await response.json();

      const uploadResponse = await fetch(data.uploadUrl, {
        method: 'PUT',
        body: blob,
        headers: {
          'Content-Type': 'image/jpeg',
        },
      });

      return data;
    } else {
      console.error("Token não encontrado no armazenamento local.");
    }
  } catch (error) {
    console.error('Erro ao enviar imagem de perfil:', error);
    throw error;
  }
}