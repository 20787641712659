import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Figure, Form, InputGroup } from "react-bootstrap";
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { AiFillLike } from "react-icons/ai";
import { FaCircleUser } from "react-icons/fa6";
import HeaderComponent from "../../components/Header3Component";
import FooterComponent from "../../components/FooterComponent";
import { getIdToken } from "../../utils/Utils";
import { getOne } from "../../services/postService";
import { sendLike, getLike, totalLikes } from "../../services/postLikeService";
import { listComments, Create, deletePostComment } from "../../services/postCommentService";
import { formatDate2 } from "../../utils/Utils";
import { PostData, CommentData } from "../../types/Types";
import ModalPerfisComponent from "../../components/ModalPerfisComponent";

const PostPage = ({ ...props }) => {
  const { id } = useParams<{ id: string}>();
  const [idUserToken, setIdUserToken] = useState(getIdToken());
  const [post, setPost] = useState<PostData | null>(null);
  const [commentsList, setCommentsList] = useState<CommentData[]>([]);
  const [campoComentario, setCampoComentario] = useState('');
  const [totalCurtiu, setTotalCurtiu] = useState(0);
  const [totalTextoCurtiu, setTotalTextoCurtiu] = useState('')
  const [curtiu, setCurtiu] = useState(0);
  const [showCurtidas, setShowCurtidas] = useState(false);
  const handleCloseCurtidas = () => setShowCurtidas(false);
  const handleShowCurtidas = () => setShowCurtidas(true);
  const [idPublicacao, setIdPublicacao] = useState('');
  
  useEffect(() => {
    const fetchPostData = async () => {
      try {
        if (id) {
          
          const postData = await getOne(id);
          setPost(postData);
          fetchComments(id);
          fetchLikes(id);
          checkUserLike(id);
          setIdPublicacao(id);
        }
      } catch (error) {
        console.error("Erro ao buscar dados do post:", error);
      }
    };
    fetchPostData();
  }, [id]);
  
  const fetchComments = async (postId: string) => {
    try {
      const comments = await listComments(postId, 0);
      setCommentsList(comments);
    } catch (error) {
      console.error("Erro ao listar comentários:", error);
    }
  };
  
  const fetchLikes = async (postId: string) => {
    try {
      
      const likes = await totalLikes(postId);
      
      if(likes === 1){
        
        setTotalTextoCurtiu('CURTIDA');
      }else{
        
        setTotalTextoCurtiu('CURTIDAS');
      }  
      
      setTotalCurtiu(likes);
    } catch (error) {
      console.error("Erro ao listar curtidas:", error);
    }
  };
  
  const checkUserLike = async (postId: string) => {
    try {
      const userLike = await getLike(postId);
      setCurtiu(userLike.like ? 1 : 0);
    } catch (error) {
      console.error("Erro ao verificar curtida:", error);
    }
  };
  
  const addLike = async () => {
    try {
      if (id) {
        
        await sendLike(id);
        setCurtiu(curtiu === 1 ? 0 : 1);
        
        let novoTotalCurtiu = (totalCurtiu + (curtiu === 1 ? -1 : 1));
        
        setTotalCurtiu(novoTotalCurtiu);
        
        if(novoTotalCurtiu === 1){
        
          setTotalTextoCurtiu('CURTIDA');
        }else{
          
          setTotalTextoCurtiu('CURTIDAS');
        }
      }
    } catch (error) {
      console.error("Erro ao curtir a publicação:", error);
    }
  };
  
  const addComment = async () => {
    try {
      if (id) {
        await Create(id, campoComentario, 1);
        setCampoComentario('');
        fetchComments(id);
      }
    } catch (error) {
      console.error("Erro ao adicionar comentário:", error);
    }
  };
  
  const removeComment = async (commentId: string) => {
    try {
      await deletePostComment(commentId);
      setCommentsList(commentsList.filter(comment => comment._id !== commentId));
    } catch (error) {
      console.error("Erro ao remover comentário:", error);
    }
  };
  
  return (
    <>
    <HeaderComponent />
    <main>
    <div className="contentPage">
    <div className="Content1">
    {post && (
      <div className="d-grid" style={{ width: "90%", margin: "0 auto", paddingTop: "2%", paddingBottom: "10%" }}>
      {post.srcImagem && (
        <Figure className="cemPor py-3 pt-0 pb-0 mb-2 figure">
        <Figure.Image
        className="cemPor mb-0"
        src={post.srcImagem}
        style={{
          borderRight: "3px solid white",
          borderBottom: "3px solid white",
          height: "320px",
        }}
        />
        </Figure>
      )}
      <div className="d-flex">
      <div className="cemPor">
      <Button
      variant={curtiu === 0 ? `outline-success` : `success`}
      size="lg"
      className="btn rounded p-0 float-start blocoIconeAcao float-start"
      onClick={addLike}
      >
      <AiFillLike className="fs-1-5em" />
      </Button>
      
      <Button
      variant="link"
      size="lg"
      className="btn rounded p-0 text-black text-start ps-2 float-start w-auto"
      onClick={handleShowCurtidas}
      >
      {totalCurtiu} {totalTextoCurtiu}
      </Button>
      <h6 className="arial w-auto float-end pe-1 italico"><small>{formatDate2(post.dataPublicacao)}</small></h6>
      </div>
      </div>
      <div className="float-start mt-2">
      {post.texto && (
        <div className="d-flex">
        <div className="px-2">
        <h6 className="py-0 ps-1 float-start text-start arial" style={{ marginTop: "7px" }}>
        <span>{post.texto}</span>
        </h6>
        </div>
        </div>
      )}
      </div>
      <InputGroup className="mt-1 mb-3 input-group-lg p-2" style={{ height: "39px" }}>
      <Form.Control
      aria-label="Adicionar comentário..."
      placeholder="Adicionar comentário..."
      className="br-0 fs-1em"
      value={campoComentario}
      onChange={(e) => setCampoComentario(e.target.value)}
      />
      <InputGroup.Text className="bg-white">
      <PiPaperPlaneRightFill className="bl-0" onClick={addComment} />
      </InputGroup.Text>
      </InputGroup>
      <div className="px-3 pt-3" id="caixaScroll" style={{ overflowY: 'scroll' }}>
      {commentsList.length === 0 && (
        <p className="text-center">Nenhum comentário.</p>
      )}
      {commentsList.length > 0 && commentsList.map((comment) => (
        <div key={comment._id}>
        <div className="d-flex">
        <div className="">
        <FaCircleUser className="fs-2em opacity-0-1" />
        </div>
        <div className="px-2 pb-3">
        <p className="text-start d-grid mb-0 arial">
        <b>{comment.user[0].nome}</b>
        <span>{comment.texto}</span>
        </p>
        </div>
        </div>
        </div>
      ))}
      </div>
      </div>
    )}
    </div>
    </div>  
    </main>
    <FooterComponent />
    
    <ModalPerfisComponent show={showCurtidas} onHide={handleCloseCurtidas} titulo="Lista de Curtidas" idPublicacao={idPublicacao} />
    </>
  );
};

export default PostPage;