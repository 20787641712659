import React, { useState, ReactNode, useEffect } from 'react';
import Cookies from 'js-cookie';
import AuthContext from './AuthContext';

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setTokenState] = useState<string | null>(null);
  const [emailConfirmed, setEmailConfirmedState] = useState<boolean | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const storedToken = Cookies.get('TokenBTMania');
    const storedEmailConfirmed = Cookies.get('EmailConfirmed') === 'true';   

    if (storedToken) {
      setTokenState(storedToken);
      setIsLoggedIn(true);
      setEmailConfirmedState(storedEmailConfirmed);
    }
  }, []);

  const setToken = (newToken: string | null) => {
    setTokenState(newToken);

    if (newToken) {
      Cookies.set('TokenBTMania', newToken.toString());
      localStorage.setItem('TokenBTMania', newToken.toString());
      setIsLoggedIn(true);
    }
  };

  const setEmailConfirmed = (confirmed: boolean | null) => {
    setEmailConfirmedState(confirmed);
    if (confirmed !== null) {
      Cookies.set('EmailConfirmed', confirmed.toString());
    } else {
      Cookies.remove('EmailConfirmed');
    }
  };

  return (
    <AuthContext.Provider value={{ token, setToken, emailConfirmed, setEmailConfirmed, isLoggedIn, setIsLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;