import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../logo-btmania-a-rede-social-do-beach-tennis.png";
import { Button, FloatingLabel, Form, Alert } from "react-bootstrap";

function NotfoundPage() {

  return (
    <main className="centralize">
        <img src={logo} alt="BT Mania" className="logo" />
        <h5 className="cemPor textCenter primary fw-bold mb-3 mt-3">
          Página não encontrada.
        </h5>
        <hr className="divider mt-3" />
        <Link to="/">
          <Button
            variant="outline-success"
            size="lg"
            className="mt-4 rounded py-3"
          >
            VOLTAR PARA PÁGINA INICIAL
          </Button>
        </Link>
    </main>
  );
}

export default NotfoundPage;