import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';

import "./custom.scss";
import "bootstrap/scss/bootstrap.scss";

import { AuthProvider } from "./context/AuthContext";

import PasswordPage from "./pages/password/PasswordPage";
import LoginPage from "./pages/login/LoginPage";
import HomePage from "./pages/home/HomePage";
import PostPage from "./pages/post/PostPage";
import NewpostPage from "./pages/post/NewPage";
import RegisterPage from "./pages/register/RegisterPage";
import ChatPage from "./pages/chat/ChatPage";
import MessagePage from "./pages/chat/MessagePage";
import ProfilesPage from "./pages/profiles/ProfilesPage";
import ProfessoresPage from './pages/professores/ProfessoresPage';
import ProfilePage from "./pages/profile/ProfilePage";
import AccountProfilePage from "./pages/account/ProfilePage";
import AccountProfileEditPage from "./pages/account/ProfileEditPage";
import VerifyPage from "./pages/account/VerifyPage";
import LogoutPage from "./pages/account/LogoutPage";
import NotfoundPage from "./pages/notfound/NotFoundPage";
import NotificationPage from "./pages/notification/NotificationPage";
import Politicadeprivacidade from './pages/politicadeprivacidade/Politicadeprivacidade';
import BlogPostPage from './pages/blog/Post';

import { ProtectedRoute, UnprotectedRoute } from "./ProtectedRoute/ProtectedRoute";

import * as serviceWorker from './serviceWorker';

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return windowDimensions;
};

declare global {
  interface Window {
    dataLayer: any[];
  }
}

window.dataLayer = window.dataLayer || [];

function App() {
  
  const { width } = useWindowDimensions();
  
  /* useEffect(() => {
    if (window.location.hostname === 'btmania.com.br') {
      window.location.replace(`https://www.btmania.com.br${window.location.pathname}${window.location.search}`);
    }
  }, []); */

  if (!isMobile) {
    
    const rootFullWidth = width;
    const rootWidth = width * 0.30;
    const marginLeft = width * 0.35;
    
    const style = document.createElement("style");
    
    style.innerHTML = `
      body{
        background: white !important;
      }
      #root{
        margin-left: 0 !important;
        padding-right: 0;
      }
      #root, nav, .MenuTopo2, .Footer, main {
          width: 100% !important;
          max-width: 100% !important;
          min-width: 100% !important;
      }
      .contentHome, .contentPage {
        display: block;
        padding: 0 50px !important;
        margin-left: 20%;
        width: 40%;
      }
      .Content1{
        padding-top: 10px !important;
      }
      .50-per-desktop{
        width: 50% !important;
        display: block !important;
      }
      .MenuTopo{
        display: none !important;
      }
      .mt-55{
        margin-top: 0 !important;
      }
      .Menu{
        display: none !important;
      }
      main{
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
      }
      .menuDesktop{
        display: block !important;
      }
      .bottomInputGroup{
        bottom: 0px !important;
        width: 40% !important;
      }
      .offcanvas-end{
        width: 40% !important;
      }
      .legendaPostDetalhe{
        width: 36% !important;
      }
    `;
    document.head.appendChild(style);
  }
  
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<UnprotectedRoute element={<LoginPage />} />} />
          <Route path="/politica-de-privacidade" element={<UnprotectedRoute element={<Politicadeprivacidade />} />} />
          <Route path="/esqueci-minha-senha" element={<UnprotectedRoute element={<PasswordPage />} />} />
          <Route path="/register" element={<UnprotectedRoute element={<RegisterPage />} />} />
          <Route path="/home" element={<ProtectedRoute element={<HomePage />} />} />
          <Route path="/post/:id" element={<ProtectedRoute element={<PostPage />} />} />
          <Route path="/post/new" element={<ProtectedRoute element={<NewpostPage />} />} />
          <Route path="/chat" element={<ProtectedRoute element={<ChatPage />} />} />
          <Route path="/chat/message/:id" element={<ProtectedRoute element={<MessagePage />} />} />
          <Route path="/profiles" element={<ProtectedRoute element={<ProfilesPage />} />} />
          <Route path="/professores" element={<ProtectedRoute element={<ProfessoresPage />} />} />
          <Route path="/profile/:id" element={<ProtectedRoute element={<ProfilePage />} />} />
          <Route path="/account/profile" element={<ProtectedRoute element={<AccountProfilePage />} />} />
          <Route path="/account/profile/edit" element={<ProtectedRoute element={<AccountProfileEditPage />} />} />
          <Route path="/account/profile/verify" element={<ProtectedRoute element={<VerifyPage />} />} />
          <Route path="/notifications" element={<ProtectedRoute element={<NotificationPage />} />} />
          <Route path="/logout" element={<ProtectedRoute element={<LogoutPage />} />} />
          <Route path="/blog/:slug" element={<BlogPostPage />} />
          <Route path="/404" element={<NotfoundPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;

serviceWorker.register();