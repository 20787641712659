import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderComponent from "../../components/Header2Component";
import FooterComponent from "../../components/FooterComponent";
import ModalPerfisComponent from "../../components/ModalPerfisComponent";
import ModalSeguidoresComponent from "../../components/ModalSeguidoresComponent";
import ModalSeguindoComponent from "../../components/ModalSeguindoComponent";
import { Col, Form, Offcanvas, InputGroup, Modal, Figure, Button, Tab, Tabs, Card, Image } from "react-bootstrap";
import { dataProfile } from '../../services/userService';
import { list, listTxt, deletePost, updatePostPublic } from '../../services/postService';
import { Create, listComments, deletePostComment, totalComments } from '../../services/postCommentService';
import { sendLike, getLike, totalLikes } from '../../services/postLikeService';
import { totalFollower, totalFollowing } from "../../services/followerService";
import { UserData, PostData, CommentData } from '../../types/Types';
import { formatDate1, formatDate2, getIdToken } from '../../utils/Utils';
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { IoChevronBackOutline } from "react-icons/io5";
import { AiFillLike } from "react-icons/ai";
import { TbMoodEmpty } from "react-icons/tb";
import { FaRegEye, FaRegEyeSlash, FaTrashAlt } from "react-icons/fa";
import { FaCircleUser } from "react-icons/fa6";
import { getIdUserLogged } from "../../services/userService";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import StaticMenuComponent from "../../components/StaticMenuComponent";

function AccountProfilePage({ ...props }) {
  const navigate = useNavigate();
  const [idUserToken, setIdUserToken] = useState(getIdToken());
  const [offsetGeral, setOffsetGeral] = useState(0);
  const [offsetList, setOffsetList] = useState(0);
  const [totFollower, setTotFollower] = useState(0);
  const [totFollowing, setTotFollowing] = useState(0);
  const [commentsTotalSel, setCommentsTotalSel] = useState(0);
  const [totalCurtiu, setTotalCurtiu] = useState(0);
  const [curtiu, setCurtiu] = useState(0);
  const [newComment, setNewComment] = useState(true);
  const [commentsTotalLoad, setCommentsTotalLoad] = useState(true);
  const [campoComentario, setCampoComentario] = useState('');
  const [imagemAtual, setImagemAtual] = useState('');
  const [totalPosts, setTotalPosts] = useState(' -- ');
  const [idPublicacao, setidPublicacao] = useState('');
  const [textoPulicacao, setTextoPulicacao] = useState('');
  const [dataPublicacaoPost, setDataPulicacao] = useState('');
  const [postPublico, setPostPublico] = useState<number>(0);
  const [botaoDesabilitadoCarregarMais, setBotaoDesabilitadoCarregarMais] = useState(false);
  const [botaoCarregarMaisComments, setBotaoCarregarMaisComments] = useState(false);
  const [key, setKey] = useState<string | null>(null);
  const [dataComentarioSel, setDataComentarioSel] = useState<any | null>(null);
  const [posts, setPosts] = useState<PostData[]>([]);
  const [postsTxt, setPostsTxt] = useState<PostData[]>([]);
  const [commentsList, setCommentsList] = useState<CommentData[]>([]);
  const [buttonTextCurtidas, setButtonTextCurtidas] = useState('');
  const [show2, setShow2] = useState(false);
  const [showConfirmacaoExclusao, setShowConfirmacaoExclusao] = useState(false);
  const [showConfirmacaoExclusaoComentario, setShowConfirmacaoExclusaoComentario] = useState(false);
  const [textoComentarioExclusao, setTextoComentarioExclusao] = useState('');
  const handleCloseConfirmacaoExclusao = () => setShowConfirmacaoExclusao(false);
  const handleCloseConfirmacaoExclusaoComentario = () => setShowConfirmacaoExclusaoComentario(false);
  const [show, setShow] = useState(false);
  const [showSeguidores, setShowSeguidores] = useState(false);
  const [showSeguindo, setShowSeguindo] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseSeguidores = () => setShowSeguidores(false);
  const handleCloseSeguindo = () => setShowSeguindo(false);
  const [totalSeguidores, setTotalSeguidores] = useState(0);
  const handleClose2 = () => {
    setShow2(false);
    setOffsetGeral(0);
  };
  const calculaSeguidor = (data:number) => {
    setTotalSeguidores(data);
  };
  const [dadosUsuario, setDadosUsuario] = useState<UserData | null>(null);
  const [imgPerfil, setImgPerfil] = useState(String);
  const [profile, setProfile] = useState("");
  const [nome, setNome] = useState("");
  const [bio, setBio] = useState("");
  const [idUsuario, setIdUsuario] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleShowSeguidores = () => {
    setShowSeguidores(true);
  };
  const handleShowSeguindo = () => {
    setShowSeguindo(true);
  };
  const getCurtidasClick = (clique:number) => {
    let textoCurtidas = '';
    if (totalCurtiu >= 1 && clique === 1) {
      textoCurtidas = (totalCurtiu + 1)+" CURTIDAS";
    } 
    if (totalCurtiu === 0 && clique === 1) {
      textoCurtidas = "1 CURTIDA";
    } 
    if ((totalCurtiu === 1 || totalCurtiu === 0) && clique === 0) {
      textoCurtidas = "NENHUMA CURTIDA";
    }
    if (totalCurtiu === 2 && clique === 0) {
      textoCurtidas = "1 CURTIDA";
    }
    if (totalCurtiu > 2 && clique === 0) {
      textoCurtidas = (totalCurtiu - 1)+" CURTIDAS";
    }
    setButtonTextCurtidas(textoCurtidas);
  }
  const getCurtidasTexto = (curtidas:any) => {
    let textoCurtidas = '';
    if (curtidas >= 2) {
      textoCurtidas = "2 CURTIDAS";
    } 
    if (curtidas === 1) {
      textoCurtidas = "1 CURTIDA";
    } 
    if (curtidas === '' || curtidas === null || curtidas === 0) {
      textoCurtidas = "NENHUMA CURTIDA";
    }
    setButtonTextCurtidas(textoCurtidas);
  }
  const handleShow2 = (post: any) => {
    getCurtidasTexto('');
    setTotalCurtiu(0);
    setOffsetGeral(0);
    setCampoComentario('');
    setPostPublico(post.publico)
    setImagemAtual(post.srcImagem);
    setTextoPulicacao(post.texto);
    setDataPulicacao(formatDate2(post.dataPublicacao));
    setShow2(true);
    setidPublicacao(post._id);
    comments(post._id, 1);
    setCommentsTotalLoad(true);
    likeVerify(post._id)
    totalLikesVerify(post._id);
  }
  const totalLikesVerify = async (idPost:string) => {
    try{
      const verifyLikeTotal = await totalLikes(idPost);
      if(verifyLikeTotal){
        setTotalCurtiu(verifyLikeTotal);
        getCurtidasTexto(verifyLikeTotal);
      }
    } catch (error) {
      console.error("Erro ao listar comentários");
    } 
  }
  const likeVerify = async (idPost:string) => {
    try{
      const verifyLike = await getLike(idPost);
      if(verifyLike){
        if(verifyLike.like === null){
          setCurtiu(0);  
        }else{
          setCurtiu(1)
        }
      }
    } catch (error) {
      console.error("Erro ao listar comentários");
    } 
  }
  const comments = async (idPost:String, resetLista:Number) => {
    try {
      if (commentsTotalLoad) {
        const commentsTotal = await totalComments(idPost);
        setCommentsTotalSel(commentsTotal);
      }
      let newCommentsList:any = [];
      if(resetLista === 1){
        newCommentsList = await listComments(idPost, 0);
        setOffsetGeral(1);
      }
      if(resetLista !== 1){
        newCommentsList = await listComments(idPost, offsetGeral);
        setOffsetGeral(offsetGeral + 1);
      }
      if (resetLista === 0) {
        setCommentsList(prev => [...prev, ...newCommentsList]);
        if (newCommentsList.length < 10) {
          setBotaoCarregarMaisComments(false);
        }else{
          setBotaoCarregarMaisComments(true);
        }
      } else {
        setCommentsList(newCommentsList);
        if (newCommentsList.length < 10) {
          setBotaoCarregarMaisComments(false);
        }else{
          setBotaoCarregarMaisComments(true);
        }
      }
    } catch (error) {
      console.error("Erro ao listar comentários");
    }   
  }
  const paginationComment = () => {
    setBotaoDesabilitadoCarregarMais(true);
    comments(idPublicacao, 0);
    setBotaoDesabilitadoCarregarMais(false);
  };
  const addLike = async () => {
    try {
      await sendLike(idPublicacao);
      getCurtidasClick(curtiu === 1 ? 0 : 1);
      setCurtiu(curtiu === 1 ? 0 : 1);
    } catch (error) {
      console.error("Erro ao cadastrar este comentário");
    }
  }
  const addComment = async () => {
    try {
      const returnList = 1;
      const newComment = await Create(idPublicacao, campoComentario, returnList);
      if(newComment){
        setCampoComentario('');
        setNewComment(true);
        comments(idPublicacao, 1); 
      }
    } catch (error) {
      console.error("Erro ao cadastrar este comentário");
    }
  };
  const updatePublic = () => {
    try {
      setButtonClicked(true);
      const updatePost = updatePostPublic(idPublicacao, postPublico);
      setPostPublico(postPublico === 1 ? 0 : 1);
      setPosts(posts.map(post => {
        if (post._id === idPublicacao) {
          return { ...post, publico: postPublico === 1 ? 0 : 1 };
        } else {
          return post;
        }
      }));
      setPostsTxt(postsTxt.map(post => {
        if (post._id === idPublicacao) {
          return { ...post, publico: postPublico === 1 ? 0 : 1 };
        } else {
          return post;
        }
      }));
    } catch (error) {
      console.error("Erro ao alterar esta postagem");
    }
  }
  const removePost = () => {
    setShowConfirmacaoExclusao(true);
  }
  const removeComentario = (comment:any) => {
    setTextoComentarioExclusao(comment.texto);
    setDataComentarioSel(comment);
    setShowConfirmacaoExclusaoComentario(true);
  }
  const removeComentarioConfirm = () => {
    if(dataComentarioSel){
      const idComentarioSel = dataComentarioSel._id;
      const remove = deletePostComment(idComentarioSel);
      setCommentsList(commentsList.filter(c => c._id !== idComentarioSel));
      setShowConfirmacaoExclusaoComentario(false);
    }
  }
  const removePostConfirm = async () => {
    try {
      const remove = await deletePost(idPublicacao);
      setShowConfirmacaoExclusao(false);
      setShow2(false);
      if(remove){
        setPosts(posts.filter(post => post._id !== idPublicacao));
        setPostsTxt(postsTxt.filter(post => post._id !== idPublicacao));
      }
    } catch (error) {
      console.error("Erro ao remover esta postagem");
    }
  }
  
  const checkImageCache = async (url:string) => {
    if ('caches' in window) {
      const cache = await caches.open('image-cache');
      const cachedResponse = await cache.match(url);
      return cachedResponse !== undefined;
    }
    return false;
  };
  
  useEffect(() => {
    let isCancelled = false;
    
    async function fetchDataProfile() {
      try {
        const idUser = await getIdUserLogged();
        const dados = await dataProfile(idUser);
        const resultTotFollower = await getTotalFollower(dados._id);
        const resultTotFollowing = await getTotalFollowing(dados._id);
        if (!isCancelled) {
          setDadosUsuario(dados);
        }
      } catch (error) {
        console.error("Erro ao buscar usuário:", error);
      }
    }
    
    async function fetchData() {
      try {
        const idUser = await getIdUserLogged();
        if(idUser){
          const lista = await list(idUser);
          const listaTxt = await listTxt(idUser);
          if (!isCancelled) {
            setPosts(lista);
            setPostsTxt(listaTxt);
            const totalPosts = lista.length + listaTxt.length;
            setTotalPosts(totalPosts);
          }
        }
      } catch (error) {
        console.error("Erro ao buscar postagens:", error);
      }
    }
    
    async function getTotalFollower(idUser: string): Promise<any> {
      try {
        const resultTotalFollower = await totalFollower(idUser);
        setTotFollower(resultTotalFollower.totalFollowers);
      } catch (error) {
        console.error("Erro ao buscar Followers:", error);
      }
    }
    
    async function getTotalFollowing(idUser: string): Promise<any> {
      try {
        const resultTotalFollowing = await totalFollowing(idUser);
        setTotFollowing(resultTotalFollowing.totalFollowing);
      } catch (error) {
        console.error("Erro ao buscar Followings:", error);
      }
    }
    
    fetchData();
    fetchDataProfile();
    
    return () => {
      isCancelled = true;
    };
  }, []);
  
  useEffect(() => {
    async function updateProfileData() {
      if (dadosUsuario) {
        const isCached = await checkImageCache(dadosUsuario.srcProfile);
        if (!isCached) {
          setImgPerfil(dadosUsuario.srcProfile);
        }
        setNome(dadosUsuario.nome);
        setProfile(dadosUsuario.profile);
        setBio(dadosUsuario.bio);
        setIdUsuario(dadosUsuario._id);
      }
    }
    updateProfileData();
  }, [dadosUsuario]);
  
  return (
    <>
    <StaticMenuComponent />
    <HeaderComponent />
    <main>   
    <div className="contentPage">
    <div className="Content1">
    <div className="d-flex cemPor px-2 pt-1">
    <Col className="my-1 col-md-3 col-3">
    
    {imgPerfil ? (
      <LazyLoadImage effect="blur" className="cemPor" src={imgPerfil} style={{ borderRadius: "50%" }} />
    ) : (
      <div className="text-center pt-1">
      <FaCircleUser className="fs-5em opacity-0-1" />
      </div>
    )}
    
    </Col>
    
    <Col className="my-1 col-md-9 col-9 px-2">
    
    <h4 className="pt-1 mb-0 px-2 arial float-start fw-bold cemPor text-left">
    {nome}
    <br />
    {profile !== '' ? (
      <span className="fs-07em">@{profile}</span>
    ) : (
      <span>{nome}</span>
    )}
    </h4>
    <div className="d-grid float-start cemPor">
    
    <div className="d-flex float-start cemPor">
    <div className="text-center col-4 contadoresTextosPerfil">
    <h5 className="mt-2 mb-0 arial">{totalPosts}</h5>
    <h6 className="arial">{parseInt(totalPosts) === 1 ? 'Postagem' : 'Postagens'}</h6>
    </div>
    
    <div className="text-center col-4 contadoresTextosPerfil" onClick={() => handleShowSeguidores()}>
    <h5 className="mt-2 mb-0 arial">{totFollower}</h5>
    <h6 className="arial">Seguidores</h6>
    </div>
    
    <div className="text-center col-4 contadoresTextosPerfil" onClick={() => handleShowSeguindo()} >
    <h5 className="mt-2 mb-0 arial">{totFollowing}</h5>
    <h6 className="arial">Seguindo</h6>
    </div>
    </div>
    </div>
    
    </Col>
    </div>
    
    <div className="px-3 my-2">
    
    <h6 className="arial float-start cemPor text-left">
    {bio}
    </h6>
    
    <Link to="/account/profile/edit" className="cemPor text-center">
    <Button
    variant="success"
    size="lg"
    className="text-center btn rounded-4 mr-1per py-1 w-auto"
    >
    <span className="pt-1 mr-2">
    <small>EDITAR PERFIL</small>
    </span>
    </Button>
    </Link> 
    </div>
    
    <div className="cemPor mt-3 px-2 cemPor">
    
    <Tabs
    id="controlled-tab-example"
    activeKey={key || undefined}
    onSelect={(k) => setKey(k)}
    className="mb-1 tabsPerfil cemPor"
    >
    <Tab eventKey="home" title="FOTOS" className="px-1 pt-2 cemPor">
    {posts.length === 0 && (
      <div className="text-center py-2">
      <span className="fs-1-2em">Nenhuma publicação ainda <TbMoodEmpty /></span>
      </div>
    )}
    {posts.length > 0 && posts.map((post, index) => (
      <Figure key={post._id} className="cinquentaPor mb-0" onClick={() => handleShow2(post)}>
      <LazyLoadImage
      effect="blur"
      className="cemPor mb-0"
      src={post.srcImagem}
      style={{
        height: "207px",
        borderRight: "3px solid white",
        borderBottom: "3px solid white",
      }}
      />
      </Figure>
    ))}
    </Tab>
    <Tab eventKey="profile" title="TEXTOS" className="px-3 pt-1 cemPor">
    {postsTxt.length === 0 && (
      <div className="text-center py-2">
      <span className="fs-1-2em">Nenhuma texto ainda <TbMoodEmpty /></span>
      </div>
    )}
    {postsTxt.length > 0 && postsTxt.map((postTxt, index) => (
      <Card key={postTxt._id} body className="mt-2 border-none sombra-1" onClick={() => handleShow2(postTxt)}>
      <h6>{postTxt.texto}</h6>
      <p className="text-end mb-0"><small className="italico font-grey fs-08em">publicado em: {formatDate1(postTxt.dataPublicacao)}</small></p>
      </Card>
    ))}
    </Tab>
    </Tabs>
    
    </div>
    </div>
    </div>
    </main>
    
    <Modal show={showConfirmacaoExclusaoComentario} className="modalConfirmacaoExclusaoComentario">
    <Modal.Body className="px-2" style={{ overflowY: "auto" }}>
    <Col xs={12} md={12} className="text-left py-2 px-3">
    <div className="p-0 d-grid cemPor text-center">
    <h6 className="fw-bold arial">Deseja realmente excluir este comentário?</h6>
    <span className="py-3">{textoComentarioExclusao}</span>
    <Button
    variant="outline-danger"
    className="rounded-pill px-4 btn-lg my-2"
    onClick={removeComentarioConfirm}
    >
    SIM EXCLUIR
    </Button>
    <Button
    variant="outline-primary"
    className="rounded-pill px-4 btn-lg my-2"
    onClick={handleCloseConfirmacaoExclusaoComentario}
    >
    CANCELAR
    </Button>
    </div>
    </Col>
    </Modal.Body>
    </Modal>
    
    <Modal show={showConfirmacaoExclusao} className="modalConfirmacaoExclusao">
    <Modal.Body className="px-2" style={{ overflowY: "auto" }}>
    <Col xs={12} md={12} className="text-left py-2 px-3">
    <div className="p-0 d-grid cemPor text-center">
    <h6 className="fw-bold arial">Deseja realmente excluir esta publicação?</h6>
    <Button
    variant="outline-danger"
    className="rounded-pill px-4 btn-lg my-2"
    onClick={removePostConfirm}
    >
    SIM EXCLUIR
    </Button>
    <Button
    variant="outline-primary"
    className="rounded-pill px-4 btn-lg my-2"
    onClick={handleCloseConfirmacaoExclusao}
    >
    CANCELAR
    </Button>
    </div>
    </Col>
    </Modal.Body>
    </Modal>
    
    <ModalPerfisComponent show={show} onHide={handleClose} titulo="Lista de Curtidas" idPublicacao={idPublicacao} />
    <ModalSeguidoresComponent show={showSeguidores} onHide={handleCloseSeguidores} titulo="Seguidores" idUser={idUsuario} onData={calculaSeguidor} />
    <ModalSeguindoComponent show={showSeguindo} onHide={handleCloseSeguindo} titulo="Seguindo" idUser={idUsuario} />
    
    <Offcanvas
    show={show2}
    onHide={handleClose2}
    {...props}
    placement={"end"}
    style={{ width: "90%" }}
    >
    <Offcanvas.Body className="pt-0">
    <div className="legendaPostDetalhe">
    <div>
    {imagemAtual && (<Figure className="cemPor py-3 pt-0 pb-0 mb-2 figure">
      <LazyLoadImage
      effect="blur"
      className="cemPor mb-0"
      src={imagemAtual}
      style={{
        borderRight: "3px solid white",
        borderBottom: "3px solid white",
        height: "320px",
      }}
      />
      </Figure>
    )}
    <div className="d-flex">
    <div className="d-flex cemPor">
    <Button
    variant="link"
    size="lg"
    className="btn rounded p-0 text-black text-start ps-2 float-start"
    onClick={handleShow}
    >{buttonTextCurtidas}</Button>
    <h6 className="arial w-auto float-end pe-1 italico"><small>{dataPublicacaoPost}</small></h6>
    </div>
    </div>
    <div className="float-start">
    {textoPulicacao && (<div className="d-flex">
      <div className="px-2">
      <h6
      className="py-0 ps-1 float-start text-start arial"
      style={{ marginTop: "7px" }}
      >
      <b>{nome}</b>{" "}
      <span>
      {textoPulicacao}
      </span>
      </h6>
      </div>
      </div>
    )}
    </div>
    </div>
    
    <InputGroup className="mt-1 mb-3 input-group-lg p-2" style={{height:"39px"}}>
    <Form.Control
    aria-label="Adicionar comentário..."
    placeholder="Adicionar comentário..."
    className="br-0 fs-1em"
    value={campoComentario}
    onChange={(e) => setCampoComentario(e.target.value)}
    />
    <InputGroup.Text className="bg-white">
    <PiPaperPlaneRightFill className="bl-0" onClick={addComment} />
    </InputGroup.Text>
    </InputGroup>
    
    <div className="px-3" id="caixaScroll" style={{overflowY:'scroll'}}>
    
    {commentsList.length === 0 && (
      <p className="text-center">Nenhum comentário.</p>
    )}
    
    {commentsList.length > 0 && commentsList.map((comment) => (
      <div key={comment._id}>
      {comment.user.map((user) => (
        <React.Fragment key={user._id}>
        <div className="d-flex" {...(idUserToken === user._id && { onClick: () => removeComentario(comment) })}>
        {user.srcProfile ? (
          <Image src={user.srcProfile} className="thumb-1" />
        ) : (
          <div className="">
          <FaCircleUser className="fs-2em opacity-0-1" />
          </div>
        )}
        <div className="px-2 pb-3">
        <p className="text-start d-grid mb-0 arial">
        <b>{user.nome}</b>
        <span>{comment.texto}</span>
        </p>
        </div>
        </div>
        </React.Fragment>
      ))}
      </div>
    ))}
    
    {botaoCarregarMaisComments && (
      <Button
      variant="primary"
      className="rounded px-4 btn-sm mb-4"
      onClick={() => paginationComment()}
      disabled={botaoDesabilitadoCarregarMais}
      >
      CARREGAR MAIS
      </Button>  
    )}
    
    </div>
    
    <div className="d-grid p-0 mt-2" style={{position:"fixed",bottom:"0",right:"2%",height:"8%"}}>
    </div>
    </div>
    
    <div style={{position:"fixed",width:"85%",right:"2%",bottom:"2%"}}>
    <Button
    variant={curtiu === 0 ? `outline-success` : `success`}
    size="lg"
    className="btn rounded p-0 float-start blocoIconeAcao"
    onClick={addLike}
    >
    <AiFillLike className="fs-1-5em" />
    </Button>
    <Button
    variant="outline-primary"
    className={`btn rounded float-start mx-2 blocoIconeAcao`}
    onClick={() => updatePublic()}
    >
    {postPublico === 1 ? <FaRegEye className="fs-1-5em" /> : <FaRegEyeSlash className="fs-1-5em" /> }
    </Button>
    <Button
    variant="outline-danger"
    className="rounded btn-lg float-start blocoIconeAcao"
    onClick={removePost}
    >
    <FaTrashAlt className="fs-1-5em" />
    </Button>
    <Button
    className="rounded btn-lg float-end quarentePor"
    onClick={handleClose2}
    >
    VOLTAR
    </Button>
    </div>
    </Offcanvas.Body>
    </Offcanvas>
    <FooterComponent />
    </>
  );
}

export default AccountProfilePage;