import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "./../logo-3.png";
import { RiMenuFill } from "react-icons/ri";
import { VscBell, VscBellDot } from "react-icons/vsc";
import { IoChevronBackOutline } from "react-icons/io5";
import { TbArrowBadgeRightFilled } from "react-icons/tb";
import Cookies from "js-cookie";

interface Notification {
    _id: string;
    tipo: string;
    usuarioDestino: string;
    usuarioOrigem: string;
    idPost?: string;
    idComentario?: string;
    dataNotificacao: string;
    visualizada: boolean;
    mensagem: string;
}

const Header3Component: React.FC = () => {
    const location = useLocation();
    const { pathname } = location;
    const pathParts = pathname.split('/').filter(Boolean);
    const [tituloDin, setTituloDin] = useState('Detalhes da publicação');

    useEffect(() => {
        
        const firstPart = pathParts[0];
    }, [pathname]);

    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleLogout = () => navigate("/logout");

    const notificationCount = Cookies.get('unviewedCount');

    return (
        <Navbar expand="lg" className="MenuTopo">
            <Container className="d-block bg-azul" style={{ paddingBottom: "8px" }}>
                <IoChevronBackOutline onClick={() => navigate(-1)} className="float-start fs-1-7em mt-2 text-white" />
                <h1 className="px-2 float-start titHeaderContent">{tituloDin}</h1>
                <Button variant="" onClick={handleShow} className="w-auto bt-topo-menu-white p-0 float-end">
                    <RiMenuFill />
                </Button>
                <div className="notification-bell">
                    <Link to="/notifications">
                        {Number(notificationCount) > 0 ? (
                            <VscBellDot className="icoNotificacaoAlert2" />
                        ) : (
                            <VscBell className="icoNotificacao2" />
                        )}
                    </Link>
                </div>
                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            <img src={logo} width={150} height={50} alt="Bt Mania" />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <ul className="ps-0">
                            <li>
                                <Link to="/home">
                                    <Button variant="link" className="py-2 text-start bt-menu">
                                        <TbArrowBadgeRightFilled /> Página inicial
                                    </Button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/profiles">
                                    <Button variant="link" className="py-2 text-start bt-menu">
                                        <TbArrowBadgeRightFilled /> Encontrar pessoas
                                    </Button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/professores">
                                    <Button variant="link" className="py-2 text-start bt-menu">
                                        <TbArrowBadgeRightFilled /> Professores
                                    </Button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/chat">
                                    <Button variant="link" className="py-2 text-start bt-menu">
                                        <TbArrowBadgeRightFilled /> Mensagens
                                    </Button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/account/profile">
                                    <Button variant="link" className="py-2 text-start bt-menu">
                                        <TbArrowBadgeRightFilled /> Meu perfil
                                    </Button>
                                </Link>
                            </li>
                            <li>
                                <Button variant="link" className="py-2 text-start bt-menu" onClick={handleLogout}>
                                    <TbArrowBadgeRightFilled /> Sair
                                </Button>
                            </li>
                        </ul>
                    </Offcanvas.Body>
                </Offcanvas>
            </Container>
        </Navbar>
    );
};

export default Header3Component;