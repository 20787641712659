import { jwtDecode } from 'jwt-decode';
import Cookies from "js-cookie";

interface JwtPayload {
  id: string;
}

export function getIdToken() {
  const authHeader = Cookies.get("TokenBTMania");

  if (!authHeader) {
    throw new Error('Token não encontrado!');
  }

  try {
    const decodedToken = jwtDecode<JwtPayload>(authHeader);
    return decodedToken.id;
  } catch (error) {
    console.error("Token inválido!", error);
    throw new Error('Token inválido!');
  }
}

export function trimStrings<T extends Record<string, any>>(obj: T): T {
 
  const trimmedObj: Partial<T> = {};
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      trimmedObj[key] = obj[key].trim();
    } else {
      trimmedObj[key] = obj[key];
    }
  }
  return trimmedObj as T;
}

export function setAuthToken(token: string | null) {
  if (token) {
    localStorage.setItem('TokenBTMania', token);
  } else {
    localStorage.removeItem('TokenBTMania');
  }
}

export function getAuthToken() {
  return Cookies.get('TokenBTMania');
}

export function configureHeaders(headers: Record<string, string>) {
  const token = getAuthToken();
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  headers['Content-Type'] = 'application/json';
}

export function formatDate1(dateString: string): string {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, 
    timeZone: 'America/Sao_Paulo'
  });
  return formattedDate;
}

export function formatDate2(dateString: string): string {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  return formattedDate;
}

export function formatConversationDate(dateString: string): string {
  const date = new Date(dateString);
  const now = new Date();
  const msInDay = 24 * 60 * 60 * 1000;
  const msInWeek = 7 * msInDay;

  const diffTime = now.getTime() - date.getTime(); 
  const diffDays = Math.floor(diffTime / msInDay);
  const diffWeeks = Math.floor(diffTime / msInWeek);

  if (diffDays === 0) {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  } else if (diffDays === 1) {
    return '1d';
  } else if (diffDays < 7) {
    return `${diffDays}d`;
  } else if (diffWeeks === 1) {
    return '1sem';
  } else {
    return `${diffWeeks}sem`;
  }
}