import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';
import * as serviceWorker from './serviceWorker';

const initGA = (measurementId:any) => {
  ReactGA.initialize(measurementId);
};

const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

const measurementId = 'G-J4N6VF57FB';

initGA(measurementId);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const logPage = () => {
  logPageView();
};

window.addEventListener('load', logPage);
window.addEventListener('popstate', logPage);

serviceWorker.register();