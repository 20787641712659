import { configureHeaders } from '../utils/Utils';
import Cookies from 'js-cookie';

let apiUrl: string = process.env.REACT_APP_HOST_API as string;

export async function addMessage(id: string, idUserToken: string, novaMensagem: string): Promise<any> {
    
    try {
        
        const token = Cookies.get("TokenBTMania");
        
        if (token) {
            
            const headers: Record<string, string> = {};
            
            configureHeaders(headers);
            
            const response = await fetch(`${apiUrl}/conversation/add-message`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ id, idUserToken, novaMensagem }),
        });
        
        const dados = await response.json();
        return dados;
        
    } else {
        console.error("Token não encontrado no armazenamento local.");
    }
} catch (error) {
    
    throw error;
}
}

export async function listConversations(id: string, offset: Number, search: string) {
    try {
        
        const token = Cookies.get("TokenBTMania");
        
        if (token) {
            
            const headers: Record<string, string> = {};
            
            if(search === '' || search === null){
                
                search = 'noSearch';
              }

            configureHeaders(headers);
            
            const response = await fetch(`${apiUrl}/conversation/list/${id}/${offset}/${search}`, {
            method: 'GET',
            headers
        });
        
        const dados = await response.json();
        return dados;
        
    } else {
        console.error("Token não encontrado no armazenamento local.");
    } } catch (error) {
        console.error("Erro ao buscar conversas: ", error);
        throw error;
    }
} 

export async function listMessages(id: string, offset: Number) {
    try {
        
        const token = Cookies.get("TokenBTMania");
        
        if (token) {
            
            const headers: Record<string, string> = {};
            
            configureHeaders(headers);
            
            const response = await fetch(`${apiUrl}/conversation/messages/${id}/${offset}`, {
            method: 'GET',
            headers
        });
        
        const dados = await response.json();
        return dados;
        
    } else {
        console.error("Token não encontrado no armazenamento local.");
    } } catch (error) {
        console.error("Erro ao buscar mensagens:", error);
        throw error;
    }
} 

export async function iniciarChat(id: string, idUserToken: string): Promise<any> {
    
    try {
        
        const token = Cookies.get("TokenBTMania");
        
        if (token) {
            
            const participants = [ id, idUserToken ];
            
            const headers: Record<string, string> = {};
            
            configureHeaders(headers);
            
            const response = await fetch(`${apiUrl}/conversation/find-or-create`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ participants }),
        });
        
        const dados = await response.json();
        return dados;
        
    } else {
        console.error("Token não encontrado no armazenamento local.");
    }
} catch (error) {
    
    throw error;
}
}

export async function carregarChat(id: string): Promise<any> {
    
    try {
        
        const token = Cookies.get("TokenBTMania");
        
        if (token) {
                    
            const headers: Record<string, string> = {};
            
            configureHeaders(headers);
            
            const response = await fetch(`${apiUrl}/conversation/find/${id}`, {
            method: 'GET',
            headers
        });
        
        const dados = await response.json();
        return dados;
        
    } else {
        console.error("Token não encontrado no armazenamento local.");
    }
} catch (error) {
    
    throw error;
}
}