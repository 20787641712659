import { configureHeaders } from '../utils/Utils';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

let apiUrl: string = process.env.REACT_APP_HOST_API as string;

export async function totalLikes(idPost: String) {
  try {
      
      const token = Cookies.get("TokenBTMania");
      
      if (token) {

          const headers: Record<string, string> = {};
                      
          configureHeaders(headers);
          
          const response = await fetch(`${apiUrl}/post-like/total/${idPost}`, {
          method: 'GET',
          headers
      });
      
      const dados = await response.json();
      return dados;
      
  } else {
      console.error("Token não encontrado no armazenamento local.");
  } } catch (error) {
      console.error("Erro ao buscar total de curtidas:", error);
      throw error;
  }
} 

export async function getLike(idPost: string) {
    try {
      
      const token = Cookies.get("TokenBTMania");
      
      if (token) {
        
        const headers: Record<string, string> = {};
        
        const decoded = jwtDecode(token);
        
        const jsonString = JSON.stringify(decoded);
        const jsonObject = JSON.parse(jsonString);
        const idUser = (jsonObject.id);
        
        configureHeaders(headers);
        
        const response = await fetch(`${apiUrl}/post-like/verify/${idPost}/${idUser}`, {
        method: 'GET',
        headers
      });
      
      const dados = await response.json();
      return dados;
      
    } else {
      console.error("Token não encontrado no armazenamento local.");
    }
  } catch (error) {
    console.error("Erro ao buscar usuário:", error);
    throw error;
  }
  }

export async function sendLike(idPost: string): Promise<any> {
    
    try {
        
        const token = Cookies.get("TokenBTMania");
        
        if (token) {
            
            if(!idPost){
                
                return { error: 'Publicação não encontrada' };
            }
            
            const decoded = jwtDecode(token);
            
            const jsonString = JSON.stringify(decoded);
            const jsonObject = JSON.parse(jsonString);
            const idUser = (jsonObject.id);
            
            const headers: Record<string, string> = {};
            
            configureHeaders(headers);
            
            const response = await fetch(`${apiUrl}/post-like`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ idPost, idUser }),
        });
        
        const data = await response.json();
        return data;
        
    } else {
        console.error("Token não encontrado no armazenamento local.");
    }} catch (error) {
        
        throw error;
    }
}

export async function listLikes(idPost: String, offset: Number) {
  try {
      
      const token = Cookies.get("TokenBTMania");
      
      if (token) {

          const headers: Record<string, string> = {};

          const decoded = jwtDecode(token);
        
          const jsonString = JSON.stringify(decoded);
          const jsonObject = JSON.parse(jsonString);
          const idUser = (jsonObject.id);

          configureHeaders(headers);
          
          const response = await fetch(`${apiUrl}/post-like/${idPost}/${offset}/${idUser}`, {
          method: 'GET',
          headers
      });
      
      const dados = await response.json();
      return dados;
      
  } else {
      console.error("Token não encontrado no armazenamento local.");
  } } catch (error) {
      console.error("Erro ao buscar comentários:", error);
      throw error;
  }
}