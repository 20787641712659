import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ element }: { element: JSX.Element }) => {
  const { isLoggedIn, emailConfirmed } = useAuth();
  const location = useLocation();

  if (location.pathname === '/logout') {
    return element;
  }

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  if (isLoggedIn && emailConfirmed === false && location.pathname !== '/account/profile/verify') {
    return <Navigate to="/account/profile/verify" replace />;
  }

  return element;
};

const UnprotectedRoute = ({ element }: { element: JSX.Element }) => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to="/home" replace />;
  }

  return element;
};

export { ProtectedRoute, UnprotectedRoute };