import { listFollowers } from "../services/followerService";
import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Col, Image } from "react-bootstrap";
import { follow } from "../services/followerService";
import { FaCircleUser } from "react-icons/fa6";

interface User {
    _id: string;
    nome: string;
    profile: string;
    srcProfile: string;
    bio: string;
    cidade: string;
    followed: boolean;
}

interface Result {
    _id: string;
    dataPublicacao: string;
    user: User;
}

interface ModalSeguidoresComponentProps {
    show: boolean;
    onHide: () => void;
    titulo: string;
    idUser: string;
    onData: (data: number) => void;
}

const ModalSeguidoresComponent: React.FC<ModalSeguidoresComponentProps> = ({ show, onHide, titulo, idUser, onData }) => {
    
    const [botaoCarregarMaisPerfisLikes, setBotaoCarregarMaisPerfisLikes] = useState(true);
    
    const [lista, setLista] = useState<Result[]>([]);
    
    const [botaoDesabilitadoCarregarMaisPerfisLikes, setBotaoDesabilitadoCarregarMaisPerfisLikes] = useState(false);
    
    const [listPerfisObj, setListPerfisObj] = useState<any | null>(null);
    
    const [contadorOffset, setContadorOffset] = useState(0);
    
    const getListLikes = async (offsetList: number) => {
        try {
            
            const resultListLikes = await listFollowers(idUser, offsetList);
            
            if(resultListLikes.length > 9){
                setBotaoCarregarMaisPerfisLikes(true);
            }else{
                setBotaoCarregarMaisPerfisLikes(false);
            }
            
            if (offsetList > 0) {
                setListPerfisObj((prevListPerfisObj:any) => [...prevListPerfisObj, ...resultListLikes]);
                setLista((prevListPerfisObj) => [...prevListPerfisObj, ...resultListLikes]);
            } else {
                setListPerfisObj(resultListLikes);
                setLista(resultListLikes);
            }
            
        } catch (error) {
            console.error("Erro ao listar curtidas:", error);
        }
    }
    
    const paginationPerfisLikes = () => {
        
        setBotaoDesabilitadoCarregarMaisPerfisLikes(true);
        
        getListLikes(contadorOffset);
        
        setContadorOffset(contadorOffset + 1);
        
        setBotaoDesabilitadoCarregarMaisPerfisLikes(false);
    };
    
    useEffect(() => {
        
        if (show) {
            
            getListLikes(0);
        } else {
        }
        
        setContadorOffset(1);
        
    }, [show]);
    
    const FollowButton = ({ user }: { user: User }) => {
                
        const [isFollowing, setIsFollowing] = useState(user.followed);
        
        const toggleFollow = useCallback(async () => {
            try {
                await follow(user._id);
                setIsFollowing((prev) => !prev);
            } catch (error) {
                console.error("Erro ao alternar seguir/deixar de seguir:", error);
            }
        }, [user._id]);
        
        if (idUser === user._id) {
            return null;
        }

        return (
            <Button
            variant={isFollowing ? "outline-success" : "success"}
            size="lg"
            className="btn rounded-5 mr-1per px-0 btSeguir"
            onClick={toggleFollow}
            >
            <span className="pt-1 mr-2">
            <small>{isFollowing ? "SEGUINDO" : "SEGUIR"}</small>
            </span>
            </Button>
        );
    };
    
    return (
        <Modal show={show} onHide={onHide} className="modalCurtidas">
        <Modal.Header closeButton className="my-0 pb-2">
        <Modal.Title className="cemPor text-center">{titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-1" style={{ overflowY: "auto" }}>
        {lista.length === 0 && (
            <span className="text-center cemPor d-block arial">Nenhum seguidor ainda.</span>
        )}
        {lista.length > 0 && lista.map((result, index) => (
            <Col xs={12} md={12} className="text-left py-2 px-1 d-flex zebra" key={index}>
            <div className="d-flex cemPor">
            
            {result.user.srcProfile ? (
                <Image src={result.user.srcProfile} className="thumb-2" />
            ) : (
                <div className="thumb-2 text-center">
                <FaCircleUser className="fs-3em opacity-0-1" />
                </div>
            )}
            
            <div className="py-1 px-2 cemPor">
            <div style={{ float: 'left' }}>
            <h6 className="fw-bold arial mb-1">{result.user.profile}</h6>
            <h6 className="arial">{result.user.nome}</h6>
            </div>
            <div style={{ float: 'right' }}>
            <FollowButton user={result.user} />
            </div>
            </div>
            </div>
            </Col>
        ))}
        
        {botaoCarregarMaisPerfisLikes && (
            <Button
            variant="primary"
            className="rounded px-4 btn-sm mb-4 mx-3 mt-1"
            onClick={() => paginationPerfisLikes()}
            disabled={botaoDesabilitadoCarregarMaisPerfisLikes}
            >
            CARREGAR MAIS
            </Button>  
        )}
        
        </Modal.Body>
        </Modal>
    );
}

export default ModalSeguidoresComponent;