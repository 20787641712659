import React, { useState, useEffect } from 'react';
import { FloatingLabel, Form } from "react-bootstrap";
import { UserData } from '../types/Types';

interface City {
    cod_municipio: number;
    nome_municipio: string;
    estado: string;
}

interface Props {
    dadosUsuario: UserData | null;
    onSave: (cidade: string) => void;
    onCityChange: (cidade: string) => void;
  }

let apiUrl: string = process.env.REACT_APP_HOST_API as string;

const CidadesComponent: React.FC<Props> = ({ dadosUsuario, onCityChange }) => {
    
    const [cidade, setcidade] = useState('');
    const [searchResults, setSearchResults] = useState<City[]>([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {
        if (dadosUsuario) {
            setcidade(dadosUsuario.cidade);
        }
    }, [dadosUsuario]);

    const handleOnSearch = async (string: string) => {
        try {
            const response = await fetch(`${apiUrl}/municipios?query=${string}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Erro ao buscar cidades');
            }

            const data: City[] = await response.json();

            setSearchResults(data);
            setShowResults(true);
        } catch (error) {
            console.error('Erro:', error);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setcidade(value);

        if (value.length > 2) {
            handleOnSearch(value);
        } else {
            setShowResults(false);
        }
    };

    const handleItemClick = (item: City) => {
       
        setcidade(`${item.nome_municipio} / ${item.estado}`);
        setShowResults(false);
    
        onCityChange(`${item.nome_municipio} / ${item.estado}`);
    };

    return (
        <div>
            <FloatingLabel controlId="floatingInput" label="Cidade" className="mb-3 mx-4">
                <Form.Control
                    className="form-control bg-light"
                    type="text"
                    value={cidade}
                    onChange={handleInputChange}
                    placeholder="Digite para buscar uma cidade"
                />
            </FloatingLabel>

            {showResults && (
                <div style={{ maxHeight: "200px", overflowY: "auto", border: '1px solid #eee', marginBottom: '10px', marginTop: '10px' }} className='mx-4 striped-list'>
                    <ul>
                        {searchResults.map((result) => (
                            <li key={result.cod_municipio} onClick={() => handleItemClick(result)}>
                                <a href="#" style={{ margin: "5px 0 0 0", color: "#111", float: "left", width: "100%", cursor: "pointer" }}>{result.nome_municipio} / {result.estado}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CidadesComponent;