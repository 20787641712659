import { configureHeaders } from '../utils/Utils';

let apiUrl: string = process.env.REACT_APP_HOST_API as string;

export async function recuperarSenha(email:string): Promise<any> {
    try {
        
        const headers: Record<string, string> = {};
        configureHeaders(headers);
        
        const response = await fetch(`${apiUrl}/mail/recupera-senha`, { 
        method: "POST",
        headers,
        body: JSON.stringify({email: email.trim()}),
    });
    
    const responseData = await response.json();
    
    if (!response.ok) {
        throw new Error(responseData.error || "Erro ao enviar e-mail");
    }
    
    return responseData;
} catch (error) {
    console.error("Erro ao enviar e-mail:", error);
    throw error;
}
}