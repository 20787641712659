import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { TbArrowBadgeRightFilled } from "react-icons/tb";
import logo from "./../logo-3.png";

const StaticMenuComponent = ( width:any ) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/logout");
  };

  return (
    <div style={{ width: '20%', position: 'fixed', top: 0, bottom: 0, left: 0, background: '#fff', padding: '10px', borderRight: '1px solid #ccc' }} className='menuDesktop'>
      <img src={logo} width={150} height={50} alt="Bt Mania" style={{ marginBottom: '20px' }} />
      <ul className="ps-0">
      <li>
          <Link to="/post/new">
            <Button variant="link" className="py-2 text-start bt-menu">
              <TbArrowBadgeRightFilled /> Adicionar publicação
            </Button>
          </Link>
        </li>
        <li>
          <Link to="/home">
            <Button variant="link" className="py-2 text-start bt-menu">
              <TbArrowBadgeRightFilled /> Página inicial
            </Button>
          </Link>
        </li>
        <li>
          <Link to="/profiles">
            <Button variant="link" className="py-2 text-start bt-menu">
              <TbArrowBadgeRightFilled /> Encontrar pessoas
            </Button>
          </Link>
        </li>
        <li>
          <Link to="/professores">
            <Button variant="link" className="py-2 text-start bt-menu">
              <TbArrowBadgeRightFilled /> Professores
            </Button>
          </Link>
        </li>
        <li>
          <Link to="/chat">
            <Button variant="link" className="py-2 text-start bt-menu">
              <TbArrowBadgeRightFilled /> Mensagens
            </Button>
          </Link>
        </li>
        <li>
          <Link to="/account/profile">
            <Button variant="link" className="py-2 text-start bt-menu">
              <TbArrowBadgeRightFilled /> Meu perfil
            </Button>
          </Link>
        </li>
        <li>
          <Button variant="link" className="py-2 text-start bt-menu" onClick={handleLogout}>
            <TbArrowBadgeRightFilled /> Sair
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default StaticMenuComponent;