import React, { useState, useEffect, useRef } from "react";
import { Button, FloatingLabel, Form, Alert, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import HeaderComponent from "../../components/Header2Component";
import FooterComponent from "../../components/FooterComponent";
import CidadesComponent from "../../components/CidadesComponent";
import ImageseletorComponent from "../../components/ImageseletorComponent";
import { updateProfile, dataProfile } from '../../services/userService';
import { UserData } from '../../types/Types';
import { getIdUserLogged } from "../../services/userService";
import StaticMenuComponent from "../../components/StaticMenuComponent";

function AccountProfileEditPage() {
  const [show, setShow] = useState(false);
  const [nome, setNome] = useState("");
  const [bio, setBio] = useState("");
  const [professor, setProfessor] = useState("0");
  const [cidade, setCidade] = useState("");
  const [dadosUsuario, setDadosUsuario] = useState<UserData | null>(null);
  const [alertVariant, setAlertVariant] = useState("success");
  const [alertContent, setAlertContent] = useState("");
  const [srcProfile, setSrcProfile] = useState("");
  const [showModalFotoPerfil, setShowModalFotoPerfil] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [botaoDesabilitado1, setbotaoDesabilitado1] = useState(false);
  const [showSalvarButton, setShowSalvarButton] = useState(false);
  const inputFile = useRef<HTMLInputElement>(null);

  const handleCloseModalFotoPerfil = () => setShowModalFotoPerfil(false);

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSrcProfile(imageUrl);
      setShowSalvarButton(true);
    }
  };

  const checkImageCache = async (url:string) => {
    if ('caches' in window) {
      const cache = await caches.open('image-cache');
      const cachedResponse = await cache.match(url);
      return cachedResponse !== undefined;
    }
    return false;
  };

  useEffect(() => {
    async function fetchDataProfile() {
      try {
        const idUser = await getIdUserLogged();
        const dados = await dataProfile(idUser);
        setDadosUsuario(dados);
      } catch (error) {
        console.error("Erro ao buscar usuário:", error);
      }
    }
    fetchDataProfile();
  }, []);

  useEffect(() => {
    async function updateProfileData() {
      if (dadosUsuario) {
        const isCached = await checkImageCache(dadosUsuario.srcProfile);
        if (!isCached) {
          setSrcProfile(dadosUsuario.srcProfile);
        }
        setNome(dadosUsuario.nome);
        setBio(dadosUsuario.bio);
        setCidade(dadosUsuario.cidade);
        setProfessor(dadosUsuario.professor);
      }
    }
    updateProfileData();
  }, [dadosUsuario]);

  const handleSalvarAlteracoes = async (cidade: string) => {
    try {
      setbotaoDesabilitado1(true);
      const result = await updateProfile(nome, bio, cidade, professor);
      if (!result.error) {
        setbotaoDesabilitado1(false);
        setAlertVariant("success");
        setAlertContent(result.message);
        setShow(true);
        setTimeout(() => {
          setShow(false);
        }, 3000);
      } else {
        setbotaoDesabilitado1(false);
        setAlertVariant("danger");
        setAlertContent(result.error);
        setShow(true);
      }
    } catch (error) {
      setbotaoDesabilitado1(false);
      setAlertVariant("danger");
      setAlertContent("Erro ao salvar alterações. Por favor, tente novamente mais tarde.");
      setShow(true);
    }
  };

  const navigate = useNavigate();

  return (
    <>
      <StaticMenuComponent />
      <HeaderComponent />
      <main>
      <div className="contentPage">
        <div className="Content1">
          <div className="mx-3 my-4">
            <ImageseletorComponent setIsVisible={setIsVisible} srcProfile={srcProfile} croppedImage="" />
          </div>
          <CidadesComponent dadosUsuario={dadosUsuario} onSave={handleSalvarAlteracoes} onCityChange={setCidade} />
          <FloatingLabel controlId="floatingInput" label="Nome" className="mb-3 mx-4">
            <Form.Control
              type="text"
              placeholder=""
              className="bg-light"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingTextarea2" label="Sobre mim" className="mb-3 mx-4">
            <Form.Control
              as="textarea"
              placeholder="Sobre mim"
              style={{ height: '100px' }}
              className="bg-light"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
            />
          </FloatingLabel>
          <div className="cemPor px-4">
            <Form.Select 
              aria-label="Selecione uma opção" 
              className="mb-3" 
              onChange={(e) => setProfessor(e.target.value)}
              value={professor}
              >
              <option value="0">Sou praticante de Beach Tennis</option>
              <option value="1">Sou professor de Beach Tennis</option>
            </Form.Select>          
          </div>
          <Alert variant={alertVariant} show={show} className="mx-4">
            <p className="my-0 text-center">{alertContent}</p>
          </Alert>
          <Button
            variant="primary"
            size="lg"
            className="mb-3 mx-4 rounded py-3"
            onClick={() => handleSalvarAlteracoes(cidade)}
            disabled={botaoDesabilitado1}
          >
            SALVAR ALTERAÇÕES
          </Button>
        </div>
        </div>
      </main>

      <Modal show={showModalFotoPerfil} onHide={handleCloseModalFotoPerfil} className="modalNovaPublicacao">
        <Modal.Header>
          <Modal.Title className="cemPor text-center">
            Foto do Perfil
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <div className="d-grid text-center">
            {!showSalvarButton && (
              <Button
                variant="outline-primary"
                className="rounded px-4 py-2 btn-lg my-4 d-grid btn-add-publicacao"
                onClick={() => inputFile.current?.click()}
              >
                <MdOutlineAddPhotoAlternate className="btn-add-publicacao-img" />
                <span className="btn-add-pubicacao-span">SELECIONAR IMAGEM</span>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={inputFile}
                  onChange={handleImageSelect}
                />
              </Button>
            )}
            {showSalvarButton && (
              <Button
                size="lg"
                className="rounded-pill px-4 btn-lg py-3 my-4"
                style={{ display: showSalvarButton ? 'block' : 'none' }}
              >
                SALVAR
              </Button>
            )}
          </div>
          <hr className="my-2" />
          <Button className="link-azul fs-1-2em" onClick={handleCloseModalFotoPerfil}>
            Cancelar
          </Button>
        </Modal.Body>
      </Modal>

      <FooterComponent />
    </>
  );
}

export default AccountProfileEditPage;