import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Helmet } from 'react-helmet';
import logo from "../../logo-btmania-a-rede-social-do-beach-tennis.png";
import { Button, FloatingLabel, Form, Alert } from "react-bootstrap";
import { login } from "../../services/userService";
import { useAuth } from "../../context/AuthContext";

function LoginPage() {
  const [retornoAlerta, setRetornoAlerta] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setEmailConfirmed, setIsLoggedIn, setToken } = useAuth();
  const navigate = useNavigate();
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);

  useEffect(() => {
    const token = Cookies.get("TokenBTMania");
    if (token) {
      navigate("/home");
    }
  }, [navigate]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setBotaoDesabilitado(true);
    try {
      const userData = await login(email, password);
      const token = userData.token;
      setToken(token);
      setEmailConfirmed(userData.emailConfirmado);
      setIsLoggedIn(true);

      if (!userData.emailConfirmado) {
        navigate('/account/profile/verify');
        return;
      }

      navigate("/home");
    } catch (error: any) {
      setBotaoDesabilitado(false);
      setRetornoAlerta(error.message);
    }
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Login - BT Mania",
    "description": "BT Mania é a rede social ideal para praticantes de Beach Tennis. Conecte-se com outros jogadores, compartilhe suas experiências e fique por dentro das novidades do mundo do Beach Tennis. Faça seu login agora!",
    "url": "https://btmania.com.br/login",
    "publisher": {
      "@type": "Organization",
      "name": "BT Mania",
      "logo": {
        "@type": "ImageObject",
        "url": "https://btmania.com.br/assets/images/logo-200-200.png"
      }
    }
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
        <meta name="description" content="BT Mania é a rede social ideal para praticantes de Beach Tennis. Conecte-se com outros jogadores, compartilhe suas experiências e fique por dentro das novidades do mundo do Beach Tennis. Faça seu login agora!" /> 
        <meta name="keywords" content="beach tennis, BT Mania, rede social beach tennis, jogadores de beach tennis, comunidade beach tennis, torneios beach tennis, dicas de beach tennis, login, faça seu login" /> 
        <meta name="author" content="BT Mania" />
        <meta name="theme-color" content="#ffffff" /> 
        <meta name="twitter:card" content="summary_large_image" /> 
        <meta property="og:type" content="website" /> 
        <meta property="og:title" content="BT Mania - Rede Social para Praticantes de Beach Tennis. Faça seu login agora!" /> 
        <meta property="og:description" content="BT Mania é a rede social ideal para praticantes de Beach Tennis. Conecte-se com outros jogadores, compartilhe suas experiências e fique por dentro das novidades do mundo do Beach Tennis. Faça seu login agora!" /> 
        <meta property="og:image" content="https://btmania.com.br/assets/images/logo-200-200.png" /> 
        <meta property="og:url" content="https://btmania.com.br" /> 
        <meta name="twitter:site" content="@bt_mania" /> 
        <meta name="twitter:title" content="BT Mania - Rede Social para Praticantes de Beach Tennis. Faça seu login agora!" /> 
        <meta name="twitter:description" content="BT Mania é a rede social ideal para praticantes de Beach Tennis. Conecte-se com outros jogadores, compartilhe suas experiências e fique por dentro das novidades do mundo do Beach Tennis. Faça seu login agora!" /> 
        <meta name="twitter:image" content="https://btmania.com.br/assets/images/logo-200-200.webp" /> 
        <meta name="twitter:url" content="https://btmania.com.br" /> 
        <meta name="twitter:creator" content="@bt_mania" /> 
        <meta name="twitter:domain" content="https://btmania.com.br" /> 
        <title>BT Mania - Rede Social para Praticantes de Beach Tennis. Faça seu login agora!</title>        
      </Helmet>
      <main className="centralize">
        <form className="w-300" onSubmit={handleSubmit}>
          <img src={logo} alt="BT Mania" className="logo" />
          <h5 className="cemPor textCenter primary fw-bold mb-3 mt-3">
            Faça seu Login
          </h5>
          <FloatingLabel
            controlId="floatingInput"
            label="Seu e-mail"
            className="mb-3"
          >
            <Form.Control
              type="email"
              placeholder="name@example.com"
              className="bg-light"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingPassword" label="Sua senha">
            <Form.Control
              type="password"
              placeholder="Password"
              className="bg-light"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </FloatingLabel>

          <Button
            type="submit"
            variant="primary"
            size="lg"
            className="mt-4 rounded py-3"
            disabled={botaoDesabilitado}
          >
            {botaoDesabilitado ? (
              <div className="loader"></div>
            ) : (
              <span className="text-button">ENTRAR</span>
            )}
          </Button>

          {retornoAlerta && <Alert key="warning" variant="warning" className="mt-3 text-center">{retornoAlerta}</Alert>}

          <p className="text-center mt-3">
            <Link to="/esqueci-minha-senha">
              <Button variant="outline" className="link primary">
                Esqueci minha senha
              </Button>
            </Link>
          </p>
          <hr className="divider mt-3" />
          <Link to="/register">
            <Button
              variant="success"
              size="lg"
              className="mt-4 rounded py-3"
            >
              CADASTRE-SE
            </Button>
          </Link>
        </form>
      </main>
    </>
  );
}

export default LoginPage;