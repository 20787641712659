import { configureHeaders } from '../utils/Utils';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";

let apiUrl = process.env.REACT_APP_HOST_API;

export async function getUnviewedNotifications() {
    try {
        const token = Cookies.get("TokenBTMania");
        
        if (token) {
            const headers = {};
            configureHeaders(headers);
            
            const response = await fetch(`${apiUrl}/notification/unviewed`, {
                method: 'GET',
                headers
            });
            
            if (!response.ok) {
                throw new Error(`Erro ao buscar notificações: ${response.statusText}`);
            }
            
            const notifications = await response.json();
            return notifications;
            
        } else {
            throw new Error("Token não encontrado no armazenamento local.");
        }
    } catch (error) {
        console.error("Erro ao buscar notificações não visualizadas:", error);
        throw error;
    }
}

export async function markNotificationsAsViewed() {
    try {
        const token = Cookies.get("TokenBTMania");
        
        if (token) {
            const headers = {};
            configureHeaders(headers);
            
            const token = Cookies.get("TokenBTMania");
            
            if (token) {
                
                const decoded = jwtDecode(token);
                
                const jsonString = JSON.stringify(decoded);
                const jsonObject = JSON.parse(jsonString);
                const idUser = (jsonObject.id);
                
                const response = await fetch(`${apiUrl}/notification/mark-as-viewed`, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify({ idUser }),
                });
                
                if (!response.ok) {
                    throw new Error(`Erro ao marcar notificações como visualizadas: ${response.statusText}`);
                }
                
                const result = await response.json();
                return result;
            }
        } else {
            throw new Error("Token não encontrado no armazenamento local.");
        }
    } catch (error) {
        console.error("Erro ao marcar notificações como visualizadas:", error);
        throw error;
    }
}