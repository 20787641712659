import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../../components/Header2Component";
import FooterComponent from "../../components/FooterComponent";
import { Button, Form, Badge } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import { list } from '../../services/profileService';
import { follow } from '../../services/followerService';
import { UserData } from '../../types/Types';
import { FaCircleUser } from "react-icons/fa6";
import PullToRefresh from 'react-pull-to-refresh';
import { getIdToken } from "../../utils/Utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import StaticMenuComponent from "../../components/StaticMenuComponent";

function ProfilesPage() {
  
  const [idUserToken, setIdUserToken] = useState(getIdToken()); 
  
  const handleRefresh = () => {
    return new Promise<void>((resolve) => {
      getList(0,searchTerm);
      resolve();
    });
  };
  
  const [listProfiles, setListProfiles] = useState<UserData[]>([]);
  const [botaoCarregarMaisPerfis, setBotaoCarregarMaisPerfis] = useState(false);
  const [botaoDesabilitadoCarregarMaisPerfis, setBotaoDesabilitadoCarregarMaisPerfis] = useState(false);
  const [contadorOffset, setContadorOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
  
  async function follower(profile_id: string): Promise<void> {
    
    const dados = await follow(profile_id);
  }
  
  const paginationPerfis = async () => {
    
    setBotaoDesabilitadoCarregarMaisPerfis(true);
    await getList(contadorOffset,searchTerm);
    setContadorOffset(prevOffset => prevOffset + 1);
    setBotaoDesabilitadoCarregarMaisPerfis(false);
  };
  
  const getList = async (offsetList: number, searchField: string) => {
    try {
      const resultListLikes = await list(offsetList, searchField);
      
      if (resultListLikes.length > 9) {
        setBotaoCarregarMaisPerfis(true);
      } else {
        setBotaoCarregarMaisPerfis(false);
      }
      
      if (offsetList > 0) {
        setListProfiles((prevListProfiles: UserData[]) => [...prevListProfiles, ...resultListLikes]);
      } else {
        setListProfiles(resultListLikes);
      }
      
    } catch (error) {
      console.error("Erro ao listar curtidas:", error);
    }
  };
  
  useEffect(() => {
    async function fetchDataProfile() {
      try {
        getList(0,'');
        setContadorOffset(1);
      } catch (error) {
        console.error("Erro ao buscar usuário:", error);
      }
    }
    fetchDataProfile();
  }, []);
  
  useEffect(() => {
    if (searchTerm.length > 3) {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      const timeout = setTimeout(() => {
        getList(0, searchTerm);
        setContadorOffset(1);
      }, 500);
      setDebounceTimeout(timeout);
    }
  }, [searchTerm]);
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
  };
  
  const navigate = useNavigate();
  
  return (
    <>
    <StaticMenuComponent />
    <HeaderComponent />
    <main>
   
    <div className="contentPage">
    <div className='Content1'>
    <InputGroup className="mb-1 input-group-md p-2">
    <Form.Control
    aria-label="Pesquisar..."
    placeholder="Pesquisar..."
    className="br-0 bl-search"
    value={searchTerm}
    onChange={handleSearchChange}
    />
    <InputGroup.Text className="bg-white bl-0 br-search">
    <IoSearch className="bl-0" />
    </InputGroup.Text>
    </InputGroup>
    <PullToRefresh onRefresh={handleRefresh}>
    {listProfiles.map((profile, index) => (
      <Col xs={12} md={12} className="text-left py-2 px-3 d-flex zebra" key={profile._id}>
      <Link to={`/profile/${profile._id}`} className="text-black text-decoration-none">
      {profile.srcProfile ? (
        <LazyLoadImage effect="blur" src={profile.srcProfile} className="thumb-2" />
      ) : (
        <div className="">
        <FaCircleUser className="fs-3em opacity-0-1" />
        </div>
      )}
      </Link>
      <div className="pr-0 ps-2 pt-1 d-flex cemPor">
      <div className="cemPor">
      <Link to={`/profile/${profile._id}`} className="text-black text-decoration-none">
      <h6 className="arial fw-bold mb-0 ps-1">
        {profile.profile}
        {profile.professor == '1' && (<Badge bg="warning" text="dark" style={{fontSize:"0.7em",float:"right",marginTop:"5px"}}>Professor(a)</Badge>)}  
      </h6>
      <h6 className="arial mt-0 mb-0 ps-1">{profile.nome}</h6>
      </Link>
      </div>
      {/* <div style={{ width: "30%" }}>
      <ProfileButton profile={profile} follower={follower} idUserToken={idUserToken} />
      </div> */}
      </div>
      </Col>
    ))}
    
    {botaoCarregarMaisPerfis && (
      <Button
      variant="primary"
      className="rounded px-4 btn-sm mb-3 mx-3 mt-3"
      onClick={paginationPerfis}
      disabled={botaoDesabilitadoCarregarMaisPerfis}
      >
      CARREGAR MAIS
      </Button>  
    )}
    </PullToRefresh>
    </div>
    </div>
    </main>
    <FooterComponent />
    </>
  );
}

const ProfileButton = ({ profile, follower, idUserToken }: { profile: UserData, follower: (profile_id: string) => Promise<void>, idUserToken: string }) => {
  
  const [isFollowing, setIsFollowing] = useState(profile.followed);
  
  const toggleFollow = async () => {
    try {
      await follower(profile._id);
      setIsFollowing(!isFollowing);
    } catch (error) {
      console.error("Erro ao alternar seguir/deixar de seguir:", error);
    }
  };
  
  if(idUserToken === profile._id){
    
    return null;
  }
  
  return (
    <Button
    variant={isFollowing ? "outline-success" : "success"}
    size="lg"
    className="btn rounded-5 mr-1per px-0 btSeguir"
    onClick={toggleFollow}
    >
    <span className="pt-1 mr-2">
    <small>{isFollowing ? "SEGUINDO" : "SEGUIR"}</small>
    </span>
    </Button>
  );
};

export default ProfilesPage;
