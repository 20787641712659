import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderComponent from "../../components/HeaderComponent";
import FooterComponent from "../../components/FooterComponent";
import { Form } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { FaCircleUser } from "react-icons/fa6";
import { listMessages, addMessage } from '../../services/chatService';
import { IoChevronBackOutline } from "react-icons/io5";
import { getIdToken } from "../../utils/Utils";
import { TbMoodSmile } from "react-icons/tb";
import { IoChatbubbles } from "react-icons/io5";
import socket from '../../socket/socket';
import StaticMenuComponent from '../../components/StaticMenuComponent';

interface Participant {
  _id: string;
  nome: string;
  srcProfile: string;
}

interface Message {
  _id: string;
  content: string;
  conversationId: string;
  createdAt: string;
  sender: {
    _id: string;
    nome: string;
    srcProfile: string;
  };
}

interface LastMessage {
  _id: string;
  content: string;
  createdAt: string;
  sender: string;
}

interface Conversation {
  _id: string;
  participants: Participant[];
  lastMessage?: LastMessage;
}

function MessagePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { conversation } = location.state || { participants: [] as Participant[] };
  const [messages, setMessages] = useState<Message[]>([]);
  const [contadorOffset, setContadorOffset] = useState(0);
  const [chatId, setChatId] = useState<string>("");
  const [idUserToken, setIdUserToken] = useState<string>(getIdToken());
  const [novaMensagem, setNovaMensagem] = useState<string>("");
  const [participant, setParticipant] = useState<Participant | null>(null);
  const messageInputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messageInputRef.current) {
      messageInputRef.current.focus();
    }

    if (conversation && conversation._id) {
      setChatId(conversation._id);
      getList(0);
      socket.emit('joinConversation', conversation._id);
    }

    return () => {
      if (conversation && conversation._id) {
        socket.emit('leaveConversation', conversation._id);
      }
    };
  }, [conversation]);

  useEffect(() => {

    const handleNewMessage = (newMessage: Message) => {
      if (newMessage.conversationId === chatId) {
        setMessages((prevMessages) => {
          const senderId = typeof newMessage.sender === 'string' ? newMessage.sender : newMessage.sender._id;
          const sender = conversation.participants.find((p: Participant) => p._id === senderId);
    
          if (!sender) {
            console.error('Sender not found in participants');
            return prevMessages;
          }
    
          const updatedMessage = {
            ...newMessage,
            sender: {
              _id: sender._id,
              nome: sender.nome,
              srcProfile: sender.srcProfile,
            }
          };

          return [...prevMessages, updatedMessage];
        });
      }
    };      

    const handleUpdateConversation = (updatedConversation: Conversation) => {
      if (updatedConversation._id === chatId && updatedConversation.lastMessage) {
        const { lastMessage } = updatedConversation;
    
        if (lastMessage) {
          const sender = updatedConversation.participants.find((p: Participant) => p._id === lastMessage.sender);
    
          if (!sender) {
            console.error('Sender not found in participants');
            return;
          }
    
          const newMessage: Message = {
            _id: lastMessage._id,
            content: lastMessage.content,
            conversationId: updatedConversation._id,
            createdAt: lastMessage.createdAt,
            sender: {
              _id: sender._id,
              nome: sender.nome,
              srcProfile: sender.srcProfile,
            },
          };
    
          setMessages((prevMessages) => {
            const messageExists = prevMessages.some(
              (message) => message._id === newMessage._id && message.createdAt === newMessage.createdAt
            );
    
            if (!messageExists) {
              const updatedMessages = [...prevMessages, newMessage];
              updatedMessages.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
              return updatedMessages;
            } else {
              return prevMessages;
            }
          });
        }
      }
    };
    
    socket.on('newMessage', handleNewMessage);
    socket.on('updateConversation', handleUpdateConversation);

    socket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    return () => {
      socket.off('newMessage', handleNewMessage);
      socket.off('updateConversation', handleUpdateConversation);
      socket.off('error');
    };
  }, [chatId]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  async function enviarNovaMensagem() {
    if (!novaMensagem.trim()) return;
    try {
      const newMessage = await addMessage(chatId, idUserToken, novaMensagem);
      setNovaMensagem('');
      if (messageInputRef.current) {
        messageInputRef.current.focus();
      }

      socket.emit('newMessage', newMessage);
    } catch (error) {
      console.error("Erro ao enviar mensagem:", error);
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      enviarNovaMensagem();
    }
  }

  async function getList(offset: number) {
    try {
      if (!conversation || !conversation._id) {
        console.error("Conversation não está definida ou não possui um _id.");
        return;
      }
  
      if (!conversation.participants || conversation.participants.length === 0) {
        console.error("Participants não estão definidos na conversation.");
        return;
      }
  
      const dados = await listMessages(conversation._id, offset);
  
      const otherParticipant = conversation.participants.find((participant: Participant) => participant._id !== idUserToken);
      
      setParticipant(otherParticipant);
  
      if (dados && Array.isArray(dados.messages)) {

        setMessages(dados.messages.reverse());
      } else {
        console.error("Formato de resposta inesperado:", dados);
        setMessages([]);
      }
    } catch (error) {
      console.error("Erro ao buscar mensagens:", error);
      setMessages([]);
    }
  }  
  
  return (
    <>
      <StaticMenuComponent />
      <main>
        <div className="d-flex cemPor MenuTopo">
          <div className='d-block bg-azul container' style={{ paddingBottom: "10px" }}>
            <IoChevronBackOutline onClick={() => navigate(-1)} className="float-start fs-1-7em mt-2 text-white" />
            <h1 className="px-2 float-start titHeaderContent">{participant && (participant.nome)}</h1>
            <div style={{ float: 'right', display: 'flex', paddingTop: '6px' }}>
              {participant && participant.srcProfile ? (
                <Image src={participant.srcProfile} className="thumb-1" />
              ) : (
                <FaCircleUser className="fs-1-6em opacity-0-1" />
              )}
            </div>
          </div>
        </div>
        <div className="cemPor float-start Content1">
        {Array.isArray(messages) && messages.length > 0 ? (
          messages.map((message) => {

            return (
              <Col key={message._id} xs={12} md={12} className={`text-left py-1 px-3 d-flex ${message.sender && message.sender._id === idUserToken ? 'justify-content-end' : ''}`}>
                {message.sender && message.sender._id !== idUserToken && (
                  message.sender.srcProfile ? (
                    <Image src={message.sender.srcProfile} className="avatar-2" />
                  ) : (
                    <FaCircleUser className="fs-3em opacity-0-1" style={{ marginRight: '7px' }} />
                  )
                )}
                <div className="px-1 py-0 d-block cemPor">
                  <div className={`px-0 pt-1 ${message.sender && message.sender._id !== idUserToken ? 'bg-chat-friend' : 'bg-chat-me'}`}>
                    <h6 className="arial m-0">
                      {message.content}
                      <p className="fs-07em pt-0 mb-0" style={{ color: "#bbbbbb", fontStyle: "italic" }}>
                        {new Date(message.createdAt).toLocaleTimeString()}
                      </p>
                    </h6>
                  </div>
                </div>
              </Col>
            );
          })
        ) : (
          <p className='text-center py-3'>
            <small className="fs-1em text-gray">Iniciar conversa <TbMoodSmile /></small>
          </p>
        )}
          <div ref={messagesEndRef} />
        </div>
        <InputGroup className="input-group-md px-2 pb-2 pt-2 bottomInputGroup bg-white">
          <Form.Control
            aria-label="Escrever mensagem..."
            placeholder="Escrever mensagem..."
            className="br-0 bl-search"
            value={novaMensagem}
            onChange={(e) => setNovaMensagem(e.target.value)}
            onKeyPress={handleKeyPress}
            ref={messageInputRef}
          />
          <InputGroup.Text className="bg-white bl-0 br-search" onClick={enviarNovaMensagem}>
            <PiPaperPlaneRightFill className="bl-0" />
          </InputGroup.Text>
        </InputGroup>
      </main>
      <FooterComponent />
    </>
  );
}

export default MessagePage;