import { configureHeaders } from '../utils/Utils';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

let apiUrl: string = process.env.REACT_APP_HOST_API as string;

export async function updatePostPublic(id: string, publico: number){
  
  try {
    
    const token = Cookies.get("TokenBTMania");
    
    if (token) {
      
      const decoded = jwtDecode(token);
      
      const jsonString = JSON.stringify(decoded);
      const jsonObject = JSON.parse(jsonString);
      const idUser = (jsonObject.id);
      
      let apiUrl: string = process.env.REACT_APP_HOST_API as string;
      
      const response = await fetch(`${apiUrl}/post/publico/${id}`, { 
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ publico, idUser, id }),
      });
      
      const dados = await response.json();
      return dados;
      
    } else {
      console.error("Token não encontrado no armazenamento local.");
    }
  } catch (error) {
    console.error('Erro ao atualizar dados do perfil:', error);
    throw error;
  }
}

export async function deletePost(id: string) {
  
  try {
    
    const token = Cookies.get("TokenBTMania");
    
    if (token) {
      
      const decoded = jwtDecode(token);
      
      const jsonString = JSON.stringify(decoded);
      const jsonObject = JSON.parse(jsonString);
      const idUser = (jsonObject.id);
      
      const headers: Record<string, string> = {};
      
      configureHeaders(headers);
      
      let apiUrl: string = process.env.REACT_APP_HOST_API as string;
      
      const response = await fetch(`${apiUrl}/post/remove`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ id, idUser }),
      });
      
      const data = await response.json();
      return data;
      
    } else {
      console.error("Token não encontrado no armazenamento local.");
    }
    
  } catch (error) {
    
    throw error;
  }
}

export async function Create(base64Image: string, texto: string, publico: number): Promise<any> {
  
  try {
    
    const token = Cookies.get("TokenBTMania");
    
    if (token) {
      
      if(!base64Image && !texto){
        
        return { error: 'Postagem sem conteúdo' };
      }
      
      const decoded = jwtDecode(token);
      
      const jsonString = JSON.stringify(decoded);
      const jsonObject = JSON.parse(jsonString);
      const idUser = (jsonObject.id);
      
      const headers: Record<string, string> = {};
      
      configureHeaders(headers);
      
      let apiUrl: string = process.env.REACT_APP_HOST_API as string;
      
      let hasBase64 = false;
      
      if(base64Image != null && base64Image != ''){
        
        hasBase64 = true;
      }
      
      const response = await fetch(`${apiUrl}/post`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ hasBase64, texto, publico, idUser }),
      });
      
      const data = await response.json();
      
      if(hasBase64 === true){
        
        const byteCharacters = atob(base64Image.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/jpeg' });
        
        const uploadResponse = await fetch(data.uploadUrl, {
          method: 'PUT',
          body: blob,
          headers: {
            'Content-Type': 'image/jpeg',
          },
        }); 
      }
      
      return data;
      
    } else {
      console.error("Token não encontrado no armazenamento local.");
    }
    
  } catch (error) {
    
    throw error;
  }
}

export async function list(idUser: any) {
  try {
    
    const token = Cookies.get("TokenBTMania");
    
    if (token) {
      
      const headers: Record<string, string> = {};
      
      configureHeaders(headers);
      
      let apiUrl: string = process.env.REACT_APP_HOST_API as string;
      
      const response = await fetch(`${apiUrl}/post/list/${idUser}`, {
        method: 'GET',
        headers
      });
      
      const dados = await response.json();
      return dados;
      
    } else {
      console.error("Token não encontrado no armazenamento local.");
    }
  } catch (error) {
    console.error("Erro ao buscar usuário:", error);
    throw error;
  }
}

export async function listhome(offset: Number) {
  try {
    
    const token = Cookies.get("TokenBTMania");
    
    if (token) {
      
      const decoded = jwtDecode(token);
      
      const jsonString = JSON.stringify(decoded);
      const jsonObject = JSON.parse(jsonString);
      const idUser = (jsonObject.id);
      
      const headers: Record<string, string> = {};
      
      configureHeaders(headers);
      
      const response = await fetch(`${apiUrl}/post/list-home/${idUser}/${offset}`, {
        method: 'GET',
        headers
      });
      
      const dados = await response.json();
      return dados;
      
    } else {
      console.error("Token não encontrado no armazenamento local.");
    }
  } catch (error) {
    console.error("Erro ao buscar usuário:", error);
    throw error;
  }
}

export async function listTxt(idUser: any) {
  try {
    
    const token = Cookies.get("TokenBTMania");
    
    if (token) {
      
      const headers: Record<string, string> = {};
      
      configureHeaders(headers);
      
      let apiUrl: string = process.env.REACT_APP_HOST_API as string;
      
      const response = await fetch(`${apiUrl}/post/list/txt/${idUser}`, {
        method: 'GET',
        headers
      });
      
      const dados = await response.json();
      return dados;
      
    } else {
      console.error("Token não encontrado no armazenamento local.");
    }
  } catch (error) {
    console.error("Erro ao buscar usuário:", error);
    throw error;
  }
}

export async function getOne(idPost: any) {
  try {
    
    const headers: Record<string, string> = {};
    
    configureHeaders(headers);
    
    let apiUrl: string = process.env.REACT_APP_HOST_API as string;
    
    const response = await fetch(`${apiUrl}/post/${idPost}`, {
      method: 'GET',
      headers
    });
    
    const dados = await response.json();
    return dados;
    
  } catch (error) {
    console.error("Erro ao buscar usuário:", error);
    throw error;
  }
}