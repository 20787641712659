import React, { useRef, useEffect, useState } from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { Button } from "react-bootstrap";
import { SendUserProfileImage } from '../services/userService';
import { MdAddAPhoto } from "react-icons/md";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

interface ImageseletorProps {
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    srcProfile: string;
    croppedImage: string | null;
}

const ImageSelectorComponent: React.FC<ImageseletorProps> = ({ setIsVisible, srcProfile }) => {

    const inputRef = useRef<HTMLInputElement>(null);
    const cropperRef = useRef<HTMLImageElement>(null);
    const cropperInstance = useRef<Cropper | null>(null);
    const [isAddPhotoVisible, setIsAddPhotoVisible] = useState(true);
    const [isEditPhotoVisible, setIsEditPhotoVisible] = useState(false);
    const [croppedImage, setCroppedImage] = useState<string | null>(null);
    const [profileImageSrc, setProfileImageSrc] = useState<string>("");

    const handleImageClick = () => {
        handleButtonClick();
    };

    const handleButtonClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const imgElement = cropperRef.current;
                if (imgElement && event.target) {
                    imgElement.src = event.target.result as string;

                    if (cropperInstance.current) {
                        cropperInstance.current.destroy();
                        cropperInstance.current = null;
                    }

                    cropperInstance.current = new Cropper(imgElement, {
                        aspectRatio: 1,
                        cropBoxResizable: false,
                        center: true,
                        minCanvasHeight: 300,
                        minContainerHeight: 300,
                        minCropBoxHeight: 300,
                        minCanvasWidth: 300,
                        minContainerWidth: 300,
                        minCropBoxWidth: 300,
                        dragMode: 'move',
                        cropBoxMovable: false,
                        data: {
                            height: 300,
                            width: 300,
                            x: 1,
                            scaleX: 1,
                            scaleY: 1,
                        },
                        crop: () => {

                        },
                    });
                }
            };

            reader.readAsDataURL(file);

            setIsVisible(false);

            setIsAddPhotoVisible(false);
            setIsEditPhotoVisible(true);

            const icoPhotoProfile = document.getElementById("icoPhotoProfile");

            if (icoPhotoProfile) {

                icoPhotoProfile.style.display = "none";
            }

            const imgFullCropContainer = document.getElementById("imgFullCropContainer");

            if (imgFullCropContainer) {

                imgFullCropContainer.style.display = "block";
            }
        }
    };

    useEffect(() => {

        const imageElement = cropperRef.current;

        if (imageElement && cropperInstance.current === null) {
            cropperInstance.current = new Cropper(imageElement, {
                aspectRatio: 1,
                cropBoxResizable: false
            });
        }

        return () => {
            if (cropperInstance.current) {
                cropperInstance.current.destroy();
                cropperInstance.current = null;
            }
        };
    }, []);

    const resizeAndCompressImage = (base64Image: string, maxWidth: number, maxHeight: number) => {
        return new Promise<string>((resolve, reject) => {
            const img = new Image();
            img.src = base64Image;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                if (!ctx) {
                    reject('Erro ao obter contexto de canvas');
                    return;
                }

                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                const compressedBase64 = canvas.toDataURL('image/jpeg', 0.7);

                resolve(compressedBase64);
            };

            img.onerror = () => {
                reject('Erro ao carregar a imagem');
            };
        });
    };

    const handleCrop = async () => {

        if (cropperInstance.current) {
            const canvas = cropperInstance.current.getCroppedCanvas();

            if (canvas) {

                const imgFullCrop = document.getElementById("imgFullCrop");

                if (imgFullCrop) {
                    imgFullCrop.setAttribute("src", "");
                }

                const imgFullCropContainer = document.getElementById("imgFullCropContainer");

                if (imgFullCropContainer) {
                    imgFullCropContainer.style.display = "none";
                }

                const icoPhotoProfile = document.getElementById("icoPhotoProfile");

                if (icoPhotoProfile) {
                    icoPhotoProfile.style.display = "block";
                }

                setIsEditPhotoVisible(false);
                setIsAddPhotoVisible(true);

                const croppedImageBase64 = canvas.toDataURL();

                const resizedAndCompressedImageBase64 = await resizeAndCompressImage(croppedImageBase64, 300, 300);
                setCroppedImage(resizedAndCompressedImageBase64);

                const profileImageElement = document.getElementById("profileImage");

                if (profileImageElement) {

                    profileImageElement.setAttribute("src", resizedAndCompressedImageBase64);
                }

                const response = await SendUserProfileImage(resizedAndCompressedImageBase64);
            }

            cropperInstance.current.destroy();
            cropperInstance.current = null;
        }
    };

    useEffect(() => {
        setProfileImageSrc(srcProfile);
    }, [srcProfile]);

    return (
        <>
            <input type="file" style={{ display: "none" }} ref={inputRef} onChange={handleFileChange} />

            <div style={{ position: "relative", width: "100%", maxHeight: "302px" }} id="imgFullCropContainer">
                <img ref={cropperRef} id="imgFullCrop" alt="Foto do perfil" style={{ display: "none" }} />
            </div>

            <div className="icoPhotoProfile" id="icoPhotoProfile" onClick={handleImageClick}>
                <LazyLoadImage
                    src={profileImageSrc && profileImageSrc.trim() !== "" ? profileImageSrc : croppedImage || ""}
                    className="profileImage"
                    alt=""
                    id="profileImage"
                />
            </div>

            {isAddPhotoVisible && (
                <div style={{ position: "absolute", left: "0", right: "50%", margin: "-80px 0px 0px" }} onClick={handleButtonClick}>
                    <div className="icoAddPhoto rounded-pill text-white">
                        <div>
                            <MdAddAPhoto style={{ fontSize: "2.5em", marginTop: "19%" }} />
                        </div>
                    </div>
                </div>
            )}

            {isEditPhotoVisible && (
                <div className="mt-3">
                    <Button
                        variant="primary"
                        size="lg"
                        className="mb-3 mx-4 rounded py-3"
                        onClick={handleCrop}
                    >
                        ALTERAR
                    </Button>
                </div>
            )}
        </>
    );
};

export default ImageSelectorComponent;