import HeaderComponent from "../../components/Header2Component";
import { IoChevronBackOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import * as React from 'react';
import { Button, FloatingLabel, Form, Alert } from "react-bootstrap";
import { MdOutlineSubject , MdOutlineAddPhotoAlternate, MdAddAPhoto } from "react-icons/md";
import { BsPostcard } from "react-icons/bs";
import { Create } from '../../services/postService';
import ImagePostComponent from "../../components/ImagePostComponent";
import StaticMenuComponent from '../../components/StaticMenuComponent';

function PostPage({ ...props }) {
    
    const navigate = useNavigate();
    const [redirecionar, setRedirecionar] = React.useState(false);
    const [show, setShow] = useState(false);
    const [exibirPasso1, setExibirPasso1] = useState(true);
    const [exibirPasso2, setExibirPasso2] = useState(false);
    const [tipoPost, setTipoPost] = useState("");
    const [texto, setTexto] = useState("");
    const [publico, setPublico] = useState(1);
    const [base64Image, setBase64Image] = useState("");
    const [croppedImage, setCroppedImage] = useState<string | ''>('');
    const [alertVariant, setAlertVariant] = useState("success");
    const [alertContent, setAlertContent] = useState("");
    const [srcProfile, setSrcProfile] = useState("");
    const [isVisible, setIsVisible] = useState(true);
    const [isVisibleLegend, setIsVisibleLegend] = useState(false);
    const [isBotaoPublicar, setIsBotaoPublicar] = useState(false);
    const [buttonPublicarDisabled, setButtonPublicarDisabled] = useState(false);
    
    const mostrarPasso2 = (param: string) => {
        setExibirPasso1(false);
        setExibirPasso2(true);
        setTipoPost(param);
        
        if(param === 'text'){
            setIsBotaoPublicar(true);
        }
        
        if(param === 'textImagem'){
            setIsBotaoPublicar(false);
            setIsVisibleLegend(false);
        }
    };
    
    const voltarParaPasso1 = () => {
        setAlertContent('');
        setShow(false);
        setExibirPasso1(true);
        setExibirPasso2(false);
        setTipoPost("");
        setTexto("");
        setCroppedImage('');
    };
    
    const handleImageCrop = (croppedImage: string | '') => {
        setCroppedImage(croppedImage);
        setIsBotaoPublicar(true);
        setIsVisibleLegend(true);
    };
    
    if (redirecionar) {
        
        navigate("/home");
    }
    
    const handleCadastrarPost = async () => {
        try {
            
            setButtonPublicarDisabled(true);
            
            const result = await Create(croppedImage, texto, publico);
            
            if(!result){
                
                setAlertVariant("danger");
                setAlertContent("Erro ao salvar. Tente mais tarde.");
                setShow(true);
                setButtonPublicarDisabled(false);
            }else{
                
                if(result.error){
                    
                    setAlertVariant("danger");
                    setAlertContent(result.error);
                    setShow(true);
                    setButtonPublicarDisabled(false);
                }else{
                    
                    setRedirecionar(true);
                    setButtonPublicarDisabled(false);
                }
            }
            
        } catch (error) {
            
            setAlertVariant("danger");
            setAlertContent("Erro ao salvar. Tente mais tarde.");
            setShow(true);
            setButtonPublicarDisabled(false);
        }
    };
    
    return (
        <>
        <StaticMenuComponent />
        <HeaderComponent />
        <main>
        <div className="contentPage">
        <div className='Content1'>
        <div className="pt-5">
        {exibirPasso1 && (
            <div className="d-grid text-center mx-5" id="publicacaoPasso1">
            <Button onClick={() => mostrarPasso2("textImagem")} variant="outline-primary" className="rounded px-4 py-2 pt-3 btn-lg my-4 d-grid btn-add-publicacao">
            <BsPostcard className="btn-add-publicacao-img" />
            <span className="btn-add-pubicacao-span">Texto e Imagem</span>
            </Button>
            <Button onClick={() => mostrarPasso2("text")} variant="outline-primary" className="rounded px-4 py-2 pt-3 btn-lg my-4 mt-0 d-grid btn-add-publicacao">
            <MdOutlineSubject className="btn-add-publicacao-img" />
            <span className="btn-add-pubicacao-span">Texto</span>
            </Button>
            </div>
        )}
        
        {exibirPasso2 && (
            <div className="d-grid text-center pt-3" id="publicacaoPasso2">
            {tipoPost === "text" && (
                <div id="publicaTexto">
                <FloatingLabel controlId="floatingTextarea2" label="O que está pensando?" className="mb-3 mx-4">
                <Form.Control
                as="textarea"
                value={texto}
                onChange={(e) => setTexto(e.target.value)}
                placeholder="O que está pensando?"
                style={{ height: '200px' }}
                className="bg-light"
                />
                </FloatingLabel>
                </div>
            )}
            
            {tipoPost === "textImagem" && (
                <div id="publicaTextoImagem">
                <div>
                <ImagePostComponent setIsVisible={setIsVisible} srcProfile={srcProfile} onImageCrop={handleImageCrop} />
                </div>
                
                {isVisibleLegend && (
                    <FloatingLabel controlId="floatingTextarea2" label="Adicione uma legenda." className="mb-3 mx-4">
                    <Form.Control
                    as="textarea"
                    value={texto}
                    onChange={(e) => setTexto(e.target.value)}
                    placeholder="Adicione uma legenda."
                    style={{ height: '100px' }}
                    className="bg-light"
                    />
                    </FloatingLabel>
                )}
                
                </div>
            )}
            
            <Alert variant={alertVariant} show={show} className="mx-4">
            <p className="my-0 text-center">{alertContent}</p>
            </Alert>
            
            {isBotaoPublicar && (
                <Button
                disabled={buttonPublicarDisabled}
                variant="primary"
                size="lg"
                className="mb-3 mx-4 rounded py-3"
                onClick={() => handleCadastrarPost()}
                >
                PUBLICAR
                </Button>
            )}
            
            <Button
            onClick={voltarParaPasso1}
            variant="light"
            size="lg"
            className="mb-3 mx-4 rounded py-3"
            >
            VOLTAR
            </Button>
            </div>
        )}
        </div>
        </div>
        </div>
        </main>
        </>
    );
}

export default PostPage;