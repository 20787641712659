import { io } from 'socket.io-client';

let apiUrl: string = process.env.REACT_APP_HOST_API as string;

const socket = io(apiUrl, {
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    autoConnect: false
});

socket.connect();

export default socket;