import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderComponent from "../../components/Header2Component";
import FooterComponent from "../../components/FooterComponent";
import ModalPerfisComponent from "../../components/ModalPerfisComponent";
import ModalSeguidoresComponent from "../../components/ModalSeguidoresComponent";
import ModalSeguindoComponent from "../../components/ModalSeguindoComponent";
import { Col, Form, Offcanvas, InputGroup, Modal, Figure, Button, Tab, Tabs, Card, Image, Badge } from "react-bootstrap";
import { dataProfile } from '../../services/userService';
import { getIdToken } from "../../utils/Utils";
import { list, listTxt } from '../../services/postService';
import { iniciarChat } from "../../services/chatService";
import { Create, listComments, deletePostComment, totalComments } from '../../services/postCommentService';
import { sendLike, getLike, totalLikes } from '../../services/postLikeService';
import { totalFollower, totalFollowing, follow } from "../../services/followerService";
import { UserData, PostData, CommentData } from '../../types/Types';
import { formatDate1, formatDate2 } from '../../utils/Utils';
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { AiFillLike } from "react-icons/ai";
import { TbMoodEmpty } from "react-icons/tb";
import { FaCircleUser } from "react-icons/fa6";
import { LazyLoadImage } from "react-lazy-load-image-component";
import StaticMenuComponent from "../../components/StaticMenuComponent";

function ProfilePage({ ...props }) {
  const [idUserToken, setIdUserToken] = useState(getIdToken());
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [offsetGeral, setOffsetGeral] = useState(0);
  const [offsetList, setOffsetList] = useState(0);
  const [totFollower, setTotFollower] = useState(0);
  const [totFollowing, setTotFollowing] = useState(0);
  const [commentsTotalSel, setCommentsTotalSel] = useState(0);
  const [totalCurtiu, setTotalCurtiu] = useState(0);
  const [curtiu, setCurtiu] = useState(0);
  const [newComment, setNewComment] = useState(true);
  const [commentsTotalLoad, setCommentsTotalLoad] = useState(true);
  const [campoComentario, setCampoComentario] = useState('');
  const [imagemAtual, setImagemAtual] = useState('');
  const [totalPosts, setTotalPosts] = useState(' -- ');
  const [idPublicacao, setidPublicacao] = useState('');
  const [textoPulicacao, setTextoPulicacao] = useState('');
  const [dataPublicacaoPost, setDataPulicacao] = useState('');
  const [postPublico, setPostPublico] = useState<number>(0);
  const [botaoDesabilitadoCarregarMais, setBotaoDesabilitadoCarregarMais] = useState(false);
  const [botaoCarregarMaisComments, setBotaoCarregarMaisComments] = useState(false);
  const [key, setKey] = useState<string | null>(null);
  const [dataComentarioSel, setDataComentarioSel] = useState<any | null>(null);
  const [posts, setPosts] = useState<PostData[]>([]);
  const [postsTxt, setPostsTxt] = useState<PostData[]>([]);
  const [commentsList, setCommentsList] = useState<CommentData[]>([]);
  const [buttonTextCurtidas, setButtonTextCurtidas] = useState('');
  const [show2, setShow2] = useState(false);
  const [showConfirmacaoExclusao, setShowConfirmacaoExclusao] = useState(false);
  const [showConfirmacaoExclusaoComentario, setShowConfirmacaoExclusaoComentario] = useState(false);
  const [textoComentarioExclusao, setTextoComentarioExclusao] = useState('');
  const [showCurtidas, setShowCurtidas] = useState(false);
  const [showSeguidores, setShowSeguidores] = useState(false);
  const [showSeguindo, setShowSeguindo] = useState(false);
  const [totalSeguidores, setTotalSeguidores] = useState(0);
  const [dadosUsuario, setDadosUsuario] = useState<UserData | null>(null);
  const [imgPerfil, setImgPerfil] = useState(String);
  const [profile, setProfile] = useState("");
  const [nome, setNome] = useState("");
  const [bio, setBio] = useState("");
  const [professor, setProfessor] = useState("");
  const [idUsuario, setIdUsuario] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [profileData, postsData, postsTxtData] = await Promise.all([
          dataProfile(id),
          list(id),
          listTxt(id),
        ]);

        setDadosUsuario(profileData);
        setPosts(postsData);
        setPostsTxt(postsTxtData);
        setTotalPosts(postsData.length + postsTxtData.length);

        const [followers, following] = await Promise.all([
          totalFollower(profileData._id),
          totalFollowing(profileData._id),
        ]);

        setTotFollower(followers.totalFollowers);
        setTotFollowing(following.totalFollowing);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (dadosUsuario) {
      setImgPerfil(dadosUsuario.srcProfile);
      setNome(dadosUsuario.nome);
      setProfile(dadosUsuario.profile);
      setBio(dadosUsuario.bio);
      setProfessor(dadosUsuario.professor);
      setIdUsuario(dadosUsuario._id);
    }
  }, [dadosUsuario]);

  const handleShowCurtidas = () => setShowCurtidas(true);
  const handleCloseCurtidas = () => setShowCurtidas(false);
  const handleShowSeguidores = () => setShowSeguidores(true);
  const handleCloseSeguidores = () => setShowSeguidores(false);
  const handleShowSeguindo = () => setShowSeguindo(true);
  const handleCloseSeguindo = () => setShowSeguindo(false);
  const handleClose2 = () => setShow2(false);
  const handleCloseConfirmacaoExclusao = () => setShowConfirmacaoExclusao(false);
  const handleCloseConfirmacaoExclusaoComentario = () => setShowConfirmacaoExclusaoComentario(false);

  const handleShow2 = (post: any) => {
    getCurtidasTexto('');
    setTotalCurtiu(0);
    setOffsetGeral(0);
    setCampoComentario('');
    setPostPublico(post.publico);
    setImagemAtual(post.srcImagem);
    setTextoPulicacao(post.texto);
    setDataPulicacao(formatDate2(post.dataPublicacao));
    setShow2(true);
    setidPublicacao(post._id);
    comments(post._id, 1);
    setCommentsTotalLoad(true);
    likeVerify(post._id);
    totalLikesVerify(post._id);
  };

  const getCurtidasTexto = (curtidas: any) => {
    let textoCurtidas = '';

    if (curtidas >= 2) {
      textoCurtidas = "2 CURTIDAS";
    } else if (curtidas === 1) {
      textoCurtidas = "1 CURTIDA";
    } else {
      textoCurtidas = "NENHUMA CURTIDA";
    }

    setButtonTextCurtidas(textoCurtidas);
  };

  const getCurtidasClick = (clique: number) => {
    let textoCurtidas = '';

    if (totalCurtiu >= 1 && clique === 1) {
      textoCurtidas = (totalCurtiu + 1) + " CURTIDAS";
    } else if (totalCurtiu === 0 && clique === 1) {
      textoCurtidas = "1 CURTIDA";
    } else if ((totalCurtiu === 1 || totalCurtiu === 0) && clique === 0) {
      textoCurtidas = "NENHUMA CURTIDA";
    } else if (totalCurtiu === 2 && clique === 0) {
      textoCurtidas = "1 CURTIDA";
    } else if (totalCurtiu > 2 && clique === 0) {
      textoCurtidas = (totalCurtiu - 1) + " CURTIDAS";
    }

    setButtonTextCurtidas(textoCurtidas);
  };

  const totalLikesVerify = async (idPost: string) => {
    try {
      const verifyLikeTotal = await totalLikes(idPost);

      if (verifyLikeTotal) {
        setTotalCurtiu(verifyLikeTotal);
        getCurtidasTexto(verifyLikeTotal);
      }
    } catch (error) {
      console.error("Erro ao listar curtidas:", error);
    }
  };

  const likeVerify = async (idPost: string) => {
    try {
      const verifyLike = await getLike(idPost);

      if (verifyLike) {
        setCurtiu(verifyLike.like === null ? 0 : 1);
      }
    } catch (error) {
      console.error("Erro ao listar curtidas:", error);
    }
  };

  const comments = async (idPost: string, resetLista: number) => {
    try {
      if (commentsTotalLoad) {
        const commentsTotal = await totalComments(idPost);
        setCommentsTotalSel(commentsTotal);
      }

      const newCommentsList = resetLista === 1
        ? await listComments(idPost, 0)
        : await listComments(idPost, offsetGeral);

      setOffsetGeral((prevOffset) => prevOffset + (resetLista === 1 ? 1 : 0));

      if (resetLista === 0) {
        setCommentsList((prev) => [...prev, ...newCommentsList]);
        setBotaoCarregarMaisComments(newCommentsList.length >= 10);
      } else {
        setCommentsList(newCommentsList);
        setBotaoCarregarMaisComments(newCommentsList.length >= 10);
      }
    } catch (error) {
      console.error("Erro ao listar comentários:", error);
    }
  };

  const paginationComment = () => {
    setBotaoDesabilitadoCarregarMais(true);
    comments(idPublicacao, 0);
    setBotaoDesabilitadoCarregarMais(false);
  };

  const addLike = async () => {
    try {
      await sendLike(idPublicacao);
      getCurtidasClick(curtiu === 1 ? 0 : 1);
      setCurtiu(curtiu === 1 ? 0 : 1);
    } catch (error) {
      console.error("Erro ao curtir a publicação:", error);
    }
  };

  const addComment = async () => {
    try {
      const returnList = 1;
      const newComment = await Create(idPublicacao, campoComentario, returnList);

      if (newComment) {
        setCampoComentario('');
        setNewComment(true);
        comments(idPublicacao, 1);
      }
    } catch (error) {
      console.error("Erro ao adicionar comentário:", error);
    }
  };

  const removeComentario = (comment: any) => {
    setTextoComentarioExclusao(comment.texto);
    setDataComentarioSel(comment);
    setShowConfirmacaoExclusaoComentario(true);
  };

  const removeComentarioConfirm = () => {
    if (dataComentarioSel) {
      const idComentarioSel = dataComentarioSel._id;
      deletePostComment(idComentarioSel);
      setCommentsList((prev) => prev.filter((c) => c._id !== idComentarioSel));
      setShowConfirmacaoExclusaoComentario(false);
    }
  };

  const newChat = async () => {
    if (id && idUserToken) {
      const iniciaChat = await iniciarChat(id, idUserToken);

      if (iniciaChat?.conversation?._id) {
        navigate('/chat/message/' + iniciaChat.conversation._id, {
          state: { conversation: iniciaChat.conversation }
        });
      }
    }
  };

  return (
    <>
      <StaticMenuComponent />
      <HeaderComponent />
      <main>
      <div className="contentPage">
      <div className="Content1">
        <div className="d-flex cemPor px-2 pt-1">
          <Col className="my-1 col-md-3 col-3">
            {imgPerfil ? (
              <Figure.Image className="cemPor" src={imgPerfil} style={{ borderRadius: "50%" }} />
            ) : (
              <div className="text-center pt-1">
                <FaCircleUser className="fs-5em opacity-0-1" />
              </div>
            )}
          </Col>
          <Col className="my-1 col-md-9 col-9 px-2">
            <h4 className="pt-1 mb-0 px-2 arial float-start fw-bold cemPor text-left">
              {nome} {professor == '1' && (<Badge bg="warning" text="dark" style={{fontSize:"0.5em",float:"right",marginTop:"5px"}}>Professor(a)</Badge>)}
              <br />
              <span className="fs-07em">@{profile}</span>
            </h4>
            <div className="d-grid float-start cemPor">
              <div className="d-flex float-start cemPor">
                <div className="text-center col-4 contadoresTextosPerfil">
                  <h5 className="mt-2 mb-0 arial">{totalPosts}</h5>
                  <h6 className="arial">{totalPosts === '1' ? 'Postagem' : 'Postagens'}</h6>
                </div>

                <div className="text-center col-4 contadoresTextosPerfil" onClick={handleShowSeguidores}>
                  <h5 className="mt-2 mb-0 arial">{totFollower}</h5>
                  <h6 className="arial">Seguidores</h6>
                </div>

                <div className="text-center col-4 contadoresTextosPerfil" onClick={handleShowSeguindo}>
                  <h5 className="mt-2 mb-0 arial">{totFollowing}</h5>
                  <h6 className="arial">Seguindo</h6>
                </div>
              </div>
            </div>
          </Col>
        </div>

        <div className="px-3 my-2">
          <h6 className="arial float-start cemPor text-left">
            {bio}
          </h6>

          {dadosUsuario && (
            <ProfileButton profile={dadosUsuario} follower={follow} idUserToken={idUserToken} />
          )}

          <Button
            variant="success"
            size="lg"
            className="text-center btn rounded-4 mr-1per py-1 w-auto ms-0"
            onClick={newChat}
          >
            <span className="pt-1 mr-2">
              <small>MENSAGEM</small>
            </span>
          </Button>
        </div>

        <div className="cemPor mt-3 px-2">
          <Tabs
            id="controlled-tab-example"
            activeKey={key || undefined}
            onSelect={(k) => setKey(k)}
            className="mb-1 tabsPerfil cemPor"
          >
            <Tab eventKey="home" title="FOTOS" className="px-1 pt-2 cemPor">
              {posts.length === 0 ? (
                <div className="text-center py-2">
                  <span className="fs-1-2em">Nenhuma publicação ainda <TbMoodEmpty /></span>
                </div>
              ) : (
                posts.map((post) => (
                  <Figure key={post._id} className="cinquentaPor mb-0" onClick={() => handleShow2(post)}>
                    <LazyLoadImage
                      effect="blur"
                      className="cemPor mb-0"
                      src={post.srcImagem}
                      style={{
                        height: "207px",
                        borderRight: "3px solid white",
                        borderBottom: "3px solid white",
                      }}
                    />
                  </Figure>
                ))
              )}
            </Tab>
            <Tab eventKey="profile" title="TEXTOS" className="px-3 pt-1 cemPor">
              {postsTxt.length === 0 ? (
                <div className="text-center py-2">
                  <span className="fs-1-2em">Nenhuma texto ainda <TbMoodEmpty /></span>
                </div>
              ) : (
                postsTxt.map((postTxt) => (
                  <Card key={postTxt._id} body className="mt-2 border-none sombra-1" onClick={() => handleShow2(postTxt)}>
                    <h6>{postTxt.texto}</h6>
                    <p className="text-end mb-0"><small className="italico font-grey fs-08em">publicado em: {formatDate1(postTxt.dataPublicacao)}</small></p>
                  </Card>
                ))
              )}
            </Tab>
          </Tabs>
        </div>
        </div>
        </div>
      </main>

      <Modal show={showConfirmacaoExclusaoComentario} className="modalConfirmacaoExclusaoComentario">
        <Modal.Body className="px-2" style={{ overflowY: "auto" }}>
          <Col xs={12} md={12} className="text-left py-2 px-3">
            <div className="p-0 d-grid cemPor text-center">
              <h6 className="fw-bold arial">Deseja realmente excluir este comentário?</h6>
              <span className="py-3">{textoComentarioExclusao}</span>
              <Button
                variant="outline-danger"
                className="rounded-pill px-4 btn-lg my-2"
                onClick={removeComentarioConfirm}
              >
                SIM EXCLUIR
              </Button>

              <Button
                variant="outline-primary"
                className="rounded-pill px-4 btn-lg my-2"
                onClick={handleCloseConfirmacaoExclusaoComentario}
              >
                CANCELAR
              </Button>
            </div>
          </Col>
        </Modal.Body>
      </Modal>

      <ModalPerfisComponent show={showCurtidas} onHide={handleCloseCurtidas} titulo="Lista de Curtidas" idPublicacao={idPublicacao} />
      <ModalSeguidoresComponent show={showSeguidores} onHide={handleCloseSeguidores} titulo="Seguidores" idUser={idUsuario} onData={setTotalSeguidores} />
      <ModalSeguindoComponent show={showSeguindo} onHide={handleCloseSeguindo} titulo="Seguindo" idUser={idUsuario} />

      <Offcanvas
        show={show2}
        onHide={handleClose2}
        {...props}
        placement={"end"}
        style={{ width: "90%" }}
      >
        <Offcanvas.Body className="pt-0">
          <div className="d-grid" style={{ position: "fixed", width: "85%", right: "2%", top: "2%", bottom: "10%" }}>
            <div>
              {imagemAtual && (
                <Figure className="cemPor py-3 pt-0 pb-0 mb-2 figure">
                  <Figure.Image
                    className="cemPor mb-0"
                    src={imagemAtual}
                    style={{
                      borderRight: "3px solid white",
                      borderBottom: "3px solid white",
                      height: "320px",
                    }}
                  />
                </Figure>
              )}
              <div className="d-flex">
                <div className="d-flex cemPor">
                  <Button
                    variant="link"
                    size="lg"
                    className="btn rounded p-0 text-black text-start ps-2 float-start"
                    onClick={handleShowCurtidas}
                  >
                    {buttonTextCurtidas}
                  </Button>
                  <h6 className="arial w-auto float-end pe-1 italico"><small>{dataPublicacaoPost}</small></h6>
                </div>
              </div>
              <div className="float-start">
                {textoPulicacao && (
                  <div className="d-flex">
                    <div className="px-2">
                      <h6
                        className="py-0 ps-1 float-start text-start arial"
                        style={{ marginTop: "7px" }}
                      >
                        <b>{nome}</b>{" "}
                        <span>
                          {textoPulicacao}
                        </span>
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <InputGroup className="mt-1 mb-3 input-group-lg p-2" style={{ height: "39px" }}>
              <Form.Control
                aria-label="Adicionar comentário..."
                placeholder="Adicionar comentário..."
                className="br-0 fs-1em"
                value={campoComentario}
                onChange={(e) => setCampoComentario(e.target.value)}
              />
              <InputGroup.Text className="bg-white">
                <PiPaperPlaneRightFill className="bl-0" onClick={addComment} />
              </InputGroup.Text>
            </InputGroup>

            <div className="px-3" id="caixaScroll" style={{ overflowY: 'scroll' }}>
              {commentsList.length === 0 && (
                <p className="text-center">Nenhum comentário.</p>
              )}

              {commentsList.length > 0 && commentsList.map((comment) => (
                <div key={comment._id}>
                  {comment.user.map((user) => (
                    <React.Fragment key={user._id}>
                      <div className="d-flex" {...(idUserToken === user._id && { onClick: () => removeComentario(comment) })}>
                        {user.srcProfile ? (
                          <Image src={user.srcProfile} className="thumb-1" />
                        ) : (
                          <div className="">
                            <FaCircleUser className="fs-2em opacity-0-1" />
                          </div>
                        )}
                        <div className="px-2 pb-3">
                          <p className="text-start d-grid mb-0 arial">
                            <b>{user.nome}</b>
                            <span>{comment.texto}</span>
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              ))}

              {botaoCarregarMaisComments && (
                <Button
                  variant="primary"
                  className="rounded px-4 btn-sm mb-4"
                  onClick={paginationComment}
                  disabled={botaoDesabilitadoCarregarMais}
                >
                  CARREGAR MAIS
                </Button>
              )}
            </div>
          </div>

          <div style={{ position: "fixed", width: "85%", right: "2%", bottom: "2%" }}>
            <Button
              variant={curtiu === 0 ? `outline-success` : `success`}
              size="lg"
              className="btn rounded p-0 float-start blocoIconeAcao"
              onClick={addLike}
            >
              <AiFillLike className="fs-1-5em" />
            </Button>

            <Button
              className="rounded btn-lg float-end quarentePor"
              onClick={handleClose2}
            >
              VOLTAR
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <FooterComponent />
    </>
  );
}

interface ProfileButtonProps {
  profile: UserData;
  follower: (profile_id: string) => Promise<void>;
  idUserToken: string;
}

const ProfileButton: React.FC<ProfileButtonProps> = ({ profile, follower, idUserToken }) => {
  const [isFollowing, setIsFollowing] = useState(profile.followed);

  const toggleFollow = async () => {
    try {
      await follower(profile._id);
      setIsFollowing(!isFollowing);
    } catch (error) {
      console.error("Erro ao alternar seguir/deixar de seguir:", error);
    }
  };

  if (idUserToken === profile._id) {
    return null;
  }

  return (
    <Button
      variant={isFollowing ? "outline-success" : "success"}
      size="lg"
      className="text-center btn rounded-4 mr-1per py-1 w-auto"
      onClick={toggleFollow}
    >
      <span className="pt-1 mr-2">
        <small>{isFollowing ? "SEGUINDO" : "SEGUIR"}</small>
      </span>
    </Button>
  );
};

export default ProfilePage;